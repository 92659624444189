import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import { BiCabinet, BiPaperPlane, BiPen, BiShieldQuarter, BiLoader, BiCheckCircle, BiDollarCircle, BiXCircle, BiSend, BiMoneyWithdraw } from 'react-icons/bi';
import { MdApproval, MdOutlineRateReview } from 'react-icons/md';
import { AiOutlineException } from 'react-icons/ai';

@inject('reports', 'global', 'filter')
@observer
class ViewPartialApplications extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="orgParentname" id="orgParentname" className={"form-control"} placeholder="Organization" onChange={(e) => this.props.filter.handleCaptureTextFilter('orgParentname(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('orgParentname(ct)', e)} value={this.props.filter.filterText.orgParentname ? this.props.filter.filterText.orgParentname : ''} />
                            <label htmlFor="orgParentname">Organization</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="legalname" id="legalname" className={"form-control"} placeholder="Legal Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('legalname(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('legalname(ct)', e)} value={this.props.filter.filterText.legalname ? this.props.filter.filterText.legalname : ''} data-qaid="inputLegalNameFilterApplications"/>
                            <label htmlFor="legalname">Legal Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="dbaname" id="dbaname" className={"form-control"} placeholder="DBA Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('dbaname(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('dbaname(ct)', e)} value={this.props.filter.filterText.dbaname ? this.props.filter.filterText.dbaname : ''} />
                            <label htmlFor="dbaname">DBA Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="contactName" id="contactName" className={"form-control"} placeholder="Contact Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('contactName(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('contactName(ct)', e)} value={this.props.filter.filterText.contactName ? this.props.filter.filterText.contactName : ''} />
                            <label htmlFor="contactName">Contact Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="contactEmail" id="contactEmail" className={"form-control"} placeholder="Contact Email" onChange={(e) => this.props.filter.handleCaptureTextFilter('contactEmail(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('contactEmail(ct)', e)} value={this.props.filter.filterText.contactEmail ? this.props.filter.filterText.contactEmail : ''} />
                            <label htmlFor="contactEmail">Contact Email</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating input-number">
                            <input name="ein" id="ein" type='number' className={"form-control"} placeholder="EIN" onChange={(e) => this.props.filter.handleCaptureTextFilter('ein(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('ein(ct)', e)} value={this.props.filter.filterText.ein ? this.props.filter.filterText.ein : ''} />
                            <label htmlFor="ein">EIN</label>
                        </div>
                    </div>
                </div>
                <div>
                    <p className='mb-2'><small>Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[-99]) ? "badge bg-light" : "badge text-light"} onClick={() => this.props.filter.handleStatus('status(in)', -99, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[-99]))}><BiCabinet /> Not Submitted</span>
                        {/* <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))}><BiPaperPlane /> In Process</span> */}
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', 2, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]))}><BiPen /> Pending Signature</span>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 3, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]))}><BiShieldQuarter /> Underwriting</span>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[4]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 4, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[4]))}><BiSend /> Submitted</span>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[6]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleStatus('status(in)', 6, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[6]))}><MdOutlineRateReview /> Manual Review</span>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[7]) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleStatus('status(in)', 7, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[7]))}><MdApproval /> Approved</span>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[8]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', 8, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[8]))}><BiMoneyWithdraw /> Withdrawn</span>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[9]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleStatus('status(in)', 9, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[9]))}><BiShieldQuarter /> Deactivated</span>
                        {/* <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', 5, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]))}><BiLoader /> Held</span> */}
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[10]) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleStatus('status(in)', 10, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[10]))}>{this.props.global.getGlobalImg((Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[10]) ?'intransitsecondarydark' : 'intransitsecondary', '12px')} Boarding</span>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[99]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 99, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[99]))}><BiCheckCircle /> Activated</span>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[100]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 100, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[100]))}><BiDollarCircle /> Live</span>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('status(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiXCircle /> Declined</span>
                        <span className={ (Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[-1]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('status(in)', -1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[-1]))}><AiOutlineException /> Exception Error</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Created At</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="createdAtCalendar" 
                                        name="createdAtCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startCreatedAt ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startCreatedAt) + ' → ' : '') + (this.props.filter.stateDate.endCreatedAt ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endCreatedAt) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="createdAtCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startCreatedAt ? this.props.filter.stateDate.startCreatedAt : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startCreatedAt', 'endCreatedAt', 'createdAt')}
                            startDate={this.props.filter.stateDate.startCreatedAt}
                            endDate={this.props.filter.stateDate.endCreatedAt}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endCreatedAt !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Last Modified</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="lastModifiedCalendar" 
                                        name="lastModifiedCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startLastModified ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startLastModified) + ' → ' : '') + (this.props.filter.stateDate.endLastModified ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endLastModified) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="lastModifiedCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startLastModified ? this.props.filter.stateDate.startLastModified : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startLastModified', 'endLastModified', 'updatedOn')}
                            startDate={this.props.filter.stateDate.startLastModified}
                            endDate={this.props.filter.stateDate.endLastModified}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endLastModified !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                </div>
        </>);
    }
}

export { ViewPartialApplications };