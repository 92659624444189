const configByReport = {
	settlements: {
		columns: [
			{ label: 'Payment ID', key: 'paymentId', visible: false },
			{ label: 'Transaction ID', key: 'transactionId', visible: true },
			{ label: 'Order ID', key: 'orderId', visible: false },
			{
				label: 'Internal Paypoint ID',
				key: 'internalPaypointId',
				visible: false
			},
			{ label: 'Customer', key: 'customer', visible: false },
			{ label: 'Company', key: 'company', visible: false },
			{ label: 'Email', key: 'email', visible: false },
			{ label: 'Phone #', key: 'phone', visible: false },
			{
				label: 'Billing Address',
				key: 'billingAddress',
				visible: false
			},
			{
				label: 'Shipping Address',
				key: 'shippingAddress',
				visible: false
			},
			{ label: 'Invoice #', key: 'invoice', visible: false },
			{
				label: 'Transaction Date',
				key: 'transactionDate',
				visible: true
			},
			{ label: 'Pay Method', key: 'payMethod', alignment: 'center', visible: true },
			{ label: 'Last 4', key: 'last4', visible: true },
			{
				label: 'Type',
				key: 'type',
				tooltip:
					'The transaction type, either Sale, Refund, or Credit. This is independent of the payment method.',
				visible: true
			},
			{
				label: 'Cycle',
				key: 'cycle',
				tooltip:
					'Cycle indicates whether the payment is one-time or a recurring subscription or auto-payment. ',
				visible: false
			},
			{
				label: 'Payment Status',
				key: 'paymentStatus',
				tooltip:
					"The transaction's payment status. This is tracked independently of the batch status.",
				visible: true
			},
			{
				label: 'Batch Status',
				key: 'fundingStatus',
				tooltip:
					"The transaction's Batch Status. This is tracked independently of the batch status.",
				visible: true
			},
			{
				label: 'Transaction Amount',
				key: 'transactionAmount',
				alignment: 'right',
				visible: true
			}
		],
		actions: [
			{
				label: 'View Details',
				action: 'openQuickView'
			}
		]
	},
	transferDetails: {
		columns: [
			{ label: 'Payment ID', key: 'paymentId', visible: false },
			{ label: 'Transaction ID', key: 'transactionId', visible: true },
			{ label: 'Order ID', key: 'orderId', visible: false },
			{
				label: 'Internal Paypoint ID',
				key: 'internalPaypointId',
				visible: false
			},
			{ label: 'Customer', key: 'customer', visible: false },
			{ label: 'Company', key: 'company', visible: false },
			{ label: 'Email', key: 'email', visible: false },
			{ label: 'Phone #', key: 'phone', visible: false },
			{
				label: 'Billing Address',
				key: 'billingAddress',
				visible: false
			},
			{
				label: 'Shipping Address',
				key: 'shippingAddress',
				visible: false
			},
			{ label: 'Invoice #', key: 'invoice', visible: false },
			{
				label: 'Transaction Date',
				key: 'transactionDate',
				visible: true
			},
			{ label: 'Pay Method', key: 'payMethod', alignment: 'center', visible: true },
			{ label: 'Last 4', key: 'last4', visible: true },
			{
				label: 'Type',
				key: 'type',
				tooltip:
					'The transaction type, either Sale, Refund, or Credit. This is independent of the payment method.',
				visible: true
			},
			{
				label: 'Cycle',
				key: 'cycle',
				tooltip:
					'Cycle indicates whether the payment is one-time or a recurring subscription or auto-payment. ',
				visible: false
			},
			{
				label: 'Payment Status',
				key: 'paymentStatus',
				tooltip:
					'The transactions payment status. This is tracked independently of the batch status. ',
				visible: true
			},
			{
				label: 'Batch Status',
				key: 'fundingStatus',
				tooltip:
					"The transaction's Batch Status. This is tracked independently of the batch status.",
				visible: true
			},
			{
				label: 'Transaction Amount',
				key: 'transactionAmount',
				alignment: 'right',
				visible: true
			},

			{
				label: 'Service Group',
				key: 'serviceGroup',
				tooltip:
					"The service's group for billing purposes: Money in, Money Out, Value Added Services, or Pay Ops.",
				visible: false
			},
			{ label: 'Service', key: 'service', visible: false },
			{
				label: 'Billable Event',
				key: 'billableEvent',
				tooltip:
					'Billable events is any event that has a pricing and billing profile. For example, credit card or ACH authorizations, chargebacks, and ACH returns may have associated billing profiles and are considered billable events.',
				visible: false
			},
			{
				label: 'Billable Event ID',
				key: 'billableEventId',
				visible: false
			},
			{
				label: 'Frequency',
				key: 'frequency',
				tooltip:
					'Frequency is how often the charges for a billable event are applied: each event, weekly, monthly, or yearly.',
				visible: false
			},
			{
				label: '% Price',
				key: 'porcentPrice',
				tooltip: 'Percentage pricing applied to a billable item. For example, 4% on total.',
				alignment: 'right',
				visible: false
			},
			{
				label: '$ Price',
				key: 'valuePrice',
				tooltip: 'Per-item pricing applied to a billable item. For example, $35 per ACH return.',
				alignment: 'right',
				visible: false
			},
			{
				label: 'Billable Amount',
				key: 'billableAmount',
				tooltip:
					'The transaction amount that the pricing profile uses to calculate the bill amount. ',
				alignment: 'right',
				visible: false
			},
			{
				label: 'Calculated Bill Amount',
				key: 'calculatedBillAmount',
				tooltip: 'The total fee applied to the billable event.',
				alignment: 'right',
				visible: false
			},
			{
				label: 'Description',
				key: 'description',
				alignment: 'center',
				visible: false
			},
			{
				label: 'Split Amount',
				key: 'splitFundingAmount',
				alignment: 'right',
				visible: false
			},
			{
				label: 'Transfer Amount',
				key: 'transferAmount',
				alignment: 'right',
				visible: true
			}
		],
		actions: [{ label: 'View Details', action: 'openQuickView' }]
	}
};

function getConfigByReport(type) {
	return configByReport[type];
}

export default getConfigByReport;
