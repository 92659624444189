import { observable, makeObservable, action, computed, toJS } from 'mobx'
import axios from 'axios'
import { PayabliStorageManager } from '../api/localStorageManager'
import globalStore from './GlobalStore'
import {BiMinusCircle, BiCheckCircle, BiLockAlt, BiXCircle, BiPaperPlane, BiLoader, BiLogOutCircle, BiDollarCircle, BiLogInCircle, BiTrash} from 'react-icons/bi'
import { CgUnavailable } from 'react-icons/cg'
import statisticStore from './StatisticStore'

const FileDownload = require('js-file-download')

class ReportsStore {
	constructor() {
		this.globalStore = globalStore
		makeObservable(this)
	}

	globalStore
	orgId = 0
	encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()

	@observable
	headers = []

	@observable
	recordDetails = 0

	@observable
	filters = {}

	@observable
	records = []

	@observable
	count = 20

	@observable
	paypoint = ''

	@observable
	totalRecords = 0

	@observable
	totalAmount = 0

	@observable
	avgAmount = 0

	@observable
	lastAmount = 0

	@observable
	from = 0

	@observable
	totalPages = 0

	@observable
	summary = null

	@observable
	objectBatchRecord = null

	getMonthName(number, short) {
		let monthNames = []
		if (short) {
			monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
		} else {
			monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		}

		return monthNames[number]
	}

	createDate(days, months, years) {
		var date = new Date()
		date.setDate(date.getDate() + days)
		date.setMonth(date.getMonth() + months)
		date.setFullYear(date.getFullYear() + years)
		return date
	}

	@computed
	get residualDataChart() {
		let data = []
		data['labels'] = []
		data['values'] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
		data['values_position'] = []

		for (let i = 12; i >= 1; i--) {
			let date = this.createDate(0, i - 12, 0)

			data['values_position'][i] = date.getFullYear() + '-' + (date.getMonth() + 1)
			data['labels'][i] = this.getMonthName(date.getMonth(), 1) + ' ' + date.getFullYear().toString().substr(-2)
		}

		if (this.records) {
			this.records.forEach(function (item) {
				let index = data['values_position'].indexOf(item.period)
				if (index > -1) {
					data['values'][index] = item.partnerResidual
				}
			})
		}

		data['lastMonthTotal'] = this.records && this.records[this.records.length - 1] ? this.records[this.records.length - 1].partnerResidual : 0

		return data
	}

	@action
	setObjectBatchRecord(object) {
		this.objectBatchRecord = object;
	}

	@action
	async getLocationByIp(ip){
		if (globalStore.validateIPaddress(ip)) {
			try {
				const response = await fetch('https://api.country.is/' + ip)
				return response.text().then(data => { return JSON.parse(data) })
			} catch (error) {}
		}
		return null;
	}

	@action
	getReportFromApi(report) {
		let limits = {}
		limits.limitRecord = this.count
		limits.fromRecord = this.from
		let params = Object.assign({}, limits, this.filters)

		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		this.orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId
		//this.getReportSummaryFromApi(report, this.orgId)
		return axios
			.get(process.env.REACT_APP_URL_API + 'Query/' + report + '/org/' + this.orgId, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: params
			})
			.then((res) => {
				this.setRecords(res.data.Records)
				this.setTotalPages(res.data.Summary.totalPages)
				this.setTotalRecords(res.data.Summary.totalRecords)
				this.setTotalAmount(res.data.Summary.totalNetAmount)
				this.summary = res.data.Summary ? res.data.Summary : null
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	getReportSummaryFromApi(report, orgId) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'Query/' + report + '/org/' + orgId, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((res) => {
				this.summary = res.data.Summary ? res.data.Summary : null;
				return res.data?.Summary;
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	getReportFromApiOrgID(report, orgId) {
		let limits = {}
		limits.limitRecord = this.count
		limits.fromRecord = this.from
		let params = Object.assign({}, limits, this.filters)
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'Query/' + report + '/org/' + orgId, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: params
			})
			.then((res) => {
				this.setRecords(res.data.Records)
				this.setTotalPages(res.data.Summary.totalPages)
				this.setTotalRecords(res.data.Summary.totalRecords)
				this.setTotalAmount(res.data.Summary.totalNetAmount)
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	exportFile(report, format) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'Export/' + report + '/' + format + '/' + this.entryPoint, {
				responseType: format.toLowerCase() === 'csv' ? 'text' : 'blob',
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: Object.assign({}, this.filters)
			})
			.then((res) => {
				FileDownload(res.data, report + '.' + format)
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	getEntrySettings(entry) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'Paypoint/settings/' + entry, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	setPaypoint(paypoint) {
		this.paypoint = paypoint
	}

	@action
	getReportOrgFromApi(report, idOrg) {
		let limits = {}
		limits.limitRecord = this.count
		limits.fromRecord = this.from
		let params = Object.assign({}, limits, this.filters)
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		this.orgId = idOrg ? idOrg : encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId
		return axios
			.get(process.env.REACT_APP_URL_API + 'Query/' + report + '/' + this.orgId, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: params
			})
			.then((res) => {
				this.setRecords(res.data.Records)
				this.setTotalPages(res.data.Summary.totalPages)
				this.setTotalRecords(res.data.Summary.totalRecords)
				this.setTotalAmount(res.data.Summary.totalNetAmount)
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	getReportStatOrgFromApi(report) {
		let limits = {}
		limits.limitRecord = this.count
		limits.fromRecord = this.from
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		this.orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId
		return axios
			.get(process.env.REACT_APP_URL_API + 'Statistic/' + report + '/m/0/' + this.orgId, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((res) => {
				
				let records = statisticStore.normalizeReportData(res.data.records)
				this.setRecords(records)
				this.setTotalPages(0)
				this.setTotalRecords(records.length)
				this.setTotalAmount(res.data.total)
				this.setAvgAmount(res.data.average)
			})
			.catch((error) => {
				throw error
			})
	}
	

	@action
	async getReportxChangeFromApi(report) {
        let limits = {};
        limits.limitRecord = this.count;
        limits.fromRecord = this.from;
        let params = Object.assign({}, limits, this.filters),
        encryptStorage = PayabliStorageManager.getEncryptedLocalStorage(),
        orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
        try {
			const res = await axios.get(process.env.REACT_APP_URL_API + 'Query/' + report + '/org/' + orgId, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: params
			})
			this.setRecords(res.data.Records)
			this.setTotalPages(res.data.Summary.totalPages)
			this.setTotalRecords(res.data.Summary.totalRecords)
			this.setTotalAmount(res.data.Summary.totalNetAmount)
		} catch (error) {
			throw error
		}
    }

	@action
	async getXchangeMappingFromApi() {
         const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage(),
        orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
        /*try {
			const res = await axios.get(process.env.REACT_APP_URL_API + 'Query/xchangeMapping/org/' + orgId, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
			})
			let records = res?.data?.Records
			if (orgId !== 2) {
				for (let i = 0; i < records.length; i++) {
					if (records[i].identifier === 'import_reconciliation') {
						records.splice(i, 1);
						break;
					}
				}
			}
			return records ? records : []
		} catch (error) {
			throw error
		} */
		let result = [
			{
			  "identifier": "import_bill_record",
			  "name": "Import Bills"
			},
			{
			  "identifier": "import_boarding",
			  "name": "Import Boarding Applications"
			},
			{
			  "identifier": "import_customer",
			  "name": "Import Customers"
			},
			{
			  "identifier": "import_vendor",
			  "name": "Import Vendors"
			}
		]
		if (orgId === 2) {
			result.push({
				"identifier": "import_reconciliation",
			  	"name": "Import Reconciliation"
			})
		}
		return result
    }

	hasSftpAccount = async (orgId) => {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
		try {
			const res = await axios.get(`${process.env.REACT_APP_URL_API}Query/XchangeAccount/org/${orgId}`, {
				headers: {
					requestToken: encryptStorage.getItem('pToken'),
				},
			})
			return res?.data?.Records?.length > 0 ? true : false
		} catch (error) {
			throw error
		}
	}

	@action
	async getReportExchangeFilesFromApi(report) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
		let orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId : "";

		const tempSftpAccount = await this.hasSftpAccount(orgId);
		
		if (tempSftpAccount) {
			let limits = {};
			limits.limitRecord = this.count;
			limits.fromRecord = this.from;
			let params = Object.assign({}, limits, this.filters);

			return axios.get(process.env.REACT_APP_URL_API + 'Query/' + report + '/org/' + orgId, {
				headers: {
					'requestToken': encryptStorage.getItem('pToken'),
				},
				params: params
			})
			.then(res => {
				this.setRecords(res.data.Records);
				this.setTotalPages(res.data.Summary.totalPages);
				this.setTotalRecords(res.data.Summary.totalRecords);
				this.setTotalAmount(res.data.Summary.totalNetAmount);
			})
			.catch(error => {
				throw error;
			});
		}
	}

	async getXchangeAccountByIdFromApi(id) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
		try {
			const res = await axios.get(process.env.REACT_APP_URL_API + 'Query/xChangeAccount/' + id, {
				headers: {
					'requestToken': encryptStorage.getItem('pToken'),
				},
			})
			return res?.data?.Records ? res.data.Records : []
		} catch (error) {
			throw error
		}
	}

	async getListFilePurposeByIdSftpConfig(idSftpConfig) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
		let orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId : "";
		try {
			const res = await axios.get(`${process.env.REACT_APP_URL_API}Query/xchangePurpose/org/${orgId}?configurationId=${idSftpConfig}`, {
				headers: {
					'requestToken': encryptStorage.getItem('pToken'),
				},
			})
			return res?.data?.Records ? res.data.Records : []
		} catch (error) {
			throw error
		}
	}

	@action
	async getSftpAccountByIdFromApi(id, idSftpConfig) {
		try {
			const listXchangeAccount = await this.getXchangeAccountByIdFromApi(id);
			const listFilePurpose = await this.getListFilePurposeByIdSftpConfig(idSftpConfig);
			let data = listXchangeAccount || [], result_1 = []
			for (let i = 0; i < data.length; i++) {
				if (data[i].idSftpConfig === idSftpConfig) {
					data[i].listFilePurpose = listFilePurpose || []
					result_1.push(data[i])
					break
				}
			}
			return result_1
		} catch (error) {
			throw error
		}
	}

    @action
    async addFilePurpose(configurationId, dataListFilePurpose){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        try {
			const res = await axios.post(process.env.REACT_APP_URL_API + 'Xchange/addFilePurpose/' + configurationId, dataListFilePurpose,
				{
					headers: { 'requestToken': encryptStorage.getItem('pToken') }
				})
			return res
		} catch (error) {
			throw error
		}
    }

    @action
    async updateFilePurpose(configurationId, dataListFilePurpose){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        try {
			const res = await axios.put(process.env.REACT_APP_URL_API + 'Xchange/updateFilePurpose/' + configurationId, dataListFilePurpose,
				{
					headers: { 'requestToken': encryptStorage.getItem('pToken') }
				})
			return res
		} catch (error) {
			throw error
		}
    }

    @action
    async deleteFilePurpose(configurationId, dataListFilePurpose){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        try {
			const res = await axios.delete(process.env.REACT_APP_URL_API + 'Xchange/deleteFilePurpose/' + configurationId,
				{
					headers: { 'requestToken': encryptStorage.getItem('pToken') },
					data: dataListFilePurpose,
				})
			return res
		} catch (error) {
			throw error
		}
    }

    @action
    addSftpAccount(data, dataListFilePurpose){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let item = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`);        
        if (item && ((data.orgtype && data.orgid && data.entryName) === undefined)) {
            data['orgtype'] = 0;
            data['orgid'] = item.orgId ? item.orgId : null;
            data['entryName'] = item.pEntry ? item.pEntry : null;
        }
        return axios.post(process.env.REACT_APP_URL_API+ 'Xchange/AddAccount', data,
        {
			headers: {'requestToken': encryptStorage.getItem('pToken')}
		})
        .then(async res => {
			if (res?.status === 200) {
				let idSftpConfig = res.data?.responseData[0]?.idSftpConfig
				if (idSftpConfig) {
					await this.addFilePurpose(idSftpConfig, dataListFilePurpose)
				}
			}
			return res;
		})
        .catch(error => {
            throw error;
        });
    }

    @action
    updateSftpAccount(data, configurationId, dataListFilePurpose){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
		let item = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`);        
        if (item && ((data.orgtype && data.orgid && data.entryName) === undefined)) {
            data['orgtype'] = 0;
            data['orgid'] = item.orgId ? item.orgId : null;
            data['entryName'] = item.pEntry ? item.pEntry : null;
        }
        return axios.put(process.env.REACT_APP_URL_API+ 'Xchange/UpdateConfiguration/'+configurationId, data,
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
		})
        .then(async res => {
			if (res) {
				this.updateFilePurposeByArray(configurationId, dataListFilePurpose)
			}
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

	updateFilePurposeByArray(configurationId, data){
		const separatedArrays = data.reduce((result, item) => {
		if (item.updFlag === 'edit') {
			if (item.purposeId) {
				result.edit.push(item);
			}
		} else if (item.updFlag === 'remove') {
			if (item.purposeId) {
				result.remove.push(item.purposeId);
			}
		} else if (item.updFlag === 'add') {
			result.add.push(item);
		}
		return result;
		}, { edit: [], remove: [], add: [] });
		
		const editArray = separatedArrays.edit;
		const removeArray = separatedArrays.remove;
		const addArray = separatedArrays.add;

		if (addArray.length > 0) {
			this.addFilePurpose(configurationId, addArray)
		}
		if (editArray.length > 0) {
			this.updateFilePurpose(configurationId, editArray)
		}
		if (removeArray.length > 0) {
			this.deleteFilePurpose(configurationId, removeArray)
		}
	}

    @action
    deleteSftpAccount(id){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.delete(process.env.REACT_APP_URL_API+ 'Xchange/DeleteConfiguration/'+id,
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            let records = this.records;
            records.forEach(function (item, index) {
                if(item.Id === id ){
                    delete records[index];
                    return;
                }
            });
        })
        .catch(error => {
            throw error;
        });
    }

		downloadFileByUrl(fileName, fileUrlContent) {
			const link = document.createElement('a');
			link.target = '_blank';
			link.href = fileUrlContent;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}

    @action
    downloadFileXChange(uniqueIdentifier, configId) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API + 'XChange/DownloadFile?configurationId='+ configId +'&uniqueIdentifier=' + uniqueIdentifier, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            },
            })
            .then(async res => {
								if (res?.data?.responseData && res?.data?.responseData?.length > 1) {
									try {
										const [fileName, fileUrlContent] = res.data.responseData;
										this.downloadFileByUrl(fileName, fileUrlContent);
									} catch (error) {
										throw error;
									}
								}
								return res?.data?.responseData;
            })
            .catch(error => {
                throw error;
            });
		}

	@action
	exportOrgFile(report, format, idOrg) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let orgId = idOrg
		return axios
			.get(process.env.REACT_APP_URL_API + 'Export/' + report + '/' + format + '/org/' + orgId, {
				responseType: format.toLowerCase() === 'csv' ? 'text' : 'blob',
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: Object.assign({}, this.filters)
			})
			.then((res) => {
				FileDownload(res.data, report + '.' + format)
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	setFilters(filters){
		this.filters = filters;
	}

	@action
	exportAllDataFile(report, format, idOrg) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'Export/' + report + '/' + format + '/' + idOrg, {
				responseType: format.toLowerCase() === 'csv' ? 'text' : 'blob',
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: Object.assign({}, this.filters)
			})
			.then((res) => {
				FileDownload(res.data, report + '.' + format)
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	exportSettlements(format, IdBatch) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId
		return axios
			.get(process.env.REACT_APP_URL_API + 'Export/settlements/' + format + '/org/' + orgId + '?batchId(eq)=' + IdBatch, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: null
			})
			.then((res) => {
				FileDownload(res.data, 'Settlemets.' + format)
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	exportTransactionsBatch(format, batchNumber) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId
		return axios
			.get(process.env.REACT_APP_URL_API + 'Export/payouts/' + format + '/org/' + orgId + '?batchId(eq)=' + batchNumber, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: null
			})
			.then((res) => {
				FileDownload(res.data, 'Transactions.' + format)
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	setRecords(records) {
		this.records = records
	}

	@action
	setTotalRecords(total) {
		this.totalRecords = total
	}

	@action
	setTotalAmount(total) {
		this.totalAmount = total
	}

	@action
	setAvgAmount(total) {
		this.avgAmount = total
	}

	@action
	setTotalPages(total) {
		this.totalPages = total
	}

	@action
	filter(type, value, report, optionalFilter) {
		if (Array.isArray(type)) {
			for (const filter in type) {
				this.filters[filter] = type[filter]
			}
		}

		if (type instanceof Object) {
			Object.keys(type).forEach((key) => {
				if (type[key]) this.filters[key] = type[key]
				else delete this.filters[key]
			})
			Object.keys(this.filters).forEach((key) => {
				if (!type[key]) delete this.filters[key]
			})
		}

		if (value) {
			this.filters[type] = value
		} else {
			delete this.filters[type]
		}

		if ((optionalFilter && Object.keys(this.filters).length > 1) || (optionalFilter && Object.keys(this.filters).length === 0)) {
			this.filters[optionalFilter[0]] = optionalFilter[1]
		}

		this.setFrom(0)
		return this.getReportFromApi(report)
	}

	@action
	filterOrg(type, value, report, idOrg) {
		if (Array.isArray(type)) {
			for (const filter in type) {
				this.filters[filter] = type[filter]
			}
		} else if (type instanceof Object) {
			Object.keys(type).forEach((key) => {
				if (type[key]) this.filters[key] = type[key]
				else delete this.filters[key]
			})
		} else {
			if (value || value === 0) {
				this.filters[type] = value
			} else {
				delete this.filters[type]
			}
		}
		this.setFrom(0)
		return this.getReportOrgFromApi(report, idOrg)
	}

	@action
	filterStatOrg(type, value, report) {
		if (Array.isArray(type)) {
			for (const filter in type) {
				this.filters[filter] = type[filter]
			}
		} else if (type instanceof Object) {
			Object.keys(type).forEach((key) => {
				if (type[key]) this.filters[key] = type[key]
				else delete this.filters[key]
			})
		} else {
			if (value || value === 0) {
				this.filters[type] = value
			} else {
				delete this.filters[type]
			}
		}
		this.setFrom(0)
		return this.getReportStatOrgFromApi(report)
	}

	@action
	filterOrgId(type, value, report, idOrg) {
		if (Array.isArray(type)) {
			for (const filter in type) {
				this.filters[filter] = type[filter]
			}
		} else {
			if (value) {
				this.filters[type] = value
			} else {
				delete this.filters[type]
			}
		}

		this.setFrom(0)
		return this.getReportFromApiOrgID(report, idOrg)
	}

	@action
	filterXchange(type, value, report, optionalFilter) {
		if (Array.isArray(type)) {
			for (const filter in type) {
				this.filters[filter] = type[filter]
			}
		}

		if (type instanceof Object) {
			Object.keys(type).forEach((key) => {
				if (type[key]) this.filters[key] = type[key]
				else delete this.filters[key]
			})
		}

		if (value) {
			this.filters[type] = value
		} else {
			delete this.filters[type]
		}

		if ((optionalFilter && Object.keys(this.filters).length > 1) || (optionalFilter && Object.keys(this.filters).length === 0)) {
			this.filters[optionalFilter[0]] = optionalFilter[1]
		}

		this.setFrom(0)
		return this.getReportxChangeFromApi(report)
	}

	@action
	handleShowColumn(e) {
		this.headers[e.target.id].display = !this.headers[e.target.id].display
	}

	@action
	setFrom(from) {
		this.from = from * this.count
	}

	@action
	setCount(count) {
		this.count = count
	}

	@action
	setRecordDetails(index) {
		this.recordDetails = index
	}

	@action
	clearFilters() {
		this.filters = {}
	}

	@computed
	get getRecordDetails() {
		if (this.records.length) {
			return this.records[this.recordDetails]
		} else {
			return null
		}
	}

	@action
	setHeaders(headers) {
		this.headers = headers
	}

	@action
	addHeader(key, data) {
		this.headers[key] = data
	}

	@action
	removeFilter(filter) {
		delete this.filters[filter]
	}

	@action
	voidTransaction(transIdToVoid) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'MoneyIn/void/' + transIdToVoid, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	reversalCreditTransaction(transId){
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'MoneyIn/reverseCredit/' + transId, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	deleteCustomer(customerId) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.delete(process.env.REACT_APP_URL_API + 'Customer/' + customerId, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				let records = this.records
				records.forEach(function (item, index) {
					if (item.customerId === customerId) {
						delete records[index]
						return
					}
				})
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	addLineItem(lineItem, lineItemsPaypoint) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()

		return axios
			.post(
				process.env.REACT_APP_URL_API + 'LineItem/' + lineItemsPaypoint,
				{
					itemProductName: lineItem.itemProductName,
					itemDescription: lineItem.itemDescription,
					itemCost: Number(parseFloat(lineItem.itemCost).toFixed(2)),
					itemProductCode: lineItem.itemProductCode,
					itemQty: 1
				},
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	updateLineItem(lineItemId, lineItem) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(
				process.env.REACT_APP_URL_API + 'LineItem/' + lineItemId,
				{
					itemProductName: lineItem.itemProductName,
					itemDescription: lineItem.itemDescription,
					itemCost: Number(parseFloat(lineItem.itemCost).toFixed(2)),
					itemProductCode: lineItem.itemProductCode,
					itemQty: 1
				},
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	deleteLineItem(lineItemId) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.delete(process.env.REACT_APP_URL_API + 'LineItem/' + lineItemId, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				let records = this.records
				records.forEach(function (item, index) {
					if (item.Id === lineItemId) {
						delete records[index]
						return
					}
				})
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	deleteBill(IdBill) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.delete(process.env.REACT_APP_URL_API + 'Bill/' + IdBill, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				let records = this.records
				records.forEach(function (item, index) {
					if (item.IdBill === IdBill) {
						records[index].Status = -99
						return
					}
				})
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	deleteVendor(IdVendor) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.delete(process.env.REACT_APP_URL_API + 'Vendor/' + IdVendor, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				let records = this.records
				records.forEach(function (item, index) {
					if (item.VendorId === IdVendor) {
						delete records[index]
						return
					}
				})
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	cancelAutopay(autopayIdToModify) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.delete(process.env.REACT_APP_URL_API + 'Subscription/' + autopayIdToModify, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				let records = this.records
				records.forEach(function (item, index) {
					if (item.IdSub === autopayIdToModify) {
						delete records[index]
						return
					}
				})
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	pauseAutopay(autopayIdToPause, pause, autopayStartDate, autopayEndDate, autopayFrequency) {
		let data = {
			setPause: pause
		}
		if (autopayStartDate && autopayEndDate && autopayFrequency) {
			data.scheduleDetails = {
				startDate: this.dateStringFormat(autopayStartDate),
				endDate: this.dateStringFormat(autopayEndDate),
				frequency: autopayFrequency
			}
		}
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(process.env.REACT_APP_URL_API + 'Subscription/' + autopayIdToPause, data, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				let records = this.records
				records.forEach(function (item, index) {
					if (item.IdSub === autopayIdToPause) {
						if (pause === true) {
							records[index].SubStatus = 0
						} else if (pause === false) {
							records[index].SubStatus = 1
						}
						return
					}
				})
			})
			.catch((error) => {
				throw error
			})
	}

	@computed
	get getHeaders() {
		return Object.entries(toJS(this.headers))
	}

	@computed
	get getFilters() {
		return Object.entries(toJS(this.filters))
	}

	dateStringFormat(date) {
		var year = date.getFullYear()
		var month = (1 + date.getMonth()).toString()
		month = month.length > 1 ? month : '0' + month
		var day = date.getDate().toString()
		day = day.length > 1 ? day : '0' + day
		return month + '/' + day + '/' + year
	}

	getCustomerStatus(status) {
		switch (status) {
			case 0:
				return <span className="badge bg-light"><BiMinusCircle /> Inactive</span>
			case 1:
				return <span className="badge bg-primary"><BiCheckCircle /> Active</span>
			case -99:
				return <span className="badge bg-danger"><BiTrash /> Deleted</span>
			case 85:
				return <span className="badge bg-warning"><BiLockAlt /> Locked</span>
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}
	getCustomerTextStatus(status) {
		switch (status) {
			case 1:
				return 'Active'
			case -1:
				return 'Non-Authorized'
			case 58:
				return 'Locked'
			case 0:
				return 'Inactive'
			default:
				return 'Unknow'
		}
	}
	getSettlementStatus(response) {
		if (response !== '' && response !== null) {
			switch (response) {
				case 0:
					return 'Pending Settlement'
				case 1:
					return 'Settled'
				default:
					return 'Pending Settlement'
			}
		}
	}

	getBillStatus(pstatus, text) {
		let status = parseInt(pstatus)
		switch (status) {
			case -99:
				return text ? 'Deleted/Cancelled' : <span className="badge bg-danger"><BiXCircle /> Deleted/Cancelled</span>
			case 1:
				return text ? 'Active' : <span className="badge bg-primary"><BiCheckCircle /> Active</span>
			case 2:
				return text ? 'Sent to Approval' : <span className="badge bg-info"><BiPaperPlane /> Sent to Approval</span>
			case 5:
				return text ? 'Pending Approval' : <span className="badge bg-warning"><BiLoader /> Pending Approval</span>
			case 11:
				return text ? 'Not Approved' : <span className="badge bg-light"><BiLogOutCircle /> Not Approved</span>
			case 20:
				return text ? 'Approved' : <span className="badge bg-info"><BiLogInCircle /> Approved</span>
			case 50:
				return text ? 'Payment In Transit' : <span className="badge bg-secondary">{this.globalStore.getGlobalImg('intransitsecondarydark', '12px')} Payment In Transit</span>
			case 100:
				return text ? 'Paid' : <span className="badge bg-success"><BiDollarCircle /> Paid</span>
			default:
				return text ? '-' : '-'
		}
	}

	getVendorStatus(pstatus, text) {
		let status = parseInt(pstatus)
		switch (status) {
			case -99:
				return text ? 'Deleted' : <span className="badge bg-danger"><BiTrash /> Deleted</span>
			case 1:
				return text ? 'Active' : <span className="badge bg-primary"><BiCheckCircle /> Active</span>
			case 0:
				return text ? 'Inactive' : <span className="badge bg-light"><BiMinusCircle /> Inactive</span>
			default:
				return text ? '-' : '-'
		}
	}

	getBatchStatus(status, text) {
		switch (status) {
			case 1:
				return <span className={text === true ? 'green-text' : 'badge bg-success'}>Originated</span>
			case 2:
				return <span className={text === true ? 'text-info' : 'badge bg-info'}>Settled</span>
			case 3:
				return <span className={text === true ? 'text-warning' : 'badge bg-warning'}>Funded</span>
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}

	getOrgSettings(id) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'Organization/settings/' + id, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	refundTransaction(transIdToVoid, amount) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'MoneyIn/refund/' + transIdToVoid + '/' + amount, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	captureTransaction(transId, amount) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'MoneyIn/capture/' + transId + '/' + amount, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	async captureTransactionOut(transId) {
		const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return await axios
			.get(process.env.REACT_APP_URL_API + 'MoneyOut/capture/' + transId, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	async sendVCardLink(transId) {
		const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
			return await axios
				.get(`${process.env.REACT_APP_URL_API}MoneyOut/vcard/send-card-link/${transId}`,
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				})
				.then((res) => {
					return res
				})
				.catch((error) => {
					throw error
				}
			)
	}

	async executeCaptureAndSendVCardLink(transId, status) {
		try {
			if (status === 11){
				const captureResponse = await this.captureTransactionOut(transId)
				if (!captureResponse.data.isSuccess){
					return  { isSuccess: false, message: captureResponse.data.responseText ?? 'Error while capturing transaction' }
				}
			}
			const sendVcardLinkResponse = await this.sendVCardLink(transId)
			if (sendVcardLinkResponse.data.success){
				return { isSuccess: true, message: sendVcardLinkResponse.data.message ?? 'Transaction captured and VCard link sent successfully' }
			}
			return  { isSuccess: false, message: sendVcardLinkResponse.data.responseText ?? 'Error while sending VCard link'}
		} catch (error) {
			return { isSuccess: false, message: error.toString() }
		}
	}

	sendReceiptTransaction(transIdToSendReceipt, sendReceiptAddress) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'MoneyIn/sendreceipt/' + transIdToSendReceipt + '?email=' + sendReceiptAddress, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {})
			.catch((error) => {
				throw error
			})
	}

	payBillMoneyOut(billToPay) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let entryPoint = billToPay.PaypointEntryname
		return axios
			.post(
				process.env.REACT_APP_URL_API + 'MoneyOut/authorize',
				{
					entryPoint: entryPoint,
					invoiceData: [
						{
							billId: billToPay.IdBill
						}
					],
					vendorData: {
						vendorNumber: billToPay.Vendor.VendorNumber
					}
				},
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	sendForApproval(idBill, emails) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.post(process.env.REACT_APP_URL_API + 'Bill/approval/' + idBill, emails, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	async markInvoiceAsPaid(invoiceId) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		try {
			const payload = {
				invoiceData: {
					invoiceStatus: 4
				}
			}
			await axios.put(process.env.REACT_APP_URL_API + 'Invoice/' + invoiceId, payload, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
		} catch (error) {
			throw error
		}
	}

	@action
	async deleteInvoice(invoiceId) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		try {
			await axios.delete(process.env.REACT_APP_URL_API + 'Invoice/' + invoiceId, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			let records = this.records
			records.forEach(function (item, index) {
				if (item.invoiceId === invoiceId) {
					delete records[index]
					return
				}
			})
		} catch (error) {
			throw error
		}
	}

	cancelTransactionMoneyOut(transIdToCancel) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'MoneyOut/cancel/' + transIdToCancel, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	getPartnerPaypoints() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId

		return axios
			.get(process.env.REACT_APP_URL_API + 'Query/paypoints/' + orgId + '?limitRecord=-1&status(eq)=1', {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((res) => {
				return res.data
			})
			.catch((error) => {
				throw error
			})
	}

	getListPartners() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId

        return axios.get(process.env.REACT_APP_URL_API + 'Query/organizations/' + orgId, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((res) => {
				return res.data
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	cancelApp(appIdToModify) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.delete(process.env.REACT_APP_URL_API + 'boarding/app/' + appIdToModify, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				let records = this.records
				records.forEach(function (item, index) {
					if (item.IdApplication === appIdToModify) {
						records.splice(index, 1)
						return
					}
				})
			})
			.catch((error) => {
				throw error
			})
	}

	@action
	deleteTemplate(appIdToModify) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.delete(process.env.REACT_APP_URL_API + 'templates/' + appIdToModify, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				let records = this.records
				records.forEach(function (item, index) {
					if (item.Id === appIdToModify) {
						records.splice(index, 1)
						return
					}
				})
			})
			.catch((error) => {
				throw error
			})
	}
}

const reportsStore = new ReportsStore()
export default reportsStore
