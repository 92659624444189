import React from 'react'
import { observable, makeObservable, action, computed } from 'mobx'
import visa from '../assets/images/visa.svg'
import wallet from '../assets/images/wallet.svg'
import mastercard from '../assets/images/mastercard.svg'
import amex from '../assets/images/amex.svg'
import discover from '../assets/images/discover.svg'
import jcb from '../assets/images/jcb.svg'
import card from '../assets/images/card.svg'
import applepay from '../assets/images/apple-pay.svg'
import googlepay from '../assets/images/google-play.svg'
import paypal from '../assets/images/paypal.svg'
import dinersclub from '../assets/images/dinersclub.svg'
import both from '../assets/images/both.svg'
import ach from '../assets/images/ach.svg'
import papercheckblue from '../assets/images/check-blue.svg'
import papercheck from '../assets/images/check.svg'
import timer from '../assets/images/timer.svg'
import cash from '../assets/images/cash.svg'
import vcard from '../assets/images/vcard.svg'
import { PayabliStorageManager } from '../api/localStorageManager'
import device from '../assets/images/device.svg'
import blockCc from '../assets/images/crc-block-cc.svg'
import blockIp from '../assets/images/crc-block-ip.svg'
import blockPp from '../assets/images/crc-block-pp.svg'
import blockUser from '../assets/images/crc-block-user.svg'
import holdBatch from '../assets/images/crc-hold-batch.svg'
import holdTrx from '../assets/images/crc-hold-trx.svg'
import blockCcColor from '../assets/images/crc-block-cc-color.svg'
import blockIpColor from '../assets/images/crc-block-ip-color.svg'
import blockPpColor from '../assets/images/crc-block-pp-color.svg'
import blockUserColor from '../assets/images/crc-block-user-color.svg'
import holdBatchColor from '../assets/images/crc-hold-batch-color.svg'
import holdTrxColor from '../assets/images/crc-hold-trx-color.svg'
import pointOfSale from '../assets/images/pointOfService.svg';
import icTransferInTransitInfo from '../assets/images/ic-transfer-in-transit-info.svg'
import icTransferInTransitInfoDark from '../assets/images/ic-transfer-in-transit-info-dark.svg'
import icTransferInTransitSecondary from '../assets/images/ic-transfer-in-transit-secondary.svg'
import icTransferInTransitSecondaryDark from '../assets/images/ic-transfer-in-transit-secondary-dark.svg'
import { BiCreditCardFront } from '@react-icons/all-files/bi/BiCreditCardFront'
import { RiBankFill } from '@react-icons/all-files/ri/RiBankFill'
import { BiDollarCircle, BiPaint, BiDoughnutChart, BiXCircle, BiUpArrowCircle, BiShare, BiDollar, BiAdjust, BiRotateLeft, 
	BiAlarm, BiCalendarCheck, BiCalendar, BiCalendarEvent, BiLogOutCircle, BiLoader, BiLogInCircle, BiErrorCircle, BiArchive, BiBox, 
	  BiCheckCircle, BiEnvelope, BiHourglass, BiRocket, BiCheckDouble, BiDisc, BiPauseCircle, BiMinusCircle, BiCabinet, BiPaperPlane, BiPen, BiShieldQuarter, 
	  BiSend,
	  BiMoneyWithdraw} from 'react-icons/bi'
import { HiCheckCircle, HiXCircle } from 'react-icons/hi'
import { CgUnavailable } from 'react-icons/cg'
import pushtocard from '../assets/images/push-to-card.svg'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { allCountries } from 'country-region-data';
import { MdApproval, MdOutlineCancel, MdOutlineRateReview } from 'react-icons/md'
import { AiOutlineException } from 'react-icons/ai'
import { LuGoal } from 'react-icons/lu'

class GlobalStore {
	constructor() {
		makeObservable(this)
	}

	@observable
	loading = false

	@observable
	rightPanelOpen = false

	@observable
	filterRightPanelOpen = false

	@observable
	slideParentViewOpen = false

	@observable
	globalSearchbarFilters = {}

	@observable
	coordClickDown = null

	@observable
	coordClickUp = null

	@observable
	brandingImg = null

	@action
	addGloablSearchbarFilters(name, value) {
		this.globalSearchbarFilters[name] = value
	}

	@action
	setCoordClickDown(coords) {
		this.coordClickDown = coords
	}

	@action
	setCoordClickUp(coords) {
		this.coordClickUp = coords
	}

	@action
	resetGloablSearchbarFilters() {
		this.globalSearchbarFilters = {}
	}

	@action
	numberWithCommas(x) {
		if (x) {
			let numString = x.toString().replace(/.(?=(?:.{3})+$)/g, '$&,')
			return numString.replace(',.', '.')
		}
		return 0
	}

	@action
	formatDecimalDynamics(x) {
		let dec = 2
		if (Math.floor(x) !== x && x.toString().includes('.')) {
			let temp = x.toString().split('.')[1].length
			if (temp > 4) {
				dec = 4
			} else if (temp > 2) {
				dec = temp
			}
		}
		return dec
	}

	@action
	numberWithCommasNegative(x, isNotMoney) {
		if (x === null || x === undefined) {
			return '$0.00';
		}

		let negative = false
		if (x < 0) {
			negative = true
			x = (x * -1).toFixed(2)
		}
		let numString = x.toString().replace(/.(?=(?:.{3})+$)/g, '$&,')
		return negative === true ? <span className='red-text-negative-number'>{`-${isNotMoney === true ? '':'$'}${numString.replace(',.', '.')}`}</span> : (isNotMoney === true ? '':'$') + numString.replace(',.', '.')
	}

	@action
	validateIPaddress(ipaddress) {
		if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
			return true
		}
		return false
	}

	@action
	getTextParseMsgError(value) {
		return value?.response?.data?.responseData?.explanation ? value.response.data.responseData.explanation : 'Something is Wrong!'
	}

	@action
	setBrandingImg(brandingImg) {
		this.brandingImg = brandingImg;
	}
	
	@computed
	get getBrandingImg() {
		return this.brandingImg;
	}

	newTabRedirection(url, e) {
		if (e) {
			e.preventDefault()
		}
		window.open(url, '_blank').focus()
	}

	frequencyText(frequency) {
		switch (frequency) {
			case 'onetime':
				return 'Onetime'
			case 'weekly':
				return 'Weekly'
			case 'every2weeks':
				return 'Every 2 Weeks'
			case 'every6months':
				return 'Every 6 Months'
			case 'monthly':
				return 'Monthly'
			case 'every3months':
				return 'Every 3 months'
			case 'annually':
				return 'Annually'
			default:
				return 'Unknow'
		}
	}

	getFileExtension(type) {
		switch (type.toLowerCase()) {
			case 'image/jpeg':
				return 'jpeg'
			case 'image/png':
				return 'png'
			case 'image/jpg':
				return 'jpg'
			case 'application/pdf':
				return 'pdf'
			case 'application/msword	':
				return 'doc'
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				return 'docx'
			case 'application/x-x509-ca-cert':
				return 'pem'
			default:
				return null
		}
	}

	readFileBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result)
			}
			fileReader.onerror = (error) => {
				reject(error)
			}
		})
	}

	getMoneyOutTransStatus(pstatus, text) {
		let status = parseInt(pstatus)

		if (status < 0) {
			return text ? 'Error' : <span className="badge bg-danger">Error</span>
		}

		switch (status) {
			case 0:
				return text ? 'Cancelled' : <span className="badge bg-danger"><BiXCircle /> Cancelled</span>
			case 1:
				return text ? 'Captured' : <span className="badge bg-secondary"><BiDisc /> Captured</span>
			case 2:
				return text ? 'Processing' : <span className="badge bg-warning"><BiLoader /> Processing</span>
			case 3:
				return text ? 'Processed' : <span className="badge bg-info">{this.getGlobalImg('intransitinfodark', '12px')} Processed</span>
			case 5:
				return text ? 'Paid' : <span className="badge bg-success"><BiDollarCircle /> Paid</span>
			case 11:
				return text ? 'Authorized' : <span className="badge bg-primary"><BiLogInCircle /> Authorized</span>
			default:
				return text ? 'N/A' : <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}

	createPasswordLabel(result) {
		switch (result) {
			case 0:
				return 'Weak'
			case 1:
				return 'Weak'
			case 2:
				return 'Fair'
			case 3:
				return 'Good'
			case 4:
				return 'Strong'
			default:
				return 'Weak'
		}
	}

	luhnCheck(ccNum) {
		let arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
		let len = ccNum.length,
			bit = 1,
			sum = 0,
			val

		while (len) {
			val = parseInt(ccNum.charAt(--len), 10)
			sum += (bit ^= 1) ? arr[val] : val
		}

		return sum && sum % 10 === 0
	}

	isValidRouting(routing) {
		if (routing.length !== 9) {
			return false
		}
		let checksumTotal = 3 * parseInt(routing.charAt(0), 10) + 7 * parseInt(routing.charAt(1), 10) + 1 * parseInt(routing.charAt(2), 10) + 3 * parseInt(routing.charAt(3), 10) + 7 * parseInt(routing.charAt(4), 10) + 1 * parseInt(routing.charAt(5), 10) + 3 * parseInt(routing.charAt(6), 10) + 7 * parseInt(routing.charAt(7), 10) + 1 * parseInt(routing.charAt(8), 10)
		return checksumTotal % 10 === 0
	}

	creditCardType(number) {
		if (!number || number === '') {
			return 'unknown'
		}

		if (number.length > 14) {
			if (this.luhnCheck(number) === false) {
				return 'unknown'
			}
		}

		let creditCardType = require('credit-card-type')
		let visaCards = creditCardType(number)
		if (visaCards[0]) {
			return visaCards[0].type
		} else {
			return 'unknown'
		}
	}

	getGlobalImg(type, height) {
		if (!type) {
			return '-'
		}
		switch (type?.toLowerCase()) { 
			case 'blockcc': 
				return ( <img style={{height: height}} alt="" src={blockCc} /> ) 
			case 'blockip': 
				return ( <img style={{height: height}} alt="" src={blockIp} /> ) 
			case 'blockpp': 
				return ( <img style={{height: height}} alt="" src={blockPp} /> ) 
			case 'blockuser': 
				return ( <img style={{height: height}} alt="" src={blockUser} /> ) 
			case 'holdbatch': 
				return ( <img style={{height: height}} alt="" src={holdBatch} /> ) 
			case 'holdtrx':
				return ( <img style={{ height: height }} alt="" src={holdTrx} /> )
			case 'blockcccolor':
				return ( <img style={{ height: height }} alt="" src={blockCcColor} /> )
			case 'blockipcolor':
				return ( <img style={{ height: height }} alt="" src={blockIpColor} /> )
			case 'blockppcolor':
				return ( <img style={{ height: height }} alt="" src={blockPpColor} /> )
			case 'blockusercolor':
				return ( <img style={{ height: height }} alt="" src={blockUserColor} /> )
			case 'holdbatchcolor':
				return ( <img style={{ height: height }} alt="" src={holdBatchColor} /> )
			case 'holdtrxcolor':
				return ( <img style={{height: height}} alt="" src={holdTrxColor} /> )
			case 'wallet': 
				return ( <img style={{height: height}} alt="" src={wallet} /> )
			case 'visa': 
				return ( <img style={{height: height}} alt="" src={visa} /> ) 
			case 'mastercard': 
				return ( <img style={{height: height}} alt="" src={mastercard} /> ) 
			case 'discover': 
				return ( <img style={{height: height}} alt="" src={discover} /> ) 
			case 'jcb': 
				return ( <img style={{height: height}} alt="" src={jcb} /> ) 
			case 'amex': 
			case 'american-express': 
			case 'american express': 
				return ( <img style={{height: height}} alt="" src={amex} /> ) 
			case 'card': 
				return ( <img style={{height: height}} alt="" src={card} /> ) 
			case 'vcard': 
				return ( <img style={{height: height}} alt="" src={vcard} /> ) 
			case 'cash': 
				return ( <img style={{height: height}} alt="" src={cash} /> ) 
			case 'check': 
				return ( <img style={{height: height}} alt="" src={papercheck} /> ) 
			case 'checkblue': 
				return ( <img className="papercheckblue" style={{height: height, color: 'blue'}} alt="" src={papercheckblue} /> ) 
			case 'timer': 
			case 'managed': 
			case 'pending': 
				return ( <img style={{height: height}} alt="" src={timer} /> )
			case 'device': 
			case 'cloud': 
				return ( <img style={{height: height}} alt="" src={device} /> ) 
			case 'applepay': 
				return ( <img style={{height: height}} alt="" src={applepay} /> ) 
			case 'googlepay': 
				return ( <img style={{height: height}} alt="" src={googlepay} /> ) 
			case 'paypal': 
				return ( <img style={{height: height}} alt="" src={paypal} /> ) 
			case 'dinersclub': 
				return ( <img style={{height: height}} alt="" src={dinersclub} /> ) 
			case 'both': 
			case 'combined':
				return ( <img style={{height: height}} alt="" src={both} /> ) 
			case 'savings':
			case 'checking':
			case 'ach':
				return ( <img style={{height: height}} alt="" src={ach} /> ) 
			case 'pointofsale':
				return ( <img style={{height: height}} alt="" src={pointOfSale} /> )
			case 'intransitinfo':
				return ( <img style={{height: height}} alt="" src={icTransferInTransitInfo} /> )
			case 'intransitinfodark':
				return ( <img style={{height: height}} alt="" src={icTransferInTransitInfoDark} /> )
			case 'intransitsecondary':
				return ( <img style={{height: height}} alt="" src={icTransferInTransitSecondary} /> )
			case 'intransitsecondarydark':
				return ( <img style={{height: height}} alt="" src={icTransferInTransitSecondaryDark} /> )
			case 'pushtocard':
				return ( <img style={{height: height}} alt="" src={pushtocard} /> )
			default: 
				return '-'
		}
	}

	getCardTypeFromMasked(cardNumber) {
		const cleaned = cardNumber.replace(/\s+/g, '').replace(/[^0-9X]/g, '')	
		const cardPatterns = {
			visa: /^4[0-9X]{10}$/,
			mastercard: /^5[0-9X]{10}$/,
			amex: /^3[0-9X]{10}$/,
			discover: /^6[0-9X]{10}$/,
			jcb: /^[12][0-9X]{10}$/,
		}	
		for (const [cardType, pattern] of Object.entries(cardPatterns)) {
			if (pattern.test(cleaned)) {
				return cardType
			}
		}	
		return null
	}

	getPaymethodImgPaymentData(record, sizePx) {    
		const { PaymentData, Method } = record || {};
		const { AccountType, MaskedAccount, binData } = PaymentData || {};
		const { binCardBrand } = binData || {};
		if (AccountType && AccountType !== 'unknow') {        
						if (MaskedAccount?.toLowerCase() === 'poi') {
								return this.getPaymethodImg('device', sizePx);
						}        
						return this.getPaymethodImg(AccountType, sizePx);
		}    
		if (binCardBrand) {
				return this.getPaymethodImg(binCardBrand?.toLowerCase(), sizePx);
		}
		if (MaskedAccount && Method?.toLowerCase() === 'card') {
				const type = this.getCardTypeFromMasked(MaskedAccount);
				if (type) { return this.getPaymethodImg(type, sizePx); }
		}
		return this.getPaymethodImg(Method?.toLowerCase(), sizePx);
	}

	getPaymethodImg(method, sizePx){
		return this.getGlobalImg(method, sizePx || '24px')
	}

	getPaymethodTypeImg(method) {
		if (!method) {
			return '-'
		}
		switch (method.toLowerCase()) {
			case 'card':
				return <BiCreditCardFront style={{ fontSize: '20px' }} />
			default:
				return <RiBankFill style={{ fontSize: '20px' }} />
		}
	}

	mcc = [
		{
			mcc: '0742',
			edited_description: 'Veterinary Services',
			combined_description: 'Veterinary Services',
			usda_description: 'Veterinary Services',
			irs_description: 'Veterinary Services',
			irs_reportable: 'Yes',
			id: 0
		},
		{
			mcc: '0763',
			edited_description: 'Agricultural Co-operatives',
			combined_description: 'Agricultural Co-operatives',
			usda_description: 'Agricultural Co-operatives',
			irs_description: 'Agricultural Cooperative',
			irs_reportable: 'Yes',
			id: 1
		},
		{
			mcc: '0780',
			edited_description: 'Horticultural Services, Landscaping Services',
			combined_description: 'Horticultural Services, Landscaping Services',
			usda_description: 'Horticultural Services',
			irs_description: 'Landscaping Services',
			irs_reportable: 'Yes',
			id: 2
		},
		{
			mcc: '1520',
			edited_description: 'General Contractors-Residential and Commercial',
			combined_description: 'General Contractors-Residential and Commercial',
			usda_description: 'General Contractors-Residential and Commercial',
			irs_description: 'General Contractors',
			irs_reportable: 'Yes',
			id: 3
		},
		{
			mcc: '1711',
			edited_description: "Air Conditioning Contractors ' Sales and Installation, Heating Contractors ' Sales, Service, Installation",
			combined_description: "Air Conditioning Contractors ' Sales and Installation, Heating Contractors ' Sales, Service, Installation",
			usda_description: "Air Conditioning Contractors ' Sales and Installation",
			irs_description: 'Heating, Plumbing, A/C',
			irs_reportable: 'Yes',
			id: 4
		},
		{
			mcc: '1731',
			edited_description: 'Electrical Contractors',
			combined_description: 'Electrical Contractors',
			usda_description: 'Electrical Contractors',
			irs_description: 'Electrical Contractors',
			irs_reportable: 'Yes',
			id: 5
		},
		{
			mcc: '1740',
			edited_description: "Insulation ' Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework and Masonry Contractors, Tile Settings Contractors",
			combined_description: "Insulation ' Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework and Masonry Contractors, Tile Settings Contractors",
			usda_description: "Insulation ' Contractors",
			irs_description: 'Masonry, Stonework, and Plaster',
			irs_reportable: 'Yes',
			id: 6
		},
		{
			mcc: '1750',
			edited_description: 'Carpentry Contractors',
			combined_description: 'Carpentry Contractors',
			usda_description: 'Carpentry Contractors',
			irs_description: 'Carpentry Contractors',
			irs_reportable: 'Yes',
			id: 7
		},
		{
			mcc: '1761',
			edited_description: "Roofing ' Contractors, Sheet Metal Work ' Contractors, Siding ' Contractors",
			combined_description: "Roofing ' Contractors, Sheet Metal Work ' Contractors, Siding ' Contractors",
			usda_description: 'Roofing - Contractors',
			irs_description: 'Roofing/Siding, Sheet Metal',
			irs_reportable: 'Yes',
			id: 8
		},
		{
			mcc: '1771',
			edited_description: "Contractors ' Concrete Work",
			combined_description: "Contractors ' Concrete Work",
			usda_description: "Contractors ' Concrete Work",
			irs_description: 'Concrete Work Contractors',
			irs_reportable: 'Yes',
			id: 9
		},
		{
			mcc: '1799',
			edited_description: "Contractors ' Special Trade, Not Elsewhere Classified",
			combined_description: "Contractors ' Special Trade, Not Elsewhere Classified",
			usda_description: "Contractors ' Special Trade, Not Elsewhere Classified",
			irs_description: 'Special Trade Contractors',
			irs_reportable: 'Yes',
			id: 10
		},
		{
			mcc: '2741',
			edited_description: 'Miscellaneous Publishing and Printing',
			combined_description: 'Miscellaneous Publishing and Printing',
			usda_description: 'Miscellaneous Publishing and Printing',
			irs_description: 'Miscellaneous Publishing and Printing',
			irs_reportable: 'Yes',
			id: 11
		},
		{
			mcc: '2791',
			edited_description: 'Typesetting, Plate Making, & Related Services',
			combined_description: 'Typesetting, Plate Making, & Related Services',
			usda_description: 'Typesetting, Plate Making, & Related Services',
			irs_description: 'Typesetting, Plate Making, and Related Services',
			irs_reportable: 'Yes',
			id: 12
		},
		{
			mcc: '2842',
			edited_description: 'Specialty Cleaning, Polishing, and Sanitation Preparations',
			combined_description: 'Specialty Cleaning, Polishing, and Sanitation Preparations',
			usda_description: 'Specialty Cleaning, Polishing, and Sanitation Preparations',
			irs_description: 'Specialty Cleaning',
			irs_reportable: 'Yes',
			id: 13
		},
		{
			mcc: '3003',
			edited_description: 'Airlines',
			combined_description: 'Airlines',
			usda_description: 'Airlines',
			irs_description: 'Airlines',
			irs_reportable: 'Yes',
			id: 17
		},
		{
			mcc: '4011',
			edited_description: 'Railroads',
			combined_description: 'Railroads',
			usda_description: 'Railroads',
			irs_description: 'Railroads',
			irs_reportable: 'No1.6041-3(c)',
			id: 697
		},
		{
			mcc: '4111',
			edited_description: "Local/Suburban Commuter Passenger Transportation ' Railroads, Feries, Local Water Transportation.",
			combined_description: "Local/Suburban Commuter Passenger Transportation ' Railroads, Feries, Local Water Transportation.",
			usda_description: "Local/Suburban Commuter Passenger Transportation ' Railroads, Feries, Local Water Transportation.",
			irs_description: 'Commuter Transport, Ferries',
			irs_reportable: 'Yes',
			id: 698
		},
		{
			mcc: '4112',
			edited_description: 'Passenger Railways',
			combined_description: 'Passenger Railways',
			usda_description: 'Passenger Railways',
			irs_description: 'Passenger Railways',
			irs_reportable: 'Yes',
			id: 699
		},
		{
			mcc: '4119',
			edited_description: 'Ambulance Services',
			combined_description: 'Ambulance Services',
			usda_description: 'Ambulance Services',
			irs_description: 'Ambulance Services',
			irs_reportable: 'Yes',
			id: 700
		},
		{
			mcc: '4121',
			edited_description: 'Taxicabs and Limousines',
			combined_description: 'Taxicabs and Limousines',
			usda_description: 'Taxicabs and Limousines',
			irs_description: 'Taxicabs/Limousines',
			irs_reportable: 'Yes',
			id: 701
		},
		{
			mcc: '4131',
			edited_description: 'Bus Lines, Including Charters, Tour Buses',
			combined_description: 'Bus Lines, Including Charters, Tour Buses',
			usda_description: 'Bus Lines, Including Charters, Tour Buses',
			irs_description: 'Bus Lines',
			irs_reportable: 'Yes',
			id: 702
		},
		{
			mcc: '4214',
			edited_description: "Motor Freight Carriers, Moving and Storage Companies, Trucking ' Local/Long Distance, Delivery Services ' Local",
			combined_description: "Motor Freight Carriers, Moving and Storage Companies, Trucking ' Local/Long Distance, Delivery Services ' Local",
			usda_description: 'Motor Freight Carriers',
			irs_description: 'Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services ',
			irs_reportable: 'No1.6041-3(c)',
			id: 703
		},
		{
			mcc: '4215',
			edited_description: "Courier Services ' Air or Ground, Freight forwarders",
			combined_description: "Courier Services ' Air or Ground, Freight forwarders",
			usda_description: "Courier Services ' Air or Ground",
			irs_description: 'Courier Services ',
			irs_reportable: 'Yes',
			id: 704
		},
		{
			mcc: '4225',
			edited_description: 'Public warehousing, Storage',
			combined_description: 'Public warehousing, Storage',
			usda_description: 'Warehousing, Public',
			irs_description: 'Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage ',
			irs_reportable: 'No1.6041-3(c)',
			id: 705
		},
		{
			mcc: '4411',
			edited_description: 'Cruise and Steamship Lines',
			combined_description: 'Cruise and Steamship Lines',
			usda_description: 'Cruise Lines',
			irs_description: 'Cruise Lines',
			irs_reportable: 'Yes',
			id: 706
		},
		{
			mcc: '4457',
			edited_description: 'Boat Rentals and Leases',
			combined_description: 'Boat Rentals and Leases',
			usda_description: 'Boat Rentals and Leases',
			irs_description: 'Boat Rentals and Leases',
			irs_reportable: 'Yes',
			id: 707
		},
		{
			mcc: '4468',
			edited_description: 'Marinas, Marine Service, and Supplies',
			combined_description: 'Marinas, Marine Service, and Supplies',
			usda_description: 'Marinas, Marine Service, and Supplies',
			irs_description: 'Marinas, Service and Supplies',
			irs_reportable: 'Yes',
			id: 708
		},
		{
			mcc: '4511',
			edited_description: 'Airlines, Air Carriers ( not listed elsewhere)',
			combined_description: 'Airlines, Air Carriers ( not listed elsewhere)',
			usda_description: 'Airlines, Air Carriers ( not listed elsewhere)',
			irs_description: 'Airlines, Air Carriers',
			irs_reportable: 'Yes',
			id: 709
		},
		{
			mcc: '4582',
			edited_description: 'Airports, Airport Terminals, Flying Fields',
			combined_description: 'Airports, Airport Terminals, Flying Fields',
			usda_description: 'Airports, Airport Terminals',
			irs_description: 'Airports, Flying Fields',
			irs_reportable: 'Yes',
			id: 710
		},
		{
			mcc: '4722',
			edited_description: 'Travel Agencies and Tour Operations',
			combined_description: 'Travel Agencies and Tour Operations',
			usda_description: 'Travel Agencies and Tour Operations',
			irs_description: 'Travel Agencies, Tour Operators',
			irs_reportable: 'Yes',
			id: 711
		},
		{
			mcc: '4784',
			edited_description: 'Toll and Bridge Fees',
			combined_description: 'Toll and Bridge Fees',
			usda_description: 'Toll and Bridge Fees',
			irs_description: 'Tolls/Bridge Fees',
			irs_reportable: 'No1.6041-3(c)',
			id: 713
		},
		{
			mcc: '4789',
			edited_description: 'Transportation Services, Not elsewhere classified)',
			combined_description: 'Transportation Services, Not elsewhere classified)',
			usda_description: 'Transportation Services, Not elsewhere classified)',
			irs_description: 'Transportation Services (Not Elsewhere Classified)',
			irs_reportable: 'Yes',
			id: 714
		},
		{
			mcc: '4812',
			edited_description: 'Telecommunications Equipment including telephone sales',
			combined_description: 'Telecommunications Equipment including telephone sales',
			usda_description: 'Telecommunications Equipment including telephone sales',
			irs_description: 'Telecommunication Equipment and Telephone Sales',
			irs_reportable: 'No1.6041-3(c)',
			id: 715
		},
		{
			mcc: '4814',
			edited_description: 'Fax services, Telecommunication Services',
			combined_description: 'Fax services, Telecommunication Services',
			usda_description: 'Fax services',
			irs_description: 'Telecommunication Services',
			irs_reportable: 'No1.6041-3(c)',
			id: 716
		},
		{
			mcc: '4816',
			edited_description: 'Computer Network Services',
			combined_description: 'Computer Network Services',
			usda_description: 'Computer Network Services',
			irs_description: 'Computer Network Services',
			irs_reportable: 'No1.6041-3(c)',
			id: 718
		},
		{
			mcc: '4821',
			edited_description: 'Telegraph services',
			combined_description: 'Telegraph services',
			usda_description: 'Telegraph services',
			irs_description: 'Telegraph Services',
			irs_reportable: 'No1.6041-3(c)',
			id: 719
		},
		{
			mcc: '4829',
			edited_description: "Money Orders ' Wire Transfer",
			combined_description: "Money Orders ' Wire Transfer",
			usda_description: "Money Orders ' Wire Transfer",
			irs_description: 'Wires, Money Orders',
			irs_reportable: 'No1.6041-3(c)',
			id: 720
		},
		{
			mcc: '4899',
			edited_description: 'Cable and other pay television (previously Cable Services)',
			combined_description: 'Cable and other pay television (previously Cable Services)',
			usda_description: 'Cable and other pay television (previously Cable Services)',
			irs_description: 'Cable, Satellite, and Other Pay Television and Radio',
			irs_reportable: 'No1.6041-3(c)',
			id: 721
		},
		{
			mcc: '4900',
			edited_description: 'Electric, Gas, Sanitary and Water Utilities',
			combined_description: 'Electric, Gas, Sanitary and Water Utilities',
			usda_description: 'Electric, Gas, Sanitary and Water Utilities',
			irs_description: 'Utilities ',
			irs_reportable: 'No1.6041-3(c)',
			id: 722
		},
		{
			mcc: '5013',
			edited_description: 'Motor vehicle supplies and new parts',
			combined_description: 'Motor vehicle supplies and new parts',
			usda_description: 'Motor vehicle supplies and new parts',
			irs_description: 'Motor Vehicle Supplies and New Parts',
			irs_reportable: 'No1.6041-3(c)',
			id: 723
		},
		{
			mcc: '5021',
			edited_description: 'Office and Commercial Furniture',
			combined_description: 'Office and Commercial Furniture',
			usda_description: 'Office and Commercial Furniture',
			irs_description: 'Office and Commercial Furniture',
			irs_reportable: 'No1.6041-3(c)',
			id: 724
		},
		{
			mcc: '5039',
			edited_description: 'Construction Materials, Not Elsewhere Classified',
			combined_description: 'Construction Materials, Not Elsewhere Classified',
			usda_description: 'Construction Materials, Not Elsewhere Classified',
			irs_description: 'Construction Materials (Not Elsewhere Classified)',
			irs_reportable: 'No1.6041-3(c)',
			id: 725
		},
		{
			mcc: '5044',
			edited_description: 'Office, Photographic, Photocopy, and Microfilm Equipment',
			combined_description: 'Office, Photographic, Photocopy, and Microfilm Equipment',
			usda_description: 'Office, Photographic, Photocopy, and Microfilm Equipment',
			irs_description: 'Photographic, Photocopy, Microfilm Equipment, and Supplies',
			irs_reportable: 'No1.6041-3(c)',
			id: 726
		},
		{
			mcc: '5045',
			edited_description: 'Computers, Computer Peripheral Equipment, Software',
			combined_description: 'Computers, Computer Peripheral Equipment, Software',
			usda_description: 'Computers, Computer Peripheral Equipment, Software',
			irs_description: 'Computers, Peripherals, and Software',
			irs_reportable: 'No1.6041-3(c)',
			id: 727
		},
		{
			mcc: '5046',
			edited_description: 'Commercial Equipment, Not Elsewhere Classified',
			combined_description: 'Commercial Equipment, Not Elsewhere Classified',
			usda_description: 'Commercial Equipment, Not Elsewhere Classified',
			irs_description: 'Commercial Equipment (Not Elsewhere Classified)',
			irs_reportable: 'No1.6041-3(c)',
			id: 728
		},
		{
			mcc: '5047',
			edited_description: 'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
			combined_description: 'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
			usda_description: 'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
			irs_description: 'Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies',
			irs_reportable: 'No1.6041-3(c)',
			id: 729
		},
		{
			mcc: '5051',
			edited_description: 'Metal Service Centers and Offices',
			combined_description: 'Metal Service Centers and Offices',
			usda_description: 'Metal Service Centers and Offices',
			irs_description: 'Metal Service Centers',
			irs_reportable: 'No1.6041-3(c)',
			id: 730
		},
		{
			mcc: '5065',
			edited_description: 'Electrical Parts and Equipment',
			combined_description: 'Electrical Parts and Equipment',
			usda_description: 'Electrical Parts and Equipment',
			irs_description: 'Electrical Parts and Equipment',
			irs_reportable: 'No1.6041-3(c)',
			id: 731
		},
		{
			mcc: '5072',
			edited_description: 'Hardware Equipment and Supplies',
			combined_description: 'Hardware Equipment and Supplies',
			usda_description: 'Hardware Equipment and Supplies',
			irs_description: 'Hardware, Equipment, and Supplies',
			irs_reportable: 'No1.6041-3(c)',
			id: 732
		},
		{
			mcc: '5074',
			edited_description: 'Plumbing and Heating Equipment and Supplies',
			combined_description: 'Plumbing and Heating Equipment and Supplies',
			usda_description: 'Plumbing and Heating Equipment and Supplies',
			irs_description: 'Plumbing, Heating Equipment, and Supplies',
			irs_reportable: 'No1.6041-3(c)',
			id: 733
		},
		{
			mcc: '5085',
			edited_description: 'Industrial Supplies, Not Elsewhere Classified',
			combined_description: 'Industrial Supplies, Not Elsewhere Classified',
			usda_description: 'Industrial Supplies, Not Elsewhere Classified',
			irs_description: 'Industrial Supplies (Not Elsewhere Classified)',
			irs_reportable: 'No1.6041-3(c)',
			id: 734
		},
		{
			mcc: '5094',
			edited_description: 'Precious Stones and Metals, Watches and Jewelry',
			combined_description: 'Precious Stones and Metals, Watches and Jewelry',
			usda_description: 'Precious Stones and Metals, Watches and Jewelry',
			irs_description: 'Precious Stones and Metals, Watches and Jewelry',
			irs_reportable: 'No1.6041-3(c)',
			id: 735
		},
		{
			mcc: '5099',
			edited_description: 'Durable Goods, Not Elsewhere Classified',
			combined_description: 'Durable Goods, Not Elsewhere Classified',
			usda_description: 'Durable Goods, Not Elsewhere Classified',
			irs_description: 'Durable Goods (Not Elsewhere Classified)',
			irs_reportable: 'No1.6041-3(c)',
			id: 736
		},
		{
			mcc: '5111',
			edited_description: 'Stationery, Office Supplies, Printing, and Writing Paper',
			combined_description: 'Stationery, Office Supplies, Printing, and Writing Paper',
			usda_description: 'Stationery, Office Supplies, Printing, and Writing Paper',
			irs_description: 'Stationary, Office Supplies, Printing and Writing Paper',
			irs_reportable: 'No1.6041-3(c)',
			id: 737
		},
		{
			mcc: '5122',
			edited_description: "Drugs, Drug Proprietors, and Druggist's Sundries",
			combined_description: "Drugs, Drug Proprietors, and Druggist's Sundries",
			usda_description: "Drugs, Drug Proprietors, and Druggist's Sundries",
			irs_description: 'Drugs, Drug Proprietaries, and Druggist Sundries',
			irs_reportable: 'No1.6041-3(c)',
			id: 738
		},
		{
			mcc: '5131',
			edited_description: 'Piece Goods, Notions, and Other Dry Goods',
			combined_description: 'Piece Goods, Notions, and Other Dry Goods',
			usda_description: 'Piece Goods, Notions, and Other Dry Goods',
			irs_description: 'Piece Goods, Notions, and Other Dry Goods',
			irs_reportable: 'No1.6041-3(c)',
			id: 739
		},
		{
			mcc: '5137',
			edited_description: "Men's Women's and Children's Uniforms and Commercial Clothing",
			combined_description: "Men's Women's and Children's Uniforms and Commercial Clothing",
			usda_description: "Men's Women's and Children's Uniforms and Commercial Clothing",
			irs_description: 'Uniforms, Commercial Clothing',
			irs_reportable: 'No1.6041-3(c)',
			id: 740
		},
		{
			mcc: '5139',
			edited_description: 'Commercial Footwear',
			combined_description: 'Commercial Footwear',
			usda_description: 'Commercial Footwear',
			irs_description: 'Commercial Footwear',
			irs_reportable: 'No1.6041-3(c)',
			id: 741
		},
		{
			mcc: '5169',
			edited_description: 'Chemicals and Allied Products, Not Elsewhere Classified',
			combined_description: 'Chemicals and Allied Products, Not Elsewhere Classified',
			usda_description: 'Chemicals and Allied Products, Not Elsewhere Classified',
			irs_description: 'Chemicals and Allied Products (Not Elsewhere Classified)',
			irs_reportable: 'No1.6041-3(c)',
			id: 742
		},
		{
			mcc: '5172',
			edited_description: 'Petroleum and Petroleum Products',
			combined_description: 'Petroleum and Petroleum Products',
			usda_description: 'Petroleum and Petroleum Products',
			irs_description: 'Petroleum and Petroleum Products',
			irs_reportable: 'No1.6041-3(c)',
			id: 743
		},
		{
			mcc: '5192',
			edited_description: 'Books, Periodicals, and Newspapers',
			combined_description: 'Books, Periodicals, and Newspapers',
			usda_description: 'Books, Periodicals, and Newspapers',
			irs_description: 'Books, Periodicals, and Newspapers',
			irs_reportable: 'No1.6041-3(c)',
			id: 744
		},
		{
			mcc: '5193',
			edited_description: "Florists' Supplies, Nursery Stock and Flowers",
			combined_description: "Florists' Supplies, Nursery Stock and Flowers",
			usda_description: "Florists' Supplies, Nursery Stock and Flowers",
			irs_description: 'Florists Supplies, Nursery Stock, and Flowers',
			irs_reportable: 'No1.6041-3(c)',
			id: 745
		},
		{
			mcc: '5198',
			edited_description: 'Paints, Varnishes, and Supplies',
			combined_description: 'Paints, Varnishes, and Supplies',
			usda_description: 'Paints, Varnishes, and Supplies',
			irs_description: 'Paints, Varnishes, and Supplies',
			irs_reportable: 'No1.6041-3(c)',
			id: 746
		},
		{
			mcc: '5199',
			edited_description: 'Non-durable Goods, Not Elsewhere Classified',
			combined_description: 'Non-durable Goods, Not Elsewhere Classified',
			usda_description: 'Non-durable Goods, Not Elsewhere Classified',
			irs_description: 'Nondurable Goods (Not Elsewhere Classified)',
			irs_reportable: 'No1.6041-3(c)',
			id: 747
		},
		{
			mcc: '5200',
			edited_description: 'Home Supply Warehouse Stores',
			combined_description: 'Home Supply Warehouse Stores',
			usda_description: 'Home Supply Warehouse Stores',
			irs_description: 'Home Supply Warehouse Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 748
		},
		{
			mcc: '5211',
			edited_description: 'Lumber and Building Materials Stores',
			combined_description: 'Lumber and Building Materials Stores',
			usda_description: 'Lumber and Building Materials Stores',
			irs_description: 'Lumber, Building Materials Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 749
		},
		{
			mcc: '5231',
			edited_description: 'Glass, Paint, and Wallpaper Stores',
			combined_description: 'Glass, Paint, and Wallpaper Stores',
			usda_description: 'Wallpaper Stores',
			irs_description: 'Glass, Paint, and Wallpaper Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 750
		},
		{
			mcc: '5251',
			edited_description: 'Hardware Stores',
			combined_description: 'Hardware Stores',
			usda_description: 'Hardware Stores',
			irs_description: 'Hardware Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 751
		},
		{
			mcc: '5261',
			edited_description: "Nurseries ' Lawn and Garden Supply Store",
			combined_description: "Nurseries ' Lawn and Garden Supply Store",
			usda_description: "Nurseries ' Lawn and Garden Supply Store",
			irs_description: 'Nurseries, Lawn and Garden Supply Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 752
		},
		{
			mcc: '5271',
			edited_description: 'Mobile Home Dealers',
			combined_description: 'Mobile Home Dealers',
			usda_description: 'Mobile Home Dealers',
			irs_description: 'Mobile Home Dealers',
			irs_reportable: 'No1.6041-3(c)',
			id: 753
		},
		{
			mcc: '5300',
			edited_description: 'Wholesale Clubs',
			combined_description: 'Wholesale Clubs',
			usda_description: 'Wholesale Clubs',
			irs_description: 'Wholesale Clubs',
			irs_reportable: 'No1.6041-3(c)',
			id: 754
		},
		{
			mcc: '5309',
			edited_description: 'Duty Free Store',
			combined_description: 'Duty Free Store',
			usda_description: 'Duty Free Store',
			irs_description: 'Duty Free Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 755
		},
		{
			mcc: '5310',
			edited_description: 'Discount Stores',
			combined_description: 'Discount Stores',
			usda_description: 'Discount Stores',
			irs_description: 'Discount Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 756
		},
		{
			mcc: '5311',
			edited_description: 'Department Stores',
			combined_description: 'Department Stores',
			usda_description: 'Department Stores',
			irs_description: 'Department Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 757
		},
		{
			mcc: '5331',
			edited_description: 'Variety Stores',
			combined_description: 'Variety Stores',
			usda_description: 'Variety Stores',
			irs_description: 'Variety Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 758
		},
		{
			mcc: '5399',
			edited_description: 'Misc. General Merchandise',
			combined_description: 'Misc. General Merchandise',
			usda_description: 'Misc. General Merchandise',
			irs_description: 'Miscellaneous General Merchandise',
			irs_reportable: 'No1.6041-3(c)',
			id: 759
		},
		{
			mcc: '5411',
			edited_description: 'Grocery Stores, Supermarkets',
			combined_description: 'Grocery Stores, Supermarkets',
			usda_description: 'Grocery Stores',
			irs_description: 'Grocery Stores, Supermarkets',
			irs_reportable: 'No1.6041-3(c)',
			id: 760
		},
		{
			mcc: '5422',
			edited_description: "Meat Provisioners ' Freezer and Locker",
			combined_description: "Meat Provisioners ' Freezer and Locker",
			usda_description: "Meat Provisioners ' Freezer and Locker",
			irs_description: 'Freezer and Locker Meat Provisioners',
			irs_reportable: 'No1.6041-3(c)',
			id: 761
		},
		{
			mcc: '5441',
			edited_description: 'Candy, Nut, and Confectionery Stores',
			combined_description: 'Candy, Nut, and Confectionery Stores',
			usda_description: 'Candy Stores',
			irs_description: 'Candy, Nut, and Confectionery Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 762
		},
		{
			mcc: '5451',
			edited_description: 'Dairy Products Stores',
			combined_description: 'Dairy Products Stores',
			usda_description: 'Dairy Products Stores',
			irs_description: 'Dairy Products Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 763
		},
		{
			mcc: '5462',
			edited_description: 'Bakeries',
			combined_description: 'Bakeries',
			usda_description: 'Bakeries',
			irs_description: 'Bakeries',
			irs_reportable: 'No1.6041-3(c)',
			id: 764
		},
		{
			mcc: '5499',
			edited_description: "Misc. Food Stores ' Convenience Stores and Specialty Markets",
			combined_description: "Misc. Food Stores ' Convenience Stores and Specialty Markets",
			usda_description: "Misc. Food Stores ' Convenience Stores and Specialty Markets",
			irs_description: 'Miscellaneous Food Stores - Convenience Stores and Specialty Markets',
			irs_reportable: 'No1.6041-3(c)',
			id: 765
		},
		{
			mcc: '5511',
			edited_description: 'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
			combined_description: 'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
			usda_description: 'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
			irs_description: 'Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing ',
			irs_reportable: 'No1.6041-3(c)',
			id: 766
		},
		{
			mcc: '5521',
			edited_description: 'Automobile and Truck Dealers (Used Only)',
			combined_description: 'Automobile and Truck Dealers (Used Only)',
			usda_description: 'Automobile and Truck Dealers (Used Only)',
			irs_description: 'Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing ',
			irs_reportable: 'No1.6041-3(c)',
			id: 767
		},
		{
			mcc: '5531',
			edited_description: 'Automobile Supply Stores',
			combined_description: 'Automobile Supply Stores',
			usda_description: 'Automobile Supply Stores',
			irs_description: 'Auto and Home Supply Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 768
		},
		{
			mcc: '5532',
			edited_description: 'Automotive Tire Stores',
			combined_description: 'Automotive Tire Stores',
			usda_description: 'Automotive Tire Stores',
			irs_description: 'Automotive Tire Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 769
		},
		{
			mcc: '5533',
			edited_description: 'Automotive Parts, Accessories Stores',
			combined_description: 'Automotive Parts, Accessories Stores',
			usda_description: 'Automotive Parts, Accessories Stores',
			irs_description: 'Automotive Parts and Accessories Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 770
		},
		{
			mcc: '5541',
			edited_description: 'Service Stations ( with or without ancillary services)',
			combined_description: 'Service Stations ( with or without ancillary services)',
			usda_description: 'Service Stations ( with or without ancillary services)',
			irs_description: 'Service Stations ',
			irs_reportable: 'No1.6041-3(c)',
			id: 771
		},
		{
			mcc: '5542',
			edited_description: 'Automated Fuel Dispensers',
			combined_description: 'Automated Fuel Dispensers',
			usda_description: 'Automated Fuel Dispensers',
			irs_description: 'Automated Fuel Dispensers',
			irs_reportable: 'No1.6041-3(c)',
			id: 772
		},
		{
			mcc: '5551',
			edited_description: 'Boat Dealers',
			combined_description: 'Boat Dealers',
			usda_description: 'Boat Dealers',
			irs_description: 'Boat Dealers',
			irs_reportable: 'No1.6041-3(c)',
			id: 773
		},
		{
			mcc: '5561',
			edited_description: 'Recreational and Utility Trailers, Camp Dealers',
			combined_description: 'Recreational and Utility Trailers, Camp Dealers',
			usda_description: 'Recreational and Utility Trailers, Camp Dealers',
			irs_description: 'Motorcycle Shops, Dealers',
			irs_reportable: 'No1.6041-3(c)',
			id: 774
		},
		{
			mcc: '5571',
			edited_description: 'Motorcycle Dealers',
			combined_description: 'Motorcycle Dealers',
			usda_description: 'Motorcycle Dealers',
			irs_description: 'Motorcycle Shops and Dealers',
			irs_reportable: 'No1.6041-3(c)',
			id: 775
		},
		{
			mcc: '5592',
			edited_description: 'Motor Home Dealers',
			combined_description: 'Motor Home Dealers',
			usda_description: 'Motor Home Dealers',
			irs_description: 'Motor Homes Dealers',
			irs_reportable: 'No1.6041-3(c)',
			id: 776
		},
		{
			mcc: '5598',
			edited_description: 'Snowmobile Dealers',
			combined_description: 'Snowmobile Dealers',
			usda_description: 'Snowmobile Dealers',
			irs_description: 'Snowmobile Dealers',
			irs_reportable: 'No1.6041-3(c)',
			id: 777
		},
		{
			mcc: '5599',
			edited_description: 'Miscellaneous Auto Dealers ',
			combined_description: 'Miscellaneous Auto Dealers ',
			usda_description: 'Miscellaneous Auto Dealers',
			irs_description: 'Miscellaneous Auto Dealers ',
			irs_reportable: 'No1.6041-3(c)',
			id: 778
		},
		{
			mcc: '5611',
			edited_description: "Men's and Boy's Clothing and Accessories Stores",
			combined_description: "Men's and Boy's Clothing and Accessories Stores",
			usda_description: "Men's and Boy's Clothing and Accessories Stores",
			irs_description: "Men's and Boy's Clothing and Accessories Stores",
			irs_reportable: 'No1.6041-3(c)',
			id: 779
		},
		{
			mcc: '5621',
			edited_description: "Women's Ready-to-Wear Stores",
			combined_description: "Women's Ready-to-Wear Stores",
			usda_description: "Women's Ready-to-Wear Stores",
			irs_description: "Women's Ready-To-Wear Stores",
			irs_reportable: 'No1.6041-3(c)',
			id: 780
		},
		{
			mcc: '5631',
			edited_description: "Women's Accessory and Specialty Shops",
			combined_description: "Women's Accessory and Specialty Shops",
			usda_description: "Women's Accessory and Specialty Shops",
			irs_description: "Women's Accessory and Specialty Shops",
			irs_reportable: 'No1.6041-3(c)',
			id: 781
		},
		{
			mcc: '5641',
			edited_description: "Children's and Infant's Wear Stores",
			combined_description: "Children's and Infant's Wear Stores",
			usda_description: "Children's and Infant's Wear Stores",
			irs_description: "Children's and Infant's Wear Stores",
			irs_reportable: 'No1.6041-3(c)',
			id: 782
		},
		{
			mcc: '5651',
			edited_description: 'Family Clothing Stores',
			combined_description: 'Family Clothing Stores',
			usda_description: 'Family Clothing Stores',
			irs_description: 'Family Clothing Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 783
		},
		{
			mcc: '5655',
			edited_description: 'Sports Apparel, Riding Apparel Stores',
			combined_description: 'Sports Apparel, Riding Apparel Stores',
			usda_description: 'Sports Apparel, Riding Apparel Stores',
			irs_description: 'Sports and Riding Apparel Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 784
		},
		{
			mcc: '5661',
			edited_description: 'Shoe Stores',
			combined_description: 'Shoe Stores',
			usda_description: 'Shoe Stores',
			irs_description: 'Shoe Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 785
		},
		{
			mcc: '5681',
			edited_description: 'Furriers and Fur Shops',
			combined_description: 'Furriers and Fur Shops',
			usda_description: 'Furriers and Fur Shops',
			irs_description: 'Furriers and Fur Shops',
			irs_reportable: 'No1.6041-3(c)',
			id: 786
		},
		{
			mcc: '5691',
			edited_description: "Men's and Women's Clothing Stores",
			combined_description: "Men's and Women's Clothing Stores",
			usda_description: "Men's and Women's Clothing Stores",
			irs_description: "Men's, Women's Clothing Stores",
			irs_reportable: 'No1.6041-3(c)',
			id: 787
		},
		{
			mcc: '5697',
			edited_description: 'Tailors, Seamstress, Mending, and Alterations',
			combined_description: 'Tailors, Seamstress, Mending, and Alterations',
			usda_description: 'Tailors, Seamstress, Mending, and Alterations',
			irs_description: 'Tailors, Alterations',
			irs_reportable: 'Yes',
			id: 788
		},
		{
			mcc: '5698',
			edited_description: 'Wig and Toupee Stores',
			combined_description: 'Wig and Toupee Stores',
			usda_description: 'Wig and Toupee Stores',
			irs_description: 'Wig and Toupee Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 789
		},
		{
			mcc: '5699',
			edited_description: 'Miscellaneous Apparel and Accessory Shops',
			combined_description: 'Miscellaneous Apparel and Accessory Shops',
			usda_description: 'Miscellaneous Apparel and Accessory Shops',
			irs_description: 'Miscellaneous Apparel and Accessory Shops',
			irs_reportable: 'No1.6041-3(c)',
			id: 790
		},
		{
			mcc: '5712',
			edited_description: 'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
			combined_description: 'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
			usda_description: 'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
			irs_description: 'Furniture, Home Furnishings, and Equipment Stores, Except Appliances',
			irs_reportable: 'No1.6041-3(c)',
			id: 791
		},
		{
			mcc: '5713',
			edited_description: 'Floor Covering Stores',
			combined_description: 'Floor Covering Stores',
			usda_description: 'Floor Covering Stores',
			irs_description: 'Floor Covering Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 792
		},
		{
			mcc: '5714',
			edited_description: 'Drapery, Window Covering and Upholstery Stores',
			combined_description: 'Drapery, Window Covering and Upholstery Stores',
			usda_description: 'Drapery, Window Covering and Upholstery Stores',
			irs_description: 'Drapery, Window Covering, and Upholstery Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 793
		},
		{
			mcc: '5718',
			edited_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
			combined_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
			usda_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
			irs_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 794
		},
		{
			mcc: '5719',
			edited_description: 'Miscellaneous Home Furnishing Specialty Stores',
			combined_description: 'Miscellaneous Home Furnishing Specialty Stores',
			usda_description: 'Miscellaneous Home Furnishing Specialty Stores',
			irs_description: 'Miscellaneous Home Furnishing Specialty Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 795
		},
		{
			mcc: '5722',
			edited_description: 'Household Appliance Stores',
			combined_description: 'Household Appliance Stores',
			usda_description: 'Household Appliance Stores',
			irs_description: 'Household Appliance Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 796
		},
		{
			mcc: '5732',
			edited_description: 'Electronic Sales',
			combined_description: 'Electronic Sales',
			usda_description: 'Electronic Sales',
			irs_description: 'Electronics Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 797
		},
		{
			mcc: '5733',
			edited_description: 'Music Stores, Musical Instruments, Piano Sheet Music',
			combined_description: 'Music Stores, Musical Instruments, Piano Sheet Music',
			usda_description: 'Music Stores, Musical Instruments, Piano Sheet Music',
			irs_description: 'Music Stores-Musical Instruments, Pianos, and Sheet Music',
			irs_reportable: 'No1.6041-3(c)',
			id: 798
		},
		{
			mcc: '5734',
			edited_description: 'Computer Software Stores',
			combined_description: 'Computer Software Stores',
			usda_description: 'Computer Software Stores',
			irs_description: 'Computer Software Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 799
		},
		{
			mcc: '5735',
			edited_description: 'Record Shops',
			combined_description: 'Record Shops',
			usda_description: 'Record Shops',
			irs_description: 'Record Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 800
		},
		{
			mcc: '5811',
			edited_description: 'Caterers',
			combined_description: 'Caterers',
			usda_description: 'Caterers',
			irs_description: 'Caterers',
			irs_reportable: 'Yes',
			id: 801
		},
		{
			mcc: '5812',
			edited_description: 'Eating places and Restaurants',
			combined_description: 'Eating places and Restaurants',
			usda_description: 'Eating places and Restaurants',
			irs_description: 'Eating Places, Restaurants',
			irs_reportable: 'No1.6041-3(c)',
			id: 802
		},
		{
			mcc: '5813',
			edited_description: 'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
			combined_description: 'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
			usda_description: 'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
			irs_description: 'Drinking Places',
			irs_reportable: 'No1.6041-3(c)',
			id: 803
		},
		{
			mcc: '5814',
			edited_description: 'Fast Food Restaurants',
			combined_description: 'Fast Food Restaurants',
			usda_description: 'Fast Food Restaurants',
			irs_description: 'Fast Food Restaurants',
			irs_reportable: 'No1.6041-3(c)',
			id: 804
		},
		{
			mcc: '5815',
			edited_description: 'Digital Goods: Media, Books, Movies, Music',
			combined_description: 'Digital Goods: Media, Books, Movies, Music',
			usda_description: 'Digital Goods: Media, Books, Movies, Music',
			irs_description: '',
			irs_reportable: '',
			id: 805
		},
		{
			mcc: '5816',
			edited_description: 'Digital Goods: Games',
			combined_description: 'Digital Goods: Games',
			usda_description: 'Digital Goods: Games',
			irs_description: '',
			irs_reportable: '',
			id: 806
		},
		{
			mcc: '5817',
			edited_description: 'Digital Goods: Applications (Excludes Games)',
			combined_description: 'Digital Goods: Applications (Excludes Games)',
			usda_description: 'Digital Goods: Applications (Excludes Games)',
			irs_description: '',
			irs_reportable: '',
			id: 807
		},
		{
			mcc: '5818',
			edited_description: 'Digital Goods: Large Digital Goods Merchant',
			combined_description: 'Digital Goods: Large Digital Goods Merchant',
			usda_description: 'Digital Goods: Large Digital Goods Merchant',
			irs_description: '',
			irs_reportable: '',
			id: 808
		},
		{
			mcc: '5832',
			edited_description: "Antique Shops ' Sales, Repairs, and Restoration Services",
			combined_description: "Antique Shops ' Sales, Repairs, and Restoration Services",
			usda_description: "Antique Shops ' Sales, Repairs, and Restoration Services",
			irs_description: '',
			irs_reportable: '',
			id: 809
		},
		{
			mcc: '5912',
			edited_description: 'Drug Stores and Pharmacies',
			combined_description: 'Drug Stores and Pharmacies',
			usda_description: 'Drug Stores and Pharmacies',
			irs_description: 'Drug Stores and Pharmacies',
			irs_reportable: 'No1.6041-3(c)',
			id: 810
		},
		{
			mcc: '5921',
			edited_description: "Package Stores ' Beer, Wine, and Liquor",
			combined_description: "Package Stores ' Beer, Wine, and Liquor",
			usda_description: "Package Stores ' Beer, Wine, and Liquor",
			irs_description: 'Package Stores-Beer, Wine, and Liquor',
			irs_reportable: 'No1.6041-3(c)',
			id: 811
		},
		{
			mcc: '5931',
			edited_description: 'Used Merchandise and Secondhand Stores',
			combined_description: 'Used Merchandise and Secondhand Stores',
			usda_description: 'Used Merchandise and Secondhand Stores',
			irs_description: 'Used Merchandise and Secondhand Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 812
		},
		{
			mcc: '5932',
			edited_description: 'Antique Shops',
			combined_description: 'Antique Shops',
			usda_description: 'Antique Shops',
			irs_description: 'Antique Shops',
			irs_reportable: 'No1.6041-3(c)',
			id: 813
		},
		{
			mcc: '5933',
			edited_description: 'Pawn Shops and Salvage Yards',
			combined_description: 'Pawn Shops and Salvage Yards',
			usda_description: 'Pawn Shops and Salvage Yards',
			irs_description: 'Pawn Shops',
			irs_reportable: 'No1.6041-3(c)',
			id: 814
		},
		{
			mcc: '5935',
			edited_description: 'Wrecking and Salvage Yards',
			combined_description: 'Wrecking and Salvage Yards',
			usda_description: 'Wrecking and Salvage Yards',
			irs_description: 'Wrecking and Salvage Yards',
			irs_reportable: 'Yes',
			id: 815
		},
		{
			mcc: '5937',
			edited_description: 'Antique Reproductions',
			combined_description: 'Antique Reproductions',
			usda_description: 'Antique Reproductions',
			irs_description: 'Antique Reproductions',
			irs_reportable: 'No1.6041-3(c)',
			id: 816
		},
		{
			mcc: '5940',
			edited_description: "Bicycle Shops ' Sales and Service",
			combined_description: "Bicycle Shops ' Sales and Service",
			usda_description: "Bicycle Shops ' Sales and Service",
			irs_description: 'Bicycle Shops',
			irs_reportable: 'No1.6041-3(c)',
			id: 817
		},
		{
			mcc: '5941',
			edited_description: 'Sporting Goods Stores',
			combined_description: 'Sporting Goods Stores',
			usda_description: 'Sporting Goods Stores',
			irs_description: 'Sporting Goods Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 818
		},
		{
			mcc: '5942',
			edited_description: 'Book Stores',
			combined_description: 'Book Stores',
			usda_description: 'Book Stores',
			irs_description: 'Book Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 819
		},
		{
			mcc: '5943',
			edited_description: 'Stationery Stores, Office and School Supply Stores',
			combined_description: 'Stationery Stores, Office and School Supply Stores',
			usda_description: 'Stationery Stores, Office and School Supply Stores',
			irs_description: 'Stationery Stores, Office, and School Supply Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 820
		},
		{
			mcc: '5944',
			edited_description: 'Watch, Clock, Jewelry, and Silverware Stores',
			combined_description: 'Watch, Clock, Jewelry, and Silverware Stores',
			usda_description: 'Watch, Clock, Jewelry, and Silverware Stores',
			irs_description: 'Jewelry Stores, Watches, Clocks, and Silverware Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 821
		},
		{
			mcc: '5945',
			edited_description: 'Hobby, Toy, and Game Shops',
			combined_description: 'Hobby, Toy, and Game Shops',
			usda_description: 'Hobby, Toy, and Game Shops',
			irs_description: 'Hobby, Toy, and Game Shops',
			irs_reportable: 'No1.6041-3(c)',
			id: 822
		},
		{
			mcc: '5946',
			edited_description: 'Camera and Photographic Supply Stores',
			combined_description: 'Camera and Photographic Supply Stores',
			usda_description: 'Camera and Photographic Supply Stores',
			irs_description: 'Camera and Photographic Supply Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 823
		},
		{
			mcc: '5947',
			edited_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
			combined_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
			usda_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
			irs_description: 'Gift, Card, Novelty, and Souvenir Shops',
			irs_reportable: 'No1.6041-3(c)',
			id: 824
		},
		{
			mcc: '5948',
			edited_description: 'Leather Goods Stores',
			combined_description: 'Leather Goods Stores',
			usda_description: 'Leather Goods Stores',
			irs_description: 'Luggage and Leather Goods Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 825
		},
		{
			mcc: '5949',
			edited_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
			combined_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
			usda_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
			irs_description: 'Sewing, Needlework, Fabric, and Piece Goods Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 826
		},
		{
			mcc: '5950',
			edited_description: 'Glassware/Crystal Stores',
			combined_description: 'Glassware/Crystal Stores',
			usda_description: 'Glassware/Crystal Stores',
			irs_description: 'Glassware, Crystal Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 827
		},
		{
			mcc: '5960',
			edited_description: 'Direct Marketing- Insurance Service',
			combined_description: 'Direct Marketing- Insurance Service',
			usda_description: 'Direct Marketing- Insurance Service',
			irs_description: 'Direct Marketing - Insurance Services',
			irs_reportable: 'Yes',
			id: 828
		},
		{
			mcc: '5962',
			edited_description: "Direct Marketing ' Travel Related Arrangements Services",
			combined_description: "Direct Marketing ' Travel Related Arrangements Services",
			usda_description: "Direct Marketing ' Travel Related Arrangements Services",
			irs_description: 'Direct Marketing - Travel',
			irs_reportable: 'Yes',
			id: 830
		},
		{
			mcc: '5963',
			edited_description: 'Door-to-Door Sales',
			combined_description: 'Door-to-Door Sales',
			usda_description: 'Door-to-Door Sales',
			irs_description: 'Door-To-Door Sales',
			irs_reportable: 'No1.6041-3(c)',
			id: 831
		},
		{
			mcc: '5964',
			edited_description: "Direct Marketing ' Catalog Merchant",
			combined_description: "Direct Marketing ' Catalog Merchant",
			usda_description: "Direct Marketing ' Catalog Merchant",
			irs_description: 'Direct Marketing - Catalog Merchant',
			irs_reportable: 'No1.6041-3(c)',
			id: 832
		},
		{
			mcc: '5965',
			edited_description: "Direct Marketing ' Catalog and Catalog and Retail Merchant",
			combined_description: "Direct Marketing ' Catalog and Catalog and Retail Merchant",
			usda_description: "Direct Marketing ' Catalog and Catalog and Retail Merchant",
			irs_description: 'Direct Marketing - Combination Catalog and Retail Merchant',
			irs_reportable: 'No1.6041-3(c)',
			id: 833
		},
		{
			mcc: '5966',
			edited_description: 'Direct Marketing- Outbound Telemarketing Merchant',
			combined_description: 'Direct Marketing- Outbound Telemarketing Merchant',
			usda_description: 'Direct Marketing- Outbound Telemarketing Merchant',
			irs_description: 'Direct Marketing - Outbound Tele',
			irs_reportable: 'No1.6041-3(c)',
			id: 834
		},
		{
			mcc: '5967',
			edited_description: "Direct Marketing ' Inbound Teleservices Merchant",
			combined_description: "Direct Marketing ' Inbound Teleservices Merchant",
			usda_description: "Direct Marketing ' Inbound Teleservices Merchant",
			irs_description: 'Direct Marketing - Inbound Tele',
			irs_reportable: 'No1.6041-3(c)',
			id: 835
		},
		{
			mcc: '5968',
			edited_description: "Direct Marketing ' Continuity/Subscription Merchant",
			combined_description: "Direct Marketing ' Continuity/Subscription Merchant",
			usda_description: "Direct Marketing ' Continuity/Subscription Merchant",
			irs_description: 'Direct Marketing - Subscription',
			irs_reportable: 'No1.6041-3(c)',
			id: 836
		},
		{
			mcc: '5969',
			edited_description: "Direct Marketing ' Not Elsewhere Classified",
			combined_description: "Direct Marketing ' Not Elsewhere Classified",
			usda_description: "Direct Marketing ' Not Elsewhere Classified",
			irs_description: 'Direct Marketing - Other ',
			irs_reportable: 'No1.6041-3(c)',
			id: 837
		},
		{
			mcc: '5970',
			edited_description: "Artist's Supply and Craft Shops",
			combined_description: "Artist's Supply and Craft Shops",
			usda_description: "Artist's Supply and Craft Shops",
			irs_description: "Artist's Supply and Craft Shops",
			irs_reportable: 'No1.6041-3(c)',
			id: 838
		},
		{
			mcc: '5971',
			edited_description: 'Art Dealers and Galleries',
			combined_description: 'Art Dealers and Galleries',
			usda_description: 'Art Dealers and Galleries',
			irs_description: 'Art Dealers and Galleries',
			irs_reportable: 'No1.6041-3(c)',
			id: 839
		},
		{
			mcc: '5972',
			edited_description: "Stamp and Coin Stores ' Philatelic and Numismatic Supplies",
			combined_description: "Stamp and Coin Stores ' Philatelic and Numismatic Supplies",
			usda_description: "Stamp and Coin Stores ' Philatelic and Numismatic Supplies",
			irs_description: 'Stamp and Coin Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 840
		},
		{
			mcc: '5973',
			edited_description: 'Religious Goods Stores',
			combined_description: 'Religious Goods Stores',
			usda_description: 'Religious Goods Stores',
			irs_description: 'Religious Goods Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 841
		},
		{
			mcc: '5975',
			edited_description: "Hearing Aids ' Sales, Service, and Supply Stores",
			combined_description: "Hearing Aids ' Sales, Service, and Supply Stores",
			usda_description: "Hearing Aids ' Sales, Service, and Supply Stores",
			irs_description: 'Hearing Aids Sales and Supplies',
			irs_reportable: 'No1.6041-3(c)',
			id: 842
		},
		{
			mcc: '5976',
			edited_description: 'Orthopedic Goods Prosthetic Devices',
			combined_description: 'Orthopedic Goods Prosthetic Devices',
			usda_description: 'Orthopedic Goods Prosthetic Devices',
			irs_description: 'Orthopedic Goods - Prosthetic Devices',
			irs_reportable: 'No1.6041-3(c)',
			id: 843
		},
		{
			mcc: '5977',
			edited_description: 'Cosmetic Stores',
			combined_description: 'Cosmetic Stores',
			usda_description: 'Cosmetic Stores',
			irs_description: 'Cosmetic Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 844
		},
		{
			mcc: '5978',
			edited_description: "Typewriter Stores ' Sales, Rental, Service",
			combined_description: "Typewriter Stores ' Sales, Rental, Service",
			usda_description: "Typewriter Stores ' Sales, Rental, Service",
			irs_description: 'Typewriter Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 845
		},
		{
			mcc: '5983',
			edited_description: "Fuel ' Fuel Oil, Wood, Coal, Liquefied Petroleum",
			combined_description: "Fuel ' Fuel Oil, Wood, Coal, Liquefied Petroleum",
			usda_description: "Fuel ' Fuel Oil, Wood, Coal, Liquefied Petroleum",
			irs_description: 'Fuel Dealers (Non Automotive)',
			irs_reportable: 'No1.6041-3(c)',
			id: 846
		},
		{
			mcc: '5992',
			edited_description: 'Florists',
			combined_description: 'Florists',
			usda_description: 'Florists',
			irs_description: 'Florists',
			irs_reportable: 'No1.6041-3(c)',
			id: 847
		},
		{
			mcc: '5993',
			edited_description: 'Cigar Stores and Stands',
			combined_description: 'Cigar Stores and Stands',
			usda_description: 'Cigar Stores and Stands',
			irs_description: 'Cigar Stores and Stands',
			irs_reportable: 'No1.6041-3(c)',
			id: 848
		},
		{
			mcc: '5994',
			edited_description: 'News Dealers and Newsstands',
			combined_description: 'News Dealers and Newsstands',
			usda_description: 'News Dealers and Newsstands',
			irs_description: 'News Dealers and Newsstands',
			irs_reportable: 'No1.6041-3(c)',
			id: 849
		},
		{
			mcc: '5995',
			edited_description: 'Pet Shops, Pet Foods, and Supplies Stores',
			combined_description: 'Pet Shops, Pet Foods, and Supplies Stores',
			usda_description: 'Pet Shops, Pet Foods, and Supplies Stores',
			irs_description: 'Pet Shops, Pet Food, and Supplies',
			irs_reportable: 'No1.6041-3(c)',
			id: 850
		},
		{
			mcc: '5996',
			edited_description: "Swimming Pools ' Sales, Service, and Supplies",
			combined_description: "Swimming Pools ' Sales, Service, and Supplies",
			usda_description: "Swimming Pools ' Sales, Service, and Supplies",
			irs_description: 'Swimming Pools Sales',
			irs_reportable: 'No1.6041-3(c)',
			id: 851
		},
		{
			mcc: '5997',
			edited_description: "Electric Razor Stores ' Sales and Service",
			combined_description: "Electric Razor Stores ' Sales and Service",
			usda_description: "Electric Razor Stores ' Sales and Service",
			irs_description: 'Electric Razor Stores',
			irs_reportable: 'No1.6041-3(c)',
			id: 852
		},
		{
			mcc: '5998',
			edited_description: 'Tent and Awning Shops',
			combined_description: 'Tent and Awning Shops',
			usda_description: 'Tent and Awning Shops',
			irs_description: 'Tent and Awning Shops',
			irs_reportable: 'No1.6041-3(c)',
			id: 853
		},
		{
			mcc: '5999',
			edited_description: 'Miscellaneous and Specialty Retail Stores',
			combined_description: 'Miscellaneous and Specialty Retail Stores',
			usda_description: 'Miscellaneous and Specialty Retail Stores',
			irs_description: 'Miscellaneous Specialty Retail',
			irs_reportable: 'No1.6041-3(c)',
			id: 854
		},
		{
			mcc: '6010',
			edited_description: "Financial Institutions ' Manual Cash Disbursements",
			combined_description: "Financial Institutions ' Manual Cash Disbursements",
			usda_description: "Financial Institutions ' Manual Cash Disbursements",
			irs_description: 'Manual Cash Disburse',
			irs_reportable: 'No1.6041-3(c)',
			id: 855
		},
		{
			mcc: '6011',
			edited_description: "Financial Institutions ' Manual Cash Disbursements",
			combined_description: "Financial Institutions ' Manual Cash Disbursements",
			usda_description: "Financial Institutions ' Manual Cash Disbursements",
			irs_description: 'Automated Cash Disburse',
			irs_reportable: 'No1.6041-3(c)',
			id: 856
		},
		{
			mcc: '6012',
			edited_description: "Financial Institutions ' Merchandise and Services",
			combined_description: "Financial Institutions ' Merchandise and Services",
			usda_description: "Financial Institutions ' Merchandise and Services",
			irs_description: 'Financial Institutions',
			irs_reportable: 'Yes',
			id: 857
		},
		{
			mcc: '6051',
			edited_description: "Non-Financial Institutions ' Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques",
			combined_description: "Non-Financial Institutions ' Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques",
			usda_description: "Non-Financial Institutions ' Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques",
			irs_description: 'Non-FI, Money Orders',
			irs_reportable: 'No1.6041-3(c)',
			id: 858
		},
		{
			mcc: '6211',
			edited_description: 'Security Brokers/Dealers',
			combined_description: 'Security Brokers/Dealers',
			usda_description: 'Security Brokers/Dealers',
			irs_description: 'Security Brokers/Dealers',
			irs_reportable: 'Yes',
			id: 859
		},
		{
			mcc: '6300',
			edited_description: 'Insurance Sales, Underwriting, and Premiums',
			combined_description: 'Insurance Sales, Underwriting, and Premiums',
			usda_description: 'Insurance Sales, Underwriting, and Premiums',
			irs_description: 'Insurance Underwriting, Premiums',
			irs_reportable: 'No1.6041-3(c)',
			id: 860
		},
		{
			mcc: '6399',
			edited_description: 'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
			combined_description: 'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
			usda_description: 'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
			irs_description: 'Insurance - Default',
			irs_reportable: 'No1.6041-3(c)',
			id: 862
		},
		{
			mcc: '6513',
			edited_description: 'Real Estate Agents and Managers - Rentals',
			combined_description: 'Real Estate Agents and Managers - Rentals',
			usda_description: 'Real Estate Agents and Managers - Rentals',
			irs_description: 'Real Estate Agents and Managers - Rentals',
			irs_reportable: 'Yes',
			id: 863
		},
		{
			mcc: '7011',
			edited_description: "Lodging ' Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)",
			combined_description: "Lodging ' Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)",
			usda_description: "Lodging ' Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)",
			irs_description: 'Hotels, Motels, and Resorts',
			irs_reportable: 'Yes',
			id: 864
		},
		{
			mcc: '7012',
			edited_description: 'Timeshares',
			combined_description: 'Timeshares',
			usda_description: 'Timeshares',
			irs_description: 'Timeshares',
			irs_reportable: 'Yes',
			id: 865
		},
		{
			mcc: '7032',
			edited_description: 'Sporting and Recreational Camps',
			combined_description: 'Sporting and Recreational Camps',
			usda_description: 'Sporting and Recreational Camps',
			irs_description: 'Sporting/Recreation Camps',
			irs_reportable: 'Yes',
			id: 866
		},
		{
			mcc: '7033',
			edited_description: 'Trailer Parks and Camp Grounds',
			combined_description: 'Trailer Parks and Camp Grounds',
			usda_description: 'Trailer Parks and Camp Grounds',
			irs_description: 'Trailer Parks, Campgrounds',
			irs_reportable: 'Yes',
			id: 867
		},
		{
			mcc: '7210',
			edited_description: 'Laundry, Cleaning, and Garment Services',
			combined_description: 'Laundry, Cleaning, and Garment Services',
			usda_description: 'Laundry, Cleaning, and Garment Services',
			irs_description: 'Laundry, Cleaning Services',
			irs_reportable: 'Yes',
			id: 868
		},
		{
			mcc: '7211',
			edited_description: "Laundry ' Family and Commercial",
			combined_description: "Laundry ' Family and Commercial",
			usda_description: "Laundry ' Family and Commercial",
			irs_description: 'Laundries ',
			irs_reportable: 'Yes',
			id: 869
		},
		{
			mcc: '7216',
			edited_description: 'Dry Cleaners',
			combined_description: 'Dry Cleaners',
			usda_description: 'Dry Cleaners',
			irs_description: 'Dry Cleaners',
			irs_reportable: 'Yes',
			id: 870
		},
		{
			mcc: '7217',
			edited_description: 'Carpet and Upholstery Cleaning',
			combined_description: 'Carpet and Upholstery Cleaning',
			usda_description: 'Carpet and Upholstery Cleaning',
			irs_description: 'Carpet/Upholstery Cleaning',
			irs_reportable: 'Yes',
			id: 871
		},
		{
			mcc: '7221',
			edited_description: 'Photographic Studios',
			combined_description: 'Photographic Studios',
			usda_description: 'Photographic Studios',
			irs_description: 'Photographic Studios',
			irs_reportable: 'Yes',
			id: 872
		},
		{
			mcc: '7230',
			edited_description: 'Barber and Beauty Shops',
			combined_description: 'Barber and Beauty Shops',
			usda_description: 'Barber and Beauty Shops',
			irs_description: 'Barber and Beauty Shops',
			irs_reportable: 'Yes',
			id: 873
		},
		{
			mcc: '7251',
			edited_description: 'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
			combined_description: 'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
			usda_description: 'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
			irs_description: 'Shoe Repair/Hat Cleaning',
			irs_reportable: 'Yes',
			id: 874
		},
		{
			mcc: '7261',
			edited_description: 'Funeral Service and Crematories',
			combined_description: 'Funeral Service and Crematories',
			usda_description: 'Funeral Service and Crematories',
			irs_description: 'Funeral Services, Crematories',
			irs_reportable: 'Yes',
			id: 875
		},
		{
			mcc: '7273',
			edited_description: 'Dating and Escort Services',
			combined_description: 'Dating and Escort Services',
			usda_description: 'Dating and Escort Services',
			irs_description: 'Dating/Escort Services',
			irs_reportable: 'Yes',
			id: 876
		},
		{
			mcc: '7276',
			edited_description: 'Tax Preparation Service',
			combined_description: 'Tax Preparation Service',
			usda_description: 'Tax Preparation Service',
			irs_description: 'Tax Preparation Services',
			irs_reportable: 'Yes',
			id: 877
		},
		{
			mcc: '7277',
			edited_description: "Counseling Service ' Debt, Marriage, Personal",
			combined_description: "Counseling Service ' Debt, Marriage, Personal",
			usda_description: "Counseling Service ' Debt, Marriage, Personal",
			irs_description: 'Counseling Services',
			irs_reportable: 'Yes',
			id: 878
		},
		{
			mcc: '7278',
			edited_description: 'Buying/Shopping Services, Clubs',
			combined_description: 'Buying/Shopping Services, Clubs',
			usda_description: 'Buying/Shopping Services, Clubs',
			irs_description: 'Buying/Shopping Services',
			irs_reportable: 'Yes',
			id: 879
		},
		{
			mcc: '7296',
			edited_description: "Clothing Rental ' Costumes, Formal Wear, Uniforms",
			combined_description: "Clothing Rental ' Costumes, Formal Wear, Uniforms",
			usda_description: "Clothing Rental ' Costumes, Formal Wear, Uniforms",
			irs_description: 'Clothing Rental ',
			irs_reportable: 'Yes',
			id: 880
		},
		{
			mcc: '7297',
			edited_description: 'Massage Parlors',
			combined_description: 'Massage Parlors',
			usda_description: 'Massage Parlors',
			irs_description: 'Massage Parlors',
			irs_reportable: 'Yes',
			id: 881
		},
		{
			mcc: '7298',
			edited_description: 'Health and Beauty Shops',
			combined_description: 'Health and Beauty Shops',
			usda_description: 'Health and Beauty Shops',
			irs_description: 'Health and Beauty Spas',
			irs_reportable: 'Yes',
			id: 882
		},
		{
			mcc: '7299',
			edited_description: 'Miscellaneous Personal Services ( not elsewhere classifies)',
			combined_description: 'Miscellaneous Personal Services ( not elsewhere classifies)',
			usda_description: 'Miscellaneous Personal Services ( not elsewhere classifies)',
			irs_description: 'Miscellaneous General Services',
			irs_reportable: 'Yes',
			id: 883
		},
		{
			mcc: '7311',
			edited_description: 'Advertising Services',
			combined_description: 'Advertising Services',
			usda_description: 'Advertising Services',
			irs_description: 'Advertising Services',
			irs_reportable: 'Yes',
			id: 884
		},
		{
			mcc: '7321',
			edited_description: 'Consumer Credit Reporting Agencies',
			combined_description: 'Consumer Credit Reporting Agencies',
			usda_description: 'Consumer Credit Reporting Agencies',
			irs_description: 'Credit Reporting Agencies',
			irs_reportable: 'Yes',
			id: 885
		},
		{
			mcc: '7332',
			edited_description: 'Blueprinting and Photocopying Services',
			combined_description: 'Blueprinting and Photocopying Services',
			usda_description: 'Blueprinting and Photocopying Services',
			irs_description: '',
			irs_reportable: '',
			id: 886
		},
		{
			mcc: '7333',
			edited_description: 'Commercial Photography, Art and Graphics',
			combined_description: 'Commercial Photography, Art and Graphics',
			usda_description: 'Commercial Photography, Art and Graphics',
			irs_description: 'Commercial Photography, Art and Graphics',
			irs_reportable: 'Yes',
			id: 887
		},
		{
			mcc: '7338',
			edited_description: 'Quick Copy, Reproduction and Blueprinting Services',
			combined_description: 'Quick Copy, Reproduction and Blueprinting Services',
			usda_description: 'Quick Copy, Reproduction and Blueprinting Services',
			irs_description: 'Quick Copy, Repro, and Blueprint',
			irs_reportable: 'Yes',
			id: 888
		},
		{
			mcc: '7339',
			edited_description: 'Stenographic and Secretarial Support Services',
			combined_description: 'Stenographic and Secretarial Support Services',
			usda_description: 'Stenographic and Secretarial Support Services',
			irs_description: 'Secretarial Support Services',
			irs_reportable: 'Yes',
			id: 889
		},
		{
			mcc: '7342',
			edited_description: 'Exterminating and Disinfecting Services',
			combined_description: 'Exterminating and Disinfecting Services',
			usda_description: 'Exterminating and Disinfecting Services',
			irs_description: 'Exterminating Services',
			irs_reportable: 'Yes',
			id: 890
		},
		{
			mcc: '7349',
			edited_description: 'Cleaning and Maintenance, Janitorial Services',
			combined_description: 'Cleaning and Maintenance, Janitorial Services',
			usda_description: 'Cleaning and Maintenance, Janitorial Services',
			irs_description: 'Cleaning and Maintenance',
			irs_reportable: 'Yes',
			id: 891
		},
		{
			mcc: '7361',
			edited_description: 'Employment Agencies, Temporary Help Services',
			combined_description: 'Employment Agencies, Temporary Help Services',
			usda_description: 'Employment Agencies, Temporary Help Services',
			irs_description: 'Employment/Temp Agencies',
			irs_reportable: 'Yes',
			id: 892
		},
		{
			mcc: '7372',
			edited_description: 'Computer Programming, Integrated Systems Design and Data Processing Services',
			combined_description: 'Computer Programming, Integrated Systems Design and Data Processing Services',
			usda_description: 'Computer Programming, Integrated Systems Design and Data Processing Services',
			irs_description: 'Computer Programming',
			irs_reportable: 'Yes',
			id: 893
		},
		{
			mcc: '7375',
			edited_description: 'Information Retrieval Services',
			combined_description: 'Information Retrieval Services',
			usda_description: 'Information Retrieval Services',
			irs_description: 'Information Retrieval Services',
			irs_reportable: 'Yes',
			id: 894
		},
		{
			mcc: '7379',
			edited_description: 'Computer Maintenance and Repair Services, Not Elsewhere Classified',
			combined_description: 'Computer Maintenance and Repair Services, Not Elsewhere Classified',
			usda_description: 'Computer Maintenance and Repair Services, Not Elsewhere Classified',
			irs_description: 'Computer Repair',
			irs_reportable: 'Yes',
			id: 895
		},
		{
			mcc: '7392',
			edited_description: 'Management, Consulting, and Public Relations Services',
			combined_description: 'Management, Consulting, and Public Relations Services',
			usda_description: 'Management, Consulting, and Public Relations Services',
			irs_description: 'Consulting, Public Relations',
			irs_reportable: 'Yes',
			id: 896
		},
		{
			mcc: '7393',
			edited_description: "Protective and Security Services ' Including Armored Carsand Guard Dogs",
			combined_description: "Protective and Security Services ' Including Armored Carsand Guard Dogs",
			usda_description: "Protective and Security Services ' Including Armored Carsand Guard Dogs",
			irs_description: 'Detective Agencies',
			irs_reportable: 'Yes',
			id: 897
		},
		{
			mcc: '7394',
			edited_description: 'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
			combined_description: 'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
			usda_description: 'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
			irs_description: 'Equipment Rental ',
			irs_reportable: 'Yes',
			id: 898
		},
		{
			mcc: '7395',
			edited_description: 'Photofinishing Laboratories, Photo Developing',
			combined_description: 'Photofinishing Laboratories, Photo Developing',
			usda_description: 'Photofinishing Laboratories, Photo Developing',
			irs_description: 'Photo Developing',
			irs_reportable: 'Yes',
			id: 899
		},
		{
			mcc: '7399',
			edited_description: 'Business Services, Not Elsewhere Classified',
			combined_description: 'Business Services, Not Elsewhere Classified',
			usda_description: 'Business Services, Not Elsewhere Classified',
			irs_description: 'Miscellaneous Business Services ',
			irs_reportable: 'Yes',
			id: 900
		},
		{
			mcc: '7511',
			edited_description: 'Truck Stop',
			combined_description: 'Truck Stop',
			usda_description: 'Truck Stop',
			irs_description: 'Truck Stop',
			irs_reportable: 'Yes',
			id: 901
		},
		{
			mcc: '7512',
			edited_description: 'Car Rental Companies ( Not Listed Below)',
			combined_description: 'Car Rental Companies ( Not Listed Below)',
			usda_description: 'Car Rental Companies ( Not Listed Below)',
			irs_description: 'Car Rental Agencies',
			irs_reportable: 'Yes',
			id: 902
		},
		{
			mcc: '7513',
			edited_description: 'Truck and Utility Trailer Rentals',
			combined_description: 'Truck and Utility Trailer Rentals',
			usda_description: 'Truck and Utility Trailer Rentals',
			irs_description: 'Truck/Utility Trailer Rentals',
			irs_reportable: 'Yes',
			id: 903
		},
		{
			mcc: '7519',
			edited_description: 'Motor Home and Recreational Vehicle Rentals',
			combined_description: 'Motor Home and Recreational Vehicle Rentals',
			usda_description: 'Motor Home and Recreational Vehicle Rentals',
			irs_description: 'Recreational Vehicle Rentals',
			irs_reportable: 'Yes',
			id: 904
		},
		{
			mcc: '7523',
			edited_description: 'Automobile Parking Lots and Garages',
			combined_description: 'Automobile Parking Lots and Garages',
			usda_description: 'Automobile Parking Lots and Garages',
			irs_description: 'Parking Lots, Garages',
			irs_reportable: 'Yes',
			id: 905
		},
		{
			mcc: '7531',
			edited_description: 'Automotive Body Repair Shops',
			combined_description: 'Automotive Body Repair Shops',
			usda_description: 'Automotive Body Repair Shops',
			irs_description: 'Auto Body Repair Shops',
			irs_reportable: 'Yes',
			id: 906
		},
		{
			mcc: '7534',
			edited_description: 'Tire Re-treading and Repair Shops',
			combined_description: 'Tire Re-treading and Repair Shops',
			usda_description: 'Tire Re-treading and Repair Shops',
			irs_description: 'Tire Retreading and Repair',
			irs_reportable: 'Yes',
			id: 907
		},
		{
			mcc: '7535',
			edited_description: "Paint Shops ' Automotive",
			combined_description: "Paint Shops ' Automotive",
			usda_description: "Paint Shops ' Automotive",
			irs_description: 'Auto Paint Shops',
			irs_reportable: 'Yes',
			id: 908
		},
		{
			mcc: '7538',
			edited_description: 'Automotive Service Shops',
			combined_description: 'Automotive Service Shops',
			usda_description: 'Automotive Service Shops',
			irs_description: 'Auto Service Shops',
			irs_reportable: 'Yes',
			id: 909
		},
		{
			mcc: '7542',
			edited_description: 'Car Washes',
			combined_description: 'Car Washes',
			usda_description: 'Car Washes',
			irs_description: 'Car Washes',
			irs_reportable: 'Yes',
			id: 910
		},
		{
			mcc: '7549',
			edited_description: 'Towing Services',
			combined_description: 'Towing Services',
			usda_description: 'Towing Services',
			irs_description: 'Towing Services',
			irs_reportable: 'Yes',
			id: 911
		},
		{
			mcc: '7622',
			edited_description: 'Radio Repair Shops',
			combined_description: 'Radio Repair Shops',
			usda_description: 'Radio Repair Shops',
			irs_description: 'Electronics Repair Shops',
			irs_reportable: 'Yes',
			id: 912
		},
		{
			mcc: '7623',
			edited_description: 'Air Conditioning and Refrigeration Repair Shops',
			combined_description: 'Air Conditioning and Refrigeration Repair Shops',
			usda_description: 'Air Conditioning and Refrigeration Repair Shops',
			irs_description: 'A/C, Refrigeration Repair',
			irs_reportable: 'Yes',
			id: 913
		},
		{
			mcc: '7629',
			edited_description: 'Electrical And Small Appliance Repair Shops',
			combined_description: 'Electrical And Small Appliance Repair Shops',
			usda_description: 'Electrical And Small Appliance Repair Shops',
			irs_description: 'Small Appliance Repair',
			irs_reportable: 'Yes',
			id: 914
		},
		{
			mcc: '7631',
			edited_description: 'Watch, Clock, and Jewelry Repair',
			combined_description: 'Watch, Clock, and Jewelry Repair',
			usda_description: 'Watch, Clock, and Jewelry Repair',
			irs_description: 'Watch/Jewelry Repair',
			irs_reportable: 'Yes',
			id: 915
		},
		{
			mcc: '7641',
			edited_description: 'Furniture, Furniture Repair, and Furniture Refinishing',
			combined_description: 'Furniture, Furniture Repair, and Furniture Refinishing',
			usda_description: 'Furniture, Furniture Repair, and Furniture Refinishing',
			irs_description: 'Furniture Repair, Refinishing',
			irs_reportable: 'Yes',
			id: 916
		},
		{
			mcc: '7692',
			edited_description: 'Welding Repair',
			combined_description: 'Welding Repair',
			usda_description: 'Welding Repair',
			irs_description: 'Welding Repair',
			irs_reportable: 'Yes',
			id: 917
		},
		{
			mcc: '7699',
			edited_description: "Repair Shops and Related Services 'Miscellaneous",
			combined_description: "Repair Shops and Related Services 'Miscellaneous",
			usda_description: "Repair Shops and Related Services 'Miscellaneous",
			irs_description: 'Miscellaneous Repair Shops',
			irs_reportable: 'Yes',
			id: 918
		},
		{
			mcc: '7829',
			edited_description: 'Motion Pictures and Video Tape Production and Distribution',
			combined_description: 'Motion Pictures and Video Tape Production and Distribution',
			usda_description: 'Motion Pictures and Video Tape Production and Distribution',
			irs_description: 'Picture/Video Production',
			irs_reportable: 'Yes',
			id: 922
		},
		{
			mcc: '7832',
			edited_description: 'Motion Picture Theaters',
			combined_description: 'Motion Picture Theaters',
			usda_description: 'Motion Picture Theaters',
			irs_description: 'Motion Picture Theaters',
			irs_reportable: 'Yes',
			id: 923
		},
		{
			mcc: '7841',
			edited_description: 'Video Tape Rental Stores',
			combined_description: 'Video Tape Rental Stores',
			usda_description: 'Video Tape Rental Stores',
			irs_description: 'Video Tape Rental Stores',
			irs_reportable: 'Yes',
			id: 924
		},
		{
			mcc: '7911',
			edited_description: 'Dance Halls, Studios and Schools',
			combined_description: 'Dance Halls, Studios and Schools',
			usda_description: 'Dance Halls, Studios and Schools',
			irs_description: 'Dance Hall, Studios, Schools',
			irs_reportable: 'Yes',
			id: 925
		},
		{
			mcc: '7922',
			edited_description: 'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
			combined_description: 'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
			usda_description: 'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
			irs_description: 'Theatrical Ticket Agencies',
			irs_reportable: 'Yes',
			id: 926
		},
		{
			mcc: '7929',
			edited_description: 'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
			combined_description: 'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
			usda_description: 'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
			irs_description: 'Bands, Orchestras',
			irs_reportable: 'Yes',
			id: 927
		},
		{
			mcc: '7932',
			edited_description: 'Billiard and Pool Establishments',
			combined_description: 'Billiard and Pool Establishments',
			usda_description: 'Billiard and Pool Establishments',
			irs_description: 'Billiard/Pool Establishments',
			irs_reportable: 'Yes',
			id: 928
		},
		{
			mcc: '7933',
			edited_description: 'Bowling Alleys',
			combined_description: 'Bowling Alleys',
			usda_description: 'Bowling Alleys',
			irs_description: 'Bowling Alleys',
			irs_reportable: 'Yes',
			id: 929
		},
		{
			mcc: '7941',
			edited_description: 'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
			combined_description: 'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
			usda_description: 'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
			irs_description: 'Sports Clubs/Fields',
			irs_reportable: 'Yes',
			id: 930
		},
		{
			mcc: '7991',
			edited_description: 'Tourist Attractions and Exhibits',
			combined_description: 'Tourist Attractions and Exhibits',
			usda_description: 'Tourist Attractions and Exhibits',
			irs_description: 'Tourist Attractions and Exhibits',
			irs_reportable: 'Yes',
			id: 931
		},
		{
			mcc: '7992',
			edited_description: "Golf Courses ' Public",
			combined_description: "Golf Courses ' Public",
			usda_description: "Golf Courses ' Public",
			irs_description: 'Golf Courses - Public',
			irs_reportable: 'Yes',
			id: 932
		},
		{
			mcc: '7993',
			edited_description: 'Video Amusement Game Supplies',
			combined_description: 'Video Amusement Game Supplies',
			usda_description: 'Video Amusement Game Supplies',
			irs_description: 'Video Amusement Game Supplies',
			irs_reportable: 'No1.6041-3(c)',
			id: 933
		},
		{
			mcc: '7994',
			edited_description: 'Video Game Arcades/Establishments',
			combined_description: 'Video Game Arcades/Establishments',
			usda_description: 'Video Game Arcades/Establishments',
			irs_description: 'Video Game Arcades',
			irs_reportable: 'Yes',
			id: 934
		},
		{
			mcc: '7995',
			edited_description: 'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
			combined_description: 'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
			usda_description: 'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
			irs_description: 'Betting/Casino Gambling',
			irs_reportable: 'Yes',
			id: 935
		},
		{
			mcc: '7996',
			edited_description: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
			combined_description: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
			usda_description: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
			irs_description: 'Amusement Parks/Carnivals',
			irs_reportable: 'Yes',
			id: 936
		},
		{
			mcc: '7997',
			edited_description: 'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
			combined_description: 'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
			usda_description: 'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
			irs_description: 'Country Clubs',
			irs_reportable: 'Yes',
			id: 937
		},
		{
			mcc: '7998',
			edited_description: 'Aquariums, Sea-aquariums, Dolphinariums',
			combined_description: 'Aquariums, Sea-aquariums, Dolphinariums',
			usda_description: 'Aquariums, Sea-aquariums, Dolphinariums',
			irs_description: 'Aquariums',
			irs_reportable: 'Yes',
			id: 938
		},
		{
			mcc: '7999',
			edited_description: 'Recreation Services (Not Elsewhere Classified)',
			combined_description: 'Recreation Services (Not Elsewhere Classified)',
			usda_description: 'Recreation Services (Not Elsewhere Classified)',
			irs_description: 'Miscellaneous Recreation Services',
			irs_reportable: 'Yes',
			id: 939
		},
		{
			mcc: '8011',
			edited_description: 'Doctors and Physicians (Not Elsewhere Classified)',
			combined_description: 'Doctors and Physicians (Not Elsewhere Classified)',
			usda_description: 'Doctors and Physicians (Not Elsewhere Classified)',
			irs_description: 'Doctors',
			irs_reportable: 'Yes',
			id: 940
		},
		{
			mcc: '8021',
			edited_description: 'Dentists and Orthodontists',
			combined_description: 'Dentists and Orthodontists',
			usda_description: 'Dentists and Orthodontists',
			irs_description: 'Dentists, Orthodontists',
			irs_reportable: 'Yes',
			id: 941
		},
		{
			mcc: '8031',
			edited_description: 'Osteopaths',
			combined_description: 'Osteopaths',
			usda_description: 'Osteopaths',
			irs_description: 'Osteopaths',
			irs_reportable: 'Yes',
			id: 942
		},
		{
			mcc: '8041',
			edited_description: 'Chiropractors',
			combined_description: 'Chiropractors',
			usda_description: 'Chiropractors',
			irs_description: 'Chiropractors',
			irs_reportable: 'Yes',
			id: 943
		},
		{
			mcc: '8042',
			edited_description: 'Optometrists and Ophthalmologists',
			combined_description: 'Optometrists and Ophthalmologists',
			usda_description: 'Optometrists and Ophthalmologists',
			irs_description: 'Optometrists, Ophthalmologist',
			irs_reportable: 'Yes',
			id: 944
		},
		{
			mcc: '8043',
			edited_description: 'Opticians, Opticians Goods and Eyeglasses',
			combined_description: 'Opticians, Opticians Goods and Eyeglasses',
			usda_description: 'Opticians, Opticians Goods and Eyeglasses',
			irs_description: 'Opticians, Eyeglasses',
			irs_reportable: 'Yes',
			id: 945
		},
		{
			mcc: '8044',
			edited_description: 'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
			combined_description: 'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
			usda_description: 'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
			irs_description: '',
			irs_reportable: '',
			id: 946
		},
		{
			mcc: '8049',
			edited_description: 'Podiatrists and Chiropodists',
			combined_description: 'Podiatrists and Chiropodists',
			usda_description: 'Podiatrists and Chiropodists',
			irs_description: 'Chiropodists, Podiatrists',
			irs_reportable: 'Yes',
			id: 947
		},
		{
			mcc: '8050',
			edited_description: 'Nursing and Personal Care Facilities',
			combined_description: 'Nursing and Personal Care Facilities',
			usda_description: 'Nursing and Personal Care Facilities',
			irs_description: 'Nursing/Personal Care ',
			irs_reportable: 'Yes',
			id: 948
		},
		{
			mcc: '8062',
			edited_description: 'Hospitals',
			combined_description: 'Hospitals',
			usda_description: 'Hospitals',
			irs_description: 'Hospitals',
			irs_reportable: 'Yes',
			id: 949
		},
		{
			mcc: '8071',
			edited_description: 'Medical and Dental Laboratories',
			combined_description: 'Medical and Dental Laboratories',
			usda_description: 'Medical and Dental Laboratories',
			irs_description: 'Medical and Dental Labs',
			irs_reportable: 'Yes',
			id: 950
		},
		{
			mcc: '8099',
			edited_description: 'Medical Services and Health Practitioners (Not Elsewhere Classified)',
			combined_description: 'Medical Services and Health Practitioners (Not Elsewhere Classified)',
			usda_description: 'Medical Services and Health Practitioners (Not Elsewhere Classified)',
			irs_description: 'Medical Services ',
			irs_reportable: 'Yes',
			id: 951
		},
		{
			mcc: '8111',
			edited_description: 'Legal Services and Attorneys',
			combined_description: 'Legal Services and Attorneys',
			usda_description: 'Legal Services and Attorneys',
			irs_description: 'Legal Services, Attorneys',
			irs_reportable: 'Yes',
			id: 952
		},
		{
			mcc: '8211',
			edited_description: 'Elementary and Secondary Schools',
			combined_description: 'Elementary and Secondary Schools',
			usda_description: 'Elementary and Secondary Schools',
			irs_description: 'Elementary, Secondary Schools',
			irs_reportable: 'No1.6041-3(p)(2)',
			id: 953
		},
		{
			mcc: '8220',
			edited_description: 'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
			combined_description: 'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
			usda_description: 'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
			irs_description: 'Colleges, Universities',
			irs_reportable: 'No1.6041-3(p)(2)',
			id: 954
		},
		{
			mcc: '8241',
			edited_description: 'Correspondence Schools',
			combined_description: 'Correspondence Schools',
			usda_description: 'Correspondence Schools',
			irs_description: 'Correspondence Schools',
			irs_reportable: 'No1.6041-3(p)(2)',
			id: 955
		},
		{
			mcc: '8244',
			edited_description: 'Business and Secretarial Schools',
			combined_description: 'Business and Secretarial Schools',
			usda_description: 'Business and Secretarial Schools',
			irs_description: 'Business/Secretarial Schools',
			irs_reportable: 'No1.6041-3(p)(2)',
			id: 956
		},
		{
			mcc: '8249',
			edited_description: 'Vocational Schools and Trade Schools',
			combined_description: 'Vocational Schools and Trade Schools',
			usda_description: 'Vocational Schools and Trade Schools',
			irs_description: 'Vocational/Trade Schools',
			irs_reportable: 'No1.6041-3(p)(2)',
			id: 957
		},
		{
			mcc: '8299',
			edited_description: 'Schools and Educational Services ( Not Elsewhere Classified)',
			combined_description: 'Schools and Educational Services ( Not Elsewhere Classified)',
			usda_description: 'Schools and Educational Services ( Not Elsewhere Classified)',
			irs_description: 'Educational Services ',
			irs_reportable: 'Yes',
			id: 958
		},
		{
			mcc: '8351',
			edited_description: 'Child Care Services',
			combined_description: 'Child Care Services',
			usda_description: 'Child Care Services',
			irs_description: 'Child Care Services',
			irs_reportable: 'Yes',
			id: 959
		},
		{
			mcc: '8398',
			edited_description: 'Charitable and Social Service Organizations',
			combined_description: 'Charitable and Social Service Organizations',
			usda_description: 'Charitable and Social Service Organizations',
			irs_description: 'Charitable and Social Service Organizations - Fundraising',
			irs_reportable: 'No1.6041-3(p)(2)',
			id: 960
		},
		{
			mcc: '8641',
			edited_description: 'Civic, Fraternal, and Social Associations',
			combined_description: 'Civic, Fraternal, and Social Associations',
			usda_description: 'Civic, Fraternal, and Social Associations',
			irs_description: 'Civic, Social, Fraternal Associations',
			irs_reportable: 'No1.6041-3(p)(2)',
			id: 961
		},
		{
			mcc: '8651',
			edited_description: 'Political Organizations',
			combined_description: 'Political Organizations',
			usda_description: 'Political Organizations',
			irs_description: 'Political Organizations',
			irs_reportable: 'Yes',
			id: 962
		},
		{
			mcc: '8661',
			edited_description: 'Religious Organizations',
			combined_description: 'Religious Organizations',
			usda_description: 'Religious Organizations',
			irs_description: 'Religious Organizations',
			irs_reportable: 'No1.6041-3(p)(2)',
			id: 963
		},
		{
			mcc: '8675',
			edited_description: 'Automobile Associations',
			combined_description: 'Automobile Associations',
			usda_description: 'Automobile Associations',
			irs_description: 'Automobile Associations',
			irs_reportable: 'Yes',
			id: 964
		},
		{
			mcc: '8699',
			edited_description: 'Membership Organizations ( Not Elsewhere Classified)',
			combined_description: 'Membership Organizations ( Not Elsewhere Classified)',
			usda_description: 'Membership Organizations ( Not Elsewhere Classified)',
			irs_description: 'Membership Organizations',
			irs_reportable: 'Yes',
			id: 965
		},
		{
			mcc: '8734',
			edited_description: 'Testing Laboratories ( non-medical)',
			combined_description: 'Testing Laboratories ( non-medical)',
			usda_description: 'Testing Laboratories ( non-medical)',
			irs_description: 'Testing Laboratories',
			irs_reportable: 'Yes',
			id: 966
		},
		{
			mcc: '8911',
			edited_description: "Architectural ' Engineering and Surveying Services",
			combined_description: "Architectural ' Engineering and Surveying Services",
			usda_description: "Architectural ' Engineering and Surveying Services",
			irs_description: 'Architectural/Surveying Services',
			irs_reportable: 'Yes',
			id: 967
		},
		{
			mcc: '8931',
			edited_description: 'Accounting, Auditing, and Bookkeeping Services',
			combined_description: 'Accounting, Auditing, and Bookkeeping Services',
			usda_description: 'Accounting, Auditing, and Bookkeeping Services',
			irs_description: 'Accounting/Bookkeeping Services',
			irs_reportable: 'Yes',
			id: 968
		},
		{
			mcc: '8999',
			edited_description: 'Professional Services ( Not Elsewhere Defined)',
			combined_description: 'Professional Services ( Not Elsewhere Defined)',
			usda_description: 'Professional Services ( Not Elsewhere Defined)',
			irs_description: 'Professional Services',
			irs_reportable: 'Yes',
			id: 969
		},
		{
			mcc: '9211',
			edited_description: 'Court Costs, including Alimony and Child Support',
			combined_description: 'Court Costs, including Alimony and Child Support',
			usda_description: 'Court Costs, including Alimony and Child Support',
			irs_description: 'Court Costs, Including Alimony and Child Support - Courts of Law',
			irs_reportable: 'No1.6041-3(p)(4)',
			id: 970
		},
		{
			mcc: '9222',
			edited_description: 'Fines',
			combined_description: 'Fines',
			usda_description: 'Fines',
			irs_description: 'Fines - Government Administrative Entities',
			irs_reportable: 'No1.6041-3(p)(4)',
			id: 971
		},
		{
			mcc: '9223',
			edited_description: 'Bail and Bond Payments',
			combined_description: 'Bail and Bond Payments',
			usda_description: 'Bail and Bond Payments',
			irs_description: 'Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency) ',
			irs_reportable: 'Yes',
			id: 972
		},
		{
			mcc: '9311',
			edited_description: 'Tax Payments',
			combined_description: 'Tax Payments',
			usda_description: 'Tax Payments',
			irs_description: 'Tax Payments - Government Agencies',
			irs_reportable: 'No1.6041-3(p)(4)',
			id: 973
		},
		{
			mcc: '9399',
			edited_description: 'Government Services ( Not Elsewhere Classified)',
			combined_description: 'Government Services ( Not Elsewhere Classified)',
			usda_description: 'Government Services ( Not Elsewhere Classified)',
			irs_description: 'Government Services (Not Elsewhere Classified)',
			irs_reportable: 'No1.6041-3(p)(4)',
			id: 974
		},
		{
			mcc: '9402',
			edited_description: "Postal Services ' Government Only",
			combined_description: "Postal Services ' Government Only",
			usda_description: "Postal Services ' Government Only",
			irs_description: 'Postal Services - Government Only',
			irs_reportable: 'No1.6041-3(p)(3)',
			id: 975
		},
		{
			mcc: '9405',
			edited_description: "Intra ' Government Transactions",
			combined_description: "Intra ' Government Transactions",
			usda_description: "Intra ' Government Transactions",
			irs_description: 'U.S. Federal Government Agencies or Departments',
			irs_reportable: 'No1.6041-3(p)(3)',
			id: 976
		},
		{
			mcc: '9700',
			edited_description: 'Automated Referral Service ( For Visa Only)',
			combined_description: 'Automated Referral Service ( For Visa Only)',
			usda_description: 'Automated Referral Service ( For Visa Only)',
			irs_description: '',
			irs_reportable: '',
			id: 977
		},
		{
			mcc: '9701',
			edited_description: 'Visa Credential Service ( For Visa Only)',
			combined_description: 'Visa Credential Service ( For Visa Only)',
			usda_description: 'Visa Credential Service ( For Visa Only)',
			irs_description: '',
			irs_reportable: '',
			id: 978
		},
		{
			mcc: '9702',
			edited_description: 'GCAS Emergency Services ( For Visa Only)',
			combined_description: 'GCAS Emergency Services ( For Visa Only)',
			usda_description: 'GCAS Emergency Services ( For Visa Only)',
			irs_description: '',
			irs_reportable: '',
			id: 979
		},
		{
			mcc: '9950',
			edited_description: "Intra ' Company Purchases ( For Visa Only)",
			combined_description: "Intra ' Company Purchases ( For Visa Only)",
			usda_description: "Intra ' Company Purchases ( For Visa Only)",
			irs_description: 'Intra-Company Purchases',
			irs_reportable: 'No1.6041-3(c)',
			id: 980
		}
	]

	getOptionsType(stype) {
		if (stype === 'btype') {
			return [
				{ value: '', text: 'Select Business Type' },
				{ value: 'Limited Liability Company', text: 'Limited Liability Company' },
				{ value: 'Non-Profit Org', text: 'Non-Profit Org' },
				{ value: 'Partnership', text: 'Partnership' },
				{ value: 'Private Corp', text: 'Private Corp' },
				{ value: 'Public Corp', text: 'Public Corp' },
				{ value: 'Tax Exempt', text: 'Tax Exempt' },
				{ value: 'Government', text: 'Government' },
				{ value: 'Sole Proprietor', text: 'Sole Proprietor' }
			]
		} else if (stype === 'whencharged') {
			return [
				{ value: '', text: 'Select' },
				{ value: 'When Service Provided', text: 'When Service Provided' },
				{ value: 'In Advance', text: 'In Advance' }
			]
		} else if (stype === 'whenprovided') {
			return [
				{ value: '', text: 'Select' },
				{ value: '30 Days or Less', text: '30 Days or Less' },
				{ value: '31 to 60 Days', text: '31 to 60 Days' },
				{ value: '60+ Days', text: '60+ Days' }
			]
		} else if (stype === 'whendelivered') {
			return [
				{ value: '', text: 'Select' },
				{ value: '0-7 Days', text: '0-7 Days' },
				{ value: '8-14 Days', text: '8-14 Days' },
				{ value: '15-30 Days', text: '15-30 Days' },
				{ value: 'Over 30 Days', text: 'Over 30 Days' }
			]
		} else if (stype === 'whenrefunded') {
			return [
				{ value: '', text: 'Select' },
				{ value: 'Exchange Only', text: 'Exchange Only' },
				{ value: 'No Refund or Exchange', text: 'No Refund or Exchange' },
				{ value: '30 Days or Less', text: '30 Days or Less' },
				{ value: 'More than 30 days', text: 'More than 30 days' }
			]
		} else if (stype === 'card-pricing') {
			return [
				{ value: '', text: 'Select' },
				{ value: '1', text: 'IC Plus' },
				{ value: '2', text: 'Flat Rate' },
				{ value: '3', text: 'Pass-Through' },
				{ value: '4', text: 'Flat Rate + Pass-Through' }
			]
		} else if (stype === 'ach-pricing') {
			return [
				{ value: '', text: 'Select' },
				{ value: '5', text: 'Absorb' },
				{ value: '3', text: 'Pass-Through' },
				{ value: '6', text: 'Both' }
			]
			} else if (stype === 'mgm-pricing') {
			return [
				{ value: '', text: 'Select' },
				{ value: '5', text: 'Absorb' },
				{ value: '3', text: 'Pass-Through' },
				{ value: '6', text: 'Both' }
			]
		} else if (stype === 'bank-type') {
			return [
				{ value: '', text: 'Select' },
				{ value: 'Checking', text: 'Checking' },
				{ value: 'Savings', text: 'Savings' }
			]
		} else if (stype === 'discount-freq') {
			return [
				{ value: '', text: 'Select' },
				{ value: 'Monthly', text: 'Monthly' },
				{ value: 'Daily', text: 'Daily' }
			]
		} else if (stype === 'discount-freq-mgm') {
			return [
				{ value: '', text: 'Select' },
				{ value: 'Monthly', text: 'Monthly' }
			]
		} else if (stype === 'funding-type') {
			return [
				{ value: '', text: 'Select' },
				{ value: 'Separate Fees and Deposits', text: 'Separate Fees and Deposits' },
				{ value: 'Net Fees and Deposits', text: 'Net Fees and Deposits' },
				{ value: 'Individual Batches', text: 'Individual Batches' }
			]
		} else if (stype === 'provider') {
			return [
				{ value: '', text: 'Select' },
				{ value: 'payabli', text: 'Payabli' }
			]
		} else if (stype === 'us_states') {
			return [
				{ value: '', text: 'Select State' },
				{ value: 'AL', text: 'Alabama' },
				{ value: 'AK', text: 'Alaska' },
				{ value: 'AZ', text: 'Arizona' },
				{ value: 'AR', text: 'Arkansas' },
				{ value: 'CA', text: 'California' },
				{ value: 'CO', text: 'Colorado' },
				{ value: 'CT', text: 'Connecticut' },
				{ value: 'DE', text: 'Delaware' },
				{ value: 'DC', text: 'District Of Columbia' },
				{ value: 'FL', text: 'Florida' },
				{ value: 'GA', text: 'Georgia' },
				{ value: 'HI', text: 'Hawaii' },
				{ value: 'ID', text: 'Idaho' },
				{ value: 'IL', text: 'Illinois' },
				{ value: 'IN', text: 'Indiana' },
				{ value: 'IA', text: 'Iowa' },
				{ value: 'KS', text: 'Kansas' },
				{ value: 'KY', text: 'Kentucky' },
				{ value: 'LA', text: 'Louisiana' },
				{ value: 'ME', text: 'Maine' },
				{ value: 'MD', text: 'Maryland' },
				{ value: 'MA', text: 'Massachusetts' },
				{ value: 'MI', text: 'Michigan' },
				{ value: 'MN', text: 'Minnesota' },
				{ value: 'MS', text: 'Mississippi' },
				{ value: 'MO', text: 'Missouri' },
				{ value: 'MT', text: 'Montana' },
				{ value: 'NE', text: 'Nebraska' },
				{ value: 'NV', text: 'Nevada' },
				{ value: 'NH', text: 'New Hampshire' },
				{ value: 'NJ', text: 'New Jersey' },
				{ value: 'NM', text: 'New Mexico' },
				{ value: 'NY', text: 'New York' },
				{ value: 'NC', text: 'North Carolina' },
				{ value: 'ND', text: 'North Dakota' },
				{ value: 'OH', text: 'Ohio' },
				{ value: 'OK', text: 'Oklahoma' },
				{ value: 'OR', text: 'Oregon' },
				{ value: 'PA', text: 'Pennsylvania' },
				{ value: 'RI', text: 'Rhode Island' },
				{ value: 'SC', text: 'South Carolina' },
				{ value: 'SD', text: 'South Dakota' },
				{ value: 'TN', text: 'Tennessee' },
				{ value: 'TX', text: 'Texas' },
				{ value: 'UT', text: 'Utah' },
				{ value: 'VT', text: 'Vermont' },
				{ value: 'VA', text: 'Virginia' },
				{ value: 'WA', text: 'Washington' },
				{ value: 'WV', text: 'West Virginia' },
				{ value: 'WI', text: 'Wisconsin' },
				{ value: 'WY', text: 'Wyoming' }
			]
		} else if (stype === 'us_countries') {
			return [
				{ value: '', text: 'Select Country' },
				{ value: 'AF', text: 'Afghanistan' },
				{ value: 'AX', text: 'Aland Islands' },
				{ value: 'AL', text: 'Albania' },
				{ value: 'DZ', text: 'Algeria' },
				{ value: 'AS', text: 'American Samoa' },
				{ value: 'AD', text: 'Andorra' },
				{ value: 'AO', text: 'Angola' },
				{ value: 'AI', text: 'Anguilla' },
				{ value: 'AQ', text: 'Antarctica' },
				{ value: 'AG', text: 'Antigua and Barbuda' },
				{ value: 'AR', text: 'Argentina' },
				{ value: 'AM', text: 'Armenia' },
				{ value: 'AW', text: 'Aruba' },
				{ value: 'AU', text: 'Australia' },
				{ value: 'AT', text: 'Austria' },
				{ value: 'AZ', text: 'Azerbaijan' },
				{ value: 'BS', text: 'Bahamas' },
				{ value: 'BH', text: 'Bahrain' },
				{ value: 'BD', text: 'Bangladesh' },
				{ value: 'BB', text: 'Barbados' },
				{ value: 'BY', text: 'Belarus' },
				{ value: 'BE', text: 'Belgium' },
				{ value: 'BZ', text: 'Belize' },
				{ value: 'BJ', text: 'Benin' },
				{ value: 'BM', text: 'Bermuda' },
				{ value: 'BT', text: 'Bhutan' },
				{ value: 'BO', text: 'Bolivia, Plurinational State of' },
				{ value: 'BQ', text: 'Bonaire, Sint Eustatius and Saba' },
				{ value: 'BA', text: 'Bosnia and Herzegovina' },
				{ value: 'BW', text: 'Botswana' },
				{ value: 'BV', text: 'Bouvet Island' },
				{ value: 'BR', text: 'Brazil' },
				{ value: 'IO', text: 'British Indian Ocean Territory' },
				{ value: 'BN', text: 'Brunei Darussalam' },
				{ value: 'BG', text: 'Bulgaria' },
				{ value: 'BF', text: 'Burkina Faso' },
				{ value: 'BI', text: 'Burundi' },
				{ value: 'KH', text: 'Cambodia' },
				{ value: 'CM', text: 'Cameroon' },
				{ value: 'CA', text: 'Canada' },
				{ value: 'CV', text: 'Cape Verde' },
				{ value: 'KY', text: 'Cayman Islands' },
				{ value: 'CF', text: 'Central African Republic' },
				{ value: 'TD', text: 'Chad' },
				{ value: 'CL', text: 'Chile' },
				{ value: 'CN', text: 'China' },
				{ value: 'CX', text: 'Christmas Island' },
				{ value: 'CC', text: 'Cocos (Keeling) Islands' },
				{ value: 'CO', text: 'Colombia' },
				{ value: 'KM', text: 'Comoros' },
				{ value: 'CG', text: 'Congo' },
				{ value: 'CD', text: 'Congo, the Democratic Republic of the' },
				{ value: 'CK', text: 'Cook Islands' },
				{ value: 'CR', text: 'Costa Rica' },
				{ value: 'CI', text: "Cote d'Ivoire" },
				{ value: 'HR', text: 'Croatia' },
				{ value: 'CU', text: 'Cuba' },
				{ value: 'CW', text: 'Curacao' },
				{ value: 'CY', text: 'Cyprus' },
				{ value: 'CZ', text: 'Czech Republic' },
				{ value: 'DK', text: 'Denmark' },
				{ value: 'DJ', text: 'Djibouti' },
				{ value: 'DM', text: 'Dominica' },
				{ value: 'DO', text: 'Dominican Republic' },
				{ value: 'EC', text: 'Ecuador' },
				{ value: 'EG', text: 'Egypt' },
				{ value: 'SV', text: 'El Salvador' },
				{ value: 'GQ', text: 'Equatorial Guinea' },
				{ value: 'ER', text: 'Eritrea' },
				{ value: 'EE', text: 'Estonia' },
				{ value: 'ET', text: 'Ethiopia' },
				{ value: 'FK', text: 'Falkland Islands (Malvinas)' },
				{ value: 'FO', text: 'Faroe Islands' },
				{ value: 'FJ', text: 'Fiji' },
				{ value: 'FI', text: 'Finland' },
				{ value: 'FR', text: 'France' },
				{ value: 'GF', text: 'French Guiana' },
				{ value: 'PF', text: 'French Polynesia' },
				{ value: 'TF', text: 'French Southern Territories' },
				{ value: 'GA', text: 'Gabon' },
				{ value: 'GM', text: 'Gambia' },
				{ value: 'GE', text: 'Georgia' },
				{ value: 'DE', text: 'Germany' },
				{ value: 'GH', text: 'Ghana' },
				{ value: 'GI', text: 'Gibraltar' },
				{ value: 'GR', text: 'Greece' },
				{ value: 'GL', text: 'Greenland' },
				{ value: 'GD', text: 'Grenada' },
				{ value: 'GP', text: 'Guadeloupe' },
				{ value: 'GU', text: 'Guam' },
				{ value: 'GT', text: 'Guatemala' },
				{ value: 'GG', text: 'Guernsey' },
				{ value: 'GN', text: 'Guinea' },
				{ value: 'GW', text: 'Guinea-Bissau' },
				{ value: 'GY', text: 'Guyana' },
				{ value: 'HT', text: 'Haiti' },
				{ value: 'HM', text: 'Heard Island and McDonald Islands' },
				{ value: 'VA', text: 'Holy See (Vatican City State)' },
				{ value: 'HN', text: 'Honduras' },
				{ value: 'HK', text: 'Hong Kong' },
				{ value: 'HU', text: 'Hungary' },
				{ value: 'IS', text: 'Iceland' },
				{ value: 'IN', text: 'India' },
				{ value: 'ID', text: 'Indonesia' },
				{ value: 'IR', text: 'Iran, Islamic Republic of' },
				{ value: 'IQ', text: 'Iraq' },
				{ value: 'IE', text: 'Ireland' },
				{ value: 'IM', text: 'Isle of Man' },
				{ value: 'IL', text: 'Israel' },
				{ value: 'IT', text: 'Italy' },
				{ value: 'JM', text: 'Jamaica' },
				{ value: 'JP', text: 'Japan' },
				{ value: 'JE', text: 'Jersey' },
				{ value: 'JO', text: 'Jordan' },
				{ value: 'KZ', text: 'Kazakhstan' },
				{ value: 'KE', text: 'Kenya' },
				{ value: 'KI', text: 'Kiribati' },
				{ value: 'KP', text: "Korea, Democratic People's Republic of" },
				{ value: 'KR', text: 'Korea, Republic of' },
				{ value: 'KW', text: 'Kuwait' },
				{ value: 'KG', text: 'Kyrgyzstan' },
				{ value: 'LA', text: "Lao People's Democratic Republic" },
				{ value: 'LV', text: 'Latvia' },
				{ value: 'LB', text: 'Lebanon' },
				{ value: 'LS', text: 'Lesotho' },
				{ value: 'LR', text: 'Liberia' },
				{ value: 'LY', text: 'Libya' },
				{ value: 'LI', text: 'Liechtenstein' },
				{ value: 'LT', text: 'Lithuania' },
				{ value: 'LU', text: 'Luxembourg' },
				{ value: 'MO', text: 'Macao' },
				{ value: 'MK', text: 'Macedonia, the former Yugoslav Republic of' },
				{ value: 'MG', text: 'Madagascar' },
				{ value: 'MW', text: 'Malawi' },
				{ value: 'MY', text: 'Malaysia' },
				{ value: 'MV', text: 'Maldives' },
				{ value: 'ML', text: 'Mali' },
				{ value: 'MT', text: 'Malta' },
				{ value: 'MH', text: 'Marshall Islands' },
				{ value: 'MQ', text: 'Martinique' },
				{ value: 'MR', text: 'Mauritania' },
				{ value: 'MU', text: 'Mauritius' },
				{ value: 'YT', text: 'Mayotte' },
				{ value: 'MX', text: 'Mexico' },
				{ value: 'FM', text: 'Micronesia, Federated States of' },
				{ value: 'MD', text: 'Moldova, Republic of' },
				{ value: 'MC', text: 'Monaco' },
				{ value: 'MN', text: 'Mongolia' },
				{ value: 'ME', text: 'Montenegro' },
				{ value: 'MS', text: 'Montserrat' },
				{ value: 'MA', text: 'Morocco' },
				{ value: 'MZ', text: 'Mozambique' },
				{ value: 'MM', text: 'Myanmar' },
				{ value: 'NA', text: 'Namibia' },
				{ value: 'NR', text: 'Nauru' },
				{ value: 'NP', text: 'Nepal' },
				{ value: 'NL', text: 'Netherlands' },
				{ value: 'NC', text: 'New Caledonia' },
				{ value: 'NZ', text: 'New Zealand' },
				{ value: 'NI', text: 'Nicaragua' },
				{ value: 'NE', text: 'Niger' },
				{ value: 'NG', text: 'Nigeria' },
				{ value: 'NU', text: 'Niue' },
				{ value: 'NF', text: 'Norfolk Island' },
				{ value: 'MP', text: 'Northern Mariana Islands' },
				{ value: 'NO', text: 'Norway' },
				{ value: 'OM', text: 'Oman' },
				{ value: 'PK', text: 'Pakistan' },
				{ value: 'PW', text: 'Palau' },
				{ value: 'PS', text: 'Palestinian Territory, Occupied' },
				{ value: 'PA', text: 'Panama' },
				{ value: 'PG', text: 'Papua New Guinea' },
				{ value: 'PY', text: 'Paraguay' },
				{ value: 'PE', text: 'Peru' },
				{ value: 'PH', text: 'Philippines' },
				{ value: 'PN', text: 'Pitcairn' },
				{ value: 'PL', text: 'Poland' },
				{ value: 'PT', text: 'Portugal' },
				{ value: 'PR', text: 'Puerto Rico' },
				{ value: 'QA', text: 'Qatar' },
				{ value: 'RE', text: 'Reunion' },
				{ value: 'RO', text: 'Romania' },
				{ value: 'RU', text: 'Russian Federation' },
				{ value: 'RW', text: 'Rwanda' },
				{ value: 'BL', text: 'Saint Barthélemy' },
				{ value: 'SH', text: 'Saint Helena, Ascension and Tristan da Cunha' },
				{ value: 'KN', text: 'Saint Kitts and Nevis' },
				{ value: 'LC', text: 'Saint Lucia' },
				{ value: 'MF', text: 'Saint Martin (French part)' },
				{ value: 'PM', text: 'Saint Pierre and Miquelon' },
				{ value: 'VC', text: 'Saint Vincent and the Grenadines' },
				{ value: 'WS', text: 'Samoa' },
				{ value: 'SM', text: 'San Marino' },
				{ value: 'ST', text: 'Sao Tome and Principe' },
				{ value: 'SA', text: 'Saudi Arabia' },
				{ value: 'SN', text: 'Senegal' },
				{ value: 'RS', text: 'Serbia' },
				{ value: 'SC', text: 'Seychelles' },
				{ value: 'SL', text: 'Sierra Leone' },
				{ value: 'SG', text: 'Singapore' },
				{ value: 'SX', text: 'Sint Maarten (Dutch part)' },
				{ value: 'SK', text: 'Slovakia' },
				{ value: 'SI', text: 'Slovenia' },
				{ value: 'SB', text: 'Solomon Islands' },
				{ value: 'SO', text: 'Somalia' },
				{ value: 'ZA', text: 'South Africa' },
				{ value: 'GS', text: 'South Georgia and the South Sandwich Islands' },
				{ value: 'SS', text: 'South Sudan' },
				{ value: 'ES', text: 'Spain' },
				{ value: 'LK', text: 'Sri Lanka' },
				{ value: 'SD', text: 'Sudan' },
				{ value: 'SR', text: 'Suriname' },
				{ value: 'SJ', text: 'Svalbard and Jan Mayen' },
				{ value: 'SZ', text: 'Swaziland' },
				{ value: 'SE', text: 'Sweden' },
				{ value: 'CH', text: 'Switzerland' },
				{ value: 'SY', text: 'Syrian Arab Republic' },
				{ value: 'TW', text: 'Taiwan, Province of China' },
				{ value: 'TJ', text: 'Tajikistan' },
				{ value: 'TZ', text: 'Tanzania, United Republic of' },
				{ value: 'TH', text: 'Thailand' },
				{ value: 'TL', text: 'Timor-Leste' },
				{ value: 'TG', text: 'Togo' },
				{ value: 'TK', text: 'Tokelau' },
				{ value: 'TO', text: 'Tonga' },
				{ value: 'TT', text: 'Trinidad and Tobago' },
				{ value: 'TN', text: 'Tunisia' },
				{ value: 'TR', text: 'Turkey' },
				{ value: 'TM', text: 'Turkmenistan' },
				{ value: 'TC', text: 'Turks and Caicos Islands' },
				{ value: 'TV', text: 'Tuvalu' },
				{ value: 'UG', text: 'Uganda' },
				{ value: 'UA', text: 'Ukraine' },
				{ value: 'AE', text: 'United Arab Emirates' },
				{ value: 'GB', text: 'United Kingdom' },
				{ value: 'US', text: 'United States' },
				{ value: 'UM', text: 'United States Minor Outlying Islands' },
				{ value: 'UY', text: 'Uruguay' },
				{ value: 'UZ', text: 'Uzbekistan' },
				{ value: 'VU', text: 'Vanuatu' },
				{ value: 'VE', text: 'Venezuela, Bolivarian Republic of' },
				{ value: 'VN', text: 'Viet Nam' },
				{ value: 'VG', text: 'Virgin Islands, British' },
				{ value: 'VI', text: 'Virgin Islands, U.S.' },
				{ value: 'WF', text: 'Wallis and Futuna' },
				{ value: 'EH', text: 'Western Sahara' },
				{ value: 'YE', text: 'Yemen' },
				{ value: 'ZM', text: 'Zambia' },
				{ value: 'ZW', text: 'Zimbabwe' }
			]
		} else if (stype === 'mcc') {
			var d = [{ value: '', text: 'Select Merchant Category' }]
			for (var i = 0; i < this.mcc.length; i++) {
				d.push({ value: this.mcc[i].mcc, text: this.mcc[i].mcc + ' - ' + this.mcc[i].irs_description })
			}
			return d
		} else if (stype === 'countries') {
			var c = [{ value: '', text: 'Select Country' }]
			for (var ii = 0; ii < this.countries.length; ii++) {
				c.push({ value: this.countries[ii].value, text: this.countries[ii].text })
			}
			return c
		} else if (stype === 's5x1') {
			let dd = []
			for (let ii = 1; ii <= 5; ii++) {
				dd.push({ value: ii, text: ii })
			}
			return dd
		} else if (stype === 's100x10') {
			var ddi = []
			for (var j = 0; j < 11; j++) {
				ddi.push({ value: j * 10, text: j * 10 + '%' })
			}
			return ddi
		} else if (stype === 's25x5') {
			var ddj = []
			for (var ij = 5; ij < 21; ij++) {
				ddj.push({ value: ij * 5, text: ij * 5 + '%' })
			}
			return ddj
		} else if (stype === 'timezones') {
			return [
				{ value: '0', text: 'UTC' },
				{ value: '-4', text: 'Atlantic Time UTC−04:00' },
				{ value: '-5', text: 'Eastern Time UTC−05:00' },
				{ value: '-6', text: 'Central Time UTC−06:00' },
				{ value: '-7', text: 'Mountain Time UTC−07:00' },
				{ value: '-8', text: 'Pacific Time UTC−08:00' },
				{ value: '-9', text: 'Alaskan Time UTC−09:00' },
				{ value: '-10', text: 'Hawaii-Aleutian Time UTC−10:00' }
			]
		}
	}

	protect(history) {
		const encryptedStorage = PayabliStorageManager.getEncryptedLocalStorage()
		if (encryptedStorage && !encryptedStorage.getItem('pToken')) {
			history.push('/login')
		}
	}

	stringDateFormat(stringDate, removeDay) {
		const utcDate = new Date(stringDate);
		const options = {
			year: 'numeric',
			month: 'long',
			timeZone: 'UTC'
		};
		if (!removeDay) {
			options.day = 'numeric'
		}
		return utcDate.toLocaleDateString('en-US', options);
	}

	stringDateFormatV3(string) {
		var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
		var date = new Date(string)
		return monthNames[date.getMonth()] + ' ' + (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) + ', ' + date.getFullYear()
	}

	formatDateTimeWithStyle(dateTimeString) {
		const date = new Date(dateTimeString);

		const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
		const formattedDate = date.toLocaleDateString('en-US', dateOptions);

		const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
		const formattedTime = <span className='format-date-time'>{date.toLocaleTimeString('en-US', timeOptions)}</span>;

		return <span className='d-grid'>{formattedDate} {formattedTime}</span>;
	}

	phoneNumberFormat(phoneNumberString) {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
		var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
		if (match) {
			var intlCode = match[1] ? '+1 ' : ''
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
		}
		return null
	}

	getMonths(index) {
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		if(Number.isInteger(index)){
			return months[index];
		}
		return months
	}

	stringDateFormatV2(string) {
		let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		string = string.split('-')
		return monthNames[parseInt(string[1]) - 1] + ' ' + string[0]
	}

	stringDateFormatV4(list) {
		console.log(list)
		var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
			data = [],
			string = ''
		list.forEach((element) => {
			string = element.split('-')
			data.push(monthNames[parseInt(string[1]) - 1] + ' ' + string[0])
		})
		return data
	}

	expDateFormat(string) {
		if (string.length === 3) {
			return string[0] + '/' + string[string.length - 2] + string[string.length - 1]
		} else if (string.length === 4 || string.length === 5) {
			return string[0] + string[1] + '/' + string[string.length - 2] + string[string.length - 1]
		}
	}

	stringDateFormatFilters(string) {
		let date = new Date(string)
		return date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-' + (date.getDate() > 9 ? date.getDate() : '0' + date.getDate())
	}

	addDays(pDate, days) {
		let date = new Date(pDate)
		date.setDate(date.getDate() + days)
		return date
	}

	stringTimeFormat(string) {
		if (isNaN(Date.parse(string))) {
			return null
		}
		const dateStringWithoutTimeZone = string.toString().replace(/[-+]\d{2}:\d{2}$/, '')
		var date = new Date(dateStringWithoutTimeZone)
		var hours = date.getHours()
		var minutes = date.getMinutes()
		var ampm = hours >= 12 ? 'PM' : 'AM'
		hours = hours % 12
		hours = hours ? hours : 12
		minutes = minutes < 10 ? '0' + minutes : minutes
		var strTime = hours.toString().padStart(2, '0') + ':' + minutes + ' ' + ampm
		return strTime
	}

	stringTimeFormatV3(string) {
		if (isNaN(Date.parse(string))) {
			return null
		}
		const dateStringWithoutTimeZone = string.toString().replace(/[-+]\d{2}:\d{2}$/, '')
		let date = new Date(dateStringWithoutTimeZone)
		let hours = date.getHours()
		let minutes = date.getMinutes()
		let seconds = date.getSeconds()
		var ampm = hours >= 12 ? 'PM' : 'AM'
		hours = hours % 12
		hours = hours ? hours : 12
		minutes = minutes < 10 ? '0' + minutes : minutes
		var strTime = hours.toString().padStart(2, '0') + ':' + minutes + ':' + seconds + ' ' + ampm
		return strTime
	}

	validateDate(date) {
		try {
			const newDate = new Date(date)
			return isNaN(newDate) ? null : new Date(date)
		} catch (error) {
			return null
		}
	}

	/* Military Format Time 00:00:00 */
	stringTimeFormatV2(string) {
		let seconds = Math.floor(string / 1000),
			minutes = Math.floor(seconds / 60),
			hours = Math.floor(minutes / 60)
		seconds = seconds % 60
		minutes = minutes % 60
		return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
	}

	capitalizeFirstLetter(string) {
		if (typeof string === 'string' && string !== '') {
			string = string.toLowerCase()
			return string[0].toUpperCase() + string.slice(1)
		}
		return ''
	}

	capitalizeFirstLetterOfEachWord(string) {
		if (typeof string === 'string') {
			return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
		}
		return ''
	}

	maskedCardNumber(string, version) {
		if (!string) {
			return string
		}

		switch (version) {
			case 'v1':
				return string.replace('XXXXXX', 'xxx xxxx xxxx ')
			case 'v2':
				return string.replaceAll('X', 'x')
			case 'v3':
				return '•••• •••• ••••  ' + string.substr(string.length - 4)
			case 'v4':
				return string.replace('XXXXXXXX', 'xxx xxxx xxxx ')
			case 'v5':
				return '•••• ' + string.substr(string.length - 4)
			case 'v6':
				return `•••• ${string.substr(string.length - 4)}`
			case 'v7':
				return string.length > 8 ? `... ${string.substr(string.length - 8)}` : string
			default:
				return string
		}
	}

	maskedNumber(number) {
		number = number.replace(/\d(?=\d{4})/g, '*')
		return number
	}

	emailValidation(email) {
		let res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return res.test(email)
	}

	multiple_emailsValidation(emails) {
		let res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		const emailsArray = emails.split(';')
		return !emailsArray.map(email => email.trim().length > 0 ? res.test(email.trim()) : true).includes(false)
	}

	maskValidator(validation) {
		switch (validation) {
			case 'alpha':
				return /^[a-zA-Z\s]*$/
			case 'numbers':
				return Number
			case 'float':
				return Number
			case 'alphanumeric':
				return /^[a-z0-9_-]+$/i
			case 'alphanumericspaces':
				return /^[a-zA-Z0-9 ]+$/i
			case 'text':
				return String
			case 'email':
				return /^([a-zA-Z0-9][-._]?)+@?([a-zA-Z0-9][-._]?)*(\.)?([a-zA-Z]*)$/
			case 'phone':
				return '(000) 000-0000'
			case 'routing':
				return '000000000'
			case 'accountnumber':
				return /^[0-9xX]+$/i
			case 'cardDate':
				return '00/00'
			case 'cvv':
				return '000'
			case 'cvvamex':
				return '0000'
			case 'zipcode':
				return /^(?=.{0,7}$)([A-Za-z0-9])+ ?([A-Za-z0-9]){0,3}$/g
			case 'creditCard':
				return '0000 0000 0000 0000'
			case 'creditCardAmex':
				return '0000 000000 00000'
			case 'ein':
				return '00 0000000'
			case 'ssn':
				return '000000000'
			default:
				return String
		}
	}

	validators = {
		isEmpty: function (value) {
			if (value === '' || value === null || value === undefined) {
				return true
			} else {
				return false
			}
		},

		isMaxLength: function (length, value) {
			if (value) {
				if (value.length > length) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		},

		isMinLength: function (length, value) {
			if (value) {
				if (value.length >= length) {
					return false
				} else {
					return true
				}
			} else {
				return false
			}
		},

		stringValidator: function (validation, value) {
			if (validation === 'moneynonzero') {
				return (!Number(value) === value && value % 1 !== 0) || value <= 0
			}
			if (!value) {
				return false
			}
			switch (validation) {
				case 'alpha':
					return !/^[a-zA-Z\s]*$/.test(value)
				case 'numbers':
					return !/^[0-9]*$/.test(value)
				case 'accountnumber':
					return !/^[0-9xX]*$/.test(value)
				case 'alphanumericspaces':
					return !/^[a-zA-Z0-9 ]+$/i.test(value)
				case 'float':
					return !Number(value) === value && value % 1 !== 0
				case 'moneynonzero':
					return (!Number(value) === value && value % 1 !== 0) || value <= 0
				case 'alphanumeric':
					return !/^([a-zA-Z0-9 _-]+)$/.test(value)
				case 'text':
					return false
				case 'email':
					return !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value.toLowerCase())
				case 'emailSemicolon':
					value = value.replaceAll(' ', '')
					let emails = value.split(';')
					let response = true
					emails.forEach(function (item) {
						if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(item.toLowerCase())) {
							response = false
						}
					})
					return response
				case 'phone':
					return !/^([0-9]{10,10})$/.test(value)
				case 'card':
					return !/^([0-9]{14,16})$/.test(value)
				case 'routing':
					return !/^([0-9]{9,9})$/.test(value)
				case 'exp':
					return !/^([0-9]{4,4})$/.test(value)
				case 'cvvamex':
					return !/^([0-9]{4,4})$/.test(value)
				case 'cvv':
					return !/^([0-9]{3,3})$/.test(value)
				case 'zipcode':
					return !/^(?=.{0,7}$)([A-Za-z0-9])+ ?([A-Za-z0-9]){0,3}$/g.test(value);
				case 'ein':
					return !/^([0-9]{9,9})$/.test(value)
				case 'ssn':
					return !/^([0-9]{9,9})$/.test(value)
				case 'url':
					return !/[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi.test(value)
				case 'urlprotocol':
					return !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi.test(value)
				case 'ipv4':
					return !/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(value)
				default:
					return String
			}
		}
	}

	getTransStatus(status) {
		switch (status) {
			case -1:
			case 0:
				return <span className="badge bg-danger"><BiErrorCircle /> Failed</span>
			case 1:
				return <span className="badge bg-success"><BiDollarCircle /> Approved</span>
			case 2:
			case 3:
				return <span className="badge bg-danger"><BiXCircle /> Declined</span>
			case 5:
				return <span className="badge bg-light"><BiLogOutCircle /> Voided</span>
			case 10:
				return <span className="badge bg-info"><LuGoal /> Initiated </span>
			case 11:
				return <span className="badge bg-primary"><BiLogInCircle /> Authorized</span>
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A </span>
		}
	}

	getSettlementTransStatus(status, record) {
		const method = record?.Method?.toLowerCase();
		const textMethod = method === 'card' ? 'Chargeback' : 'ACH Return'
		if (record) {
			const allowedTransStatus = [0, -1, 2, 3, 5];
			const allowedMethods = ['cash', 'check'];
			if ((allowedTransStatus.includes(record.TransStatus) || allowedMethods.includes(method))) {
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
			}
		}

		switch (status) {
			case 0:
				return <span className={'badge bg-warning'}><BiLoader /> Pending</span>
			case 1:
				return <span className={'badge bg-info'}>{this.getGlobalImg('intransitinfodark', '12px')} In Transit</span>
			case 2:
				return <span className={'badge bg-success'}><BiDollar /> Transferred</span>
			case 3:
				return <span className={'badge bg-success'}><BiDollarCircle /> Funded</span>
			case -1:
				return <span className={'badge bg-danger'}><BiErrorCircle /> Exception</span>
			case -2:
				return <span className={'badge bg-secondary'}><BiShare /> {textMethod}</span>
			case -5:
				return <span className="badge bg-warning"><BiPauseCircle /> Held</span>
			case -6:
				return <span className="badge bg-success"><BiUpArrowCircle /> Released</span>
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}

	autopayStatus(status){
        switch(status){
            case 1:
                return <span className="badge bg-primary"><BiCheckCircle /> Active</span>;
            case 0:
                return <span className="badge bg-warning"><BiMinusCircle /> Paused</span>;
            case 99:
                return <span className="badge bg-success"><BiCheckDouble /> Completed</span>;
            default:
							return <span className="badge bg-light"><CgUnavailable /> N/A</span>;
        }
    }

	getBatchStatus(status, text) {
		switch (status) {
			case 0:
				return <span className={text === true ? 'text-primary' : 'badge bg-primary'}><BiArchive /> Open</span>
			case 1:
				return <span className={text === true ? 'text-secondary' : 'badge bg-secondary'}><BiBox /> Closed</span>
			case 2:
				return <span className={text === true ? 'text-success' : 'badge bg-success'}><BiDollar /> Transferred</span>
			case 3:
				return <span className={text === true ? 'text-success' : 'badge bg-success'}><BiDollarCircle /> Funded</span>
			case -5:
				return <span className={text === true ? 'text-warning' : 'badge bg-warning'}><BiPauseCircle /> Held</span>
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}

	getTransferStatus(status) {
		switch (status) {
			case 0:
				return <span className={'badge bg-warning'}><BiLoader /> Pending</span>
			case 1:
				return <span className={'badge bg-info'}>{this.getGlobalImg('intransitinfodark', '12px')} In Transit</span>
			case 2:
				return <span className={'badge bg-success'}><BiDollar /> Transferred</span>
			case 3:
				return <span className={'badge bg-success'}><BiDollarCircle /> Funded</span>
			case -1:
				return <span className={'badge bg-danger'}><BiErrorCircle /> Exception</span>
			case -2:
				return <span className={'badge bg-warning'}><BiDoughnutChart /> Pending Balance</span>
			case -4:
				return <span className={'badge bg-secondary'}><BiShare /> ACH Return</span>
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}

	getBatchMoStatus(status, text) {
		switch (parseInt(status)) {
			case 0:
				return <span className={text === true ? 'text-primary' : 'badge bg-primary'}><BiArchive /> Open</span>
			case 1:
				return <span className={text === true ? 'text-secondary' : 'badge bg-secondary'}><BiBox /> Closed</span>
			case 2:
				return <span className={text === true ? 'text-success' : 'badge bg-success'}><BiDollar /> Funded</span>
			case 3:
				return <span className={text === true ? 'text-info' : 'badge bg-info'}>{this.getGlobalImg('intransitinfodark', '12px')} Processed</span>
			case 4:
				return <span className={text === true ? 'text-success' : 'badge bg-success'}><BiDollarCircle /> Paid</span>
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}

	getInvoicesStatus(status, invoiceDue) {
		let statusCalculated = status
		const today = new Date()
		const invoiceDueDate = new Date(invoiceDue)

		if ((status === 1 || status === 2) && today > invoiceDueDate) statusCalculated = 'overdue'

		switch (statusCalculated) {
			case 0:
				return <span className="badge bg-light"><BiPaint /> Draft</span>
			case 1:
				return <span className="badge bg-primary"><BiCheckCircle /> Active</span>
			case 2:
				return <span className="badge bg-warning"><BiAdjust /> Partially Paid</span>
			case 4:
				return <span className="badge bg-success"><BiDollarCircle /> Paid</span>
			case 99:
				return <span className="badge bg-danger"><BiLogOutCircle /> Canceled</span>
			case 'overdue':
				return <span className="badge bg-danger"><BiRotateLeft /> Past Due</span>
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}

	getChargebackStatus(status) {
		switch (status) {
			case 0:
				return <span className="badge bg-primary"><BiArchive /> Open</span>
			case 1:
				return <span className="badge bg-warning"><BiLoader /> Pending</span>
			case 2:
				return <span className="badge bg-success"><BiBox /> Closed-Won</span>
			case 3:
				return <span className="badge bg-danger"><BiBox /> Closed-Lost</span>
			case 4:
				return <span className="badge bg-info"><BiShare /> ACH Return</span>
			case 5:
				return <span className="badge bg-secondary"><BiShare /> ACH Chargeback</span>
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}

	getXchangeLogStatus(status) {
		switch (Number(status)) {
			case 1:
                return <span className="badge bg-success"><BiEnvelope /> Received</span>;
            case 2:
                return <span className="badge bg-secondary"><BiHourglass /> In Progress</span>;
            case 3:
                return <span className="badge bg-primary"><BiLogInCircle /> Imported</span>;
            case 4:
                return <span className="badge bg-info"><BiCheckDouble /> Completed</span>;
            case 5:
                return <span className="badge bg-warning"><BiErrorCircle /> Errored</span>;
            case 6:
                return <span className="badge bg-danger"><BiXCircle /> Cancelled</span>;
            case 7:
                return <span className="badge bg-success"><BiRocket /> Sent</span>;
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}

	getXchangeFrequency(type) {
		let tempFreq = type.toLowerCase()
		switch (tempFreq) {
			case 'hourly':
				return <span className="badge bg-primary"><BiAlarm /> Hourly</span>;
			case 'weekly':
				return <span className="badge bg-success"><BiCalendarCheck /> Weekly</span>;
			case 'daily':
				return <span className="badge bg-secondary"><BiCalendar /> Daily</span>;
			case 'monthly':
				return <span className="badge bg-info"><BiCalendarEvent /> Monthly</span>;
			default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>
		}
	}

	getXchangeFrequencyScheduleTime(time) {
		if (time === '' || time === null) {
			return '-'
		} else {
			let arr = time.split('T')
			return arr[0]
		}
	}

	getTransStatusText(status) {
		switch (status) {
			case 1:
				return <span className="green-text">Success</span>
			case 15:
				return <span className="">Refunded</span>
			case 5:
				return <span className="">Voided</span>
			case 12:
				return <span className="">Returned</span>
			case 11:
				return <span className="">Uncaptured</span>
			case 3:
				return <span className="red-text">Failed</span>
			case 2:
				return <span className="red-text">Declined</span>
			case -1:
				return <span className="red-text">Error</span>
			default:
				return <span className="red-text">Error</span>
		}
	}

	@action
	setRightPanelOpen(isOpen) {
		this.rightPanelOpen = isOpen
	}

	@action
	setFilterRightPanelOpen(isOpen) {
		this.filterRightPanelOpen = isOpen
	}

	@action
	setSlideParentViewOpen(isOpen) {
		this.slideParentViewOpen = isOpen
	}

	@action
	setLoading(status) {
		this.loading = status
	}

	@computed
	get isLoading() {
		return this.loading
	}

	getURLEntry() {
		let url = new URL(window.location.href)
		let pathname = url.pathname.split('/')[1]
		return pathname
	}

	getUToken() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		var entryToken = encryptStorage && encryptStorage.getItem('pToken') ? encryptStorage.getItem('pToken') : ''
		entryToken = entryToken.replaceAll('/', '_')
		return encodeURIComponent(entryToken)
	}

	getDateFromValue(d) {
		var x = new Date();

    if (d !== null && d.length > 0) {
        let datePart, timePart;

        if (d.includes('T')) {
            // input has a format "yyyy-mm-ddThh:mm:ss"
            let dateTimeParts = d.split('T');
            datePart = dateTimeParts[0];
            timePart = dateTimeParts[1];
        } else if (d.includes('/') || d.includes('-')) {
            // input has a format "dd/mm/yyyy" or "yyyy-mm-dd"
            datePart = d;
        } else {
            return null; 
        }
        let dateParts;
        if (datePart.includes('/')) {
            dateParts = datePart.split('/');
        } else if (datePart.includes('-')) {
            dateParts = datePart.split('-');
        }
        if (dateParts && dateParts.length === 3 && !isNaN(dateParts[0]) && !isNaN(dateParts[1]) && !isNaN(dateParts[2])) {
			let year = parseInt(dateParts[0], 10);
			let month = parseInt(dateParts[1], 10) - 1;
			let day = parseInt(dateParts[2], 10);
			if(!timePart){
				year = parseInt(dateParts[2], 10);
				month = parseInt(dateParts[1], 10) - 1;
				day = parseInt(dateParts[0], 10);
			}

            if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
                x = new Date(year, month, day);

                if (!isNaN(x.getTime())) {
                    if (timePart) {
                        let timeParts = timePart.split(':');
                        let hours = parseInt(timeParts[0], 10);
                        let minutes = parseInt(timeParts[1], 10);
                        let seconds = parseInt(timeParts[2], 10);

                        if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
                            x.setHours(hours, minutes, seconds);
                        }
                    }

                    return x;
                }
            }
        }
    }

    return null;
	}

	getValidationMessage(validation) {
		switch (validation) {
			case 'alpha':
				return 'Value must be alphabetic'
			case 'alphanumeric':
				return 'Value must be alphanumeric'
			case 'numbers':
				return 'Value must be a valid number'
			case 'float':
				return 'Value must be a valid number'
			case 'text':
				return 'Value must be a valid text'
			case 'email':
				return 'Value must be a valid email'
			case 'phone':
				return 'Value must be a valid phone number'
			case 'ein':
				return 'Value must be a valid EIN'
			case 'card':
				return 'Value must be a valid Card'
			case 'routing':
				return 'Value must be a valid Routing Number'
			case 'exp':
				return 'Value must be a valid Expiration Date'
			case 'cvvamex':
				return 'Value must be a valid CVV'
			case 'cvv':
				return 'Value must be a valid CVV'
			case 'zipcode':
				return 'Value must be a valid Zipcode'
			case 'url':
				return 'Value must be a valid URL'
			default:
				return 'Value must be valid'
		}
	}

	getValidator() {
		return {
			text: 'text',
			alpha: 'alpha',
			ein: 'ein',
			url: 'url',
			phone: 'phone',
			alphanumeric: 'alphanumeric',
			zipcode: 'zipcode',
			numbers: 'numbers',
			float: 'float',
			moneynonzero: 'moneynonzero',
			ssn: 'ssn',
			email: 'email',
			routing: 'routing',
			accountnumber: 'accountnumber'
		}
	}

	getUSAStates() {
		return [
			{ value: 'AL', text: 'Alabama' },
			{ value: 'AK', text: 'Alaska' },
			{ value: 'AZ', text: 'Arizona' },
			{ value: 'AR', text: 'Arkansas' },
			{ value: 'CA', text: 'California' },
			{ value: 'CO', text: 'Colorado' },
			{ value: 'CT', text: 'Connecticut' },
			{ value: 'DE', text: 'Delaware' },
			{ value: 'DC', text: 'District Of Columbia' },
			{ value: 'FL', text: 'Florida' },
			{ value: 'GA', text: 'Georgia' },
			{ value: 'HI', text: 'Hawaii' },
			{ value: 'ID', text: 'Idaho' },
			{ value: 'IL', text: 'Illinois' },
			{ value: 'IN', text: 'Indiana' },
			{ value: 'IA', text: 'Iowa' },
			{ value: 'KS', text: 'Kansas' },
			{ value: 'KY', text: 'Kentucky' },
			{ value: 'LA', text: 'Louisiana' },
			{ value: 'ME', text: 'Maine' },
			{ value: 'MD', text: 'Maryland' },
			{ value: 'MA', text: 'Massachusetts' },
			{ value: 'MI', text: 'Michigan' },
			{ value: 'MN', text: 'Minnesota' },
			{ value: 'MS', text: 'Mississippi' },
			{ value: 'MO', text: 'Missouri' },
			{ value: 'MT', text: 'Montana' },
			{ value: 'NE', text: 'Nebraska' },
			{ value: 'NV', text: 'Nevada' },
			{ value: 'NH', text: 'New Hampshire' },
			{ value: 'NJ', text: 'New Jersey' },
			{ value: 'NM', text: 'New Mexico' },
			{ value: 'NY', text: 'New York' },
			{ value: 'NC', text: 'North Carolina' },
			{ value: 'ND', text: 'North Dakota' },
			{ value: 'OH', text: 'Ohio' },
			{ value: 'OK', text: 'Oklahoma' },
			{ value: 'OR', text: 'Oregon' },
			{ value: 'PA', text: 'Pennsylvania' },
			{ value: 'RI', text: 'Rhode Island' },
			{ value: 'SC', text: 'South Carolina' },
			{ value: 'SD', text: 'South Dakota' },
			{ value: 'TN', text: 'Tennessee' },
			{ value: 'TX', text: 'Texas' },
			{ value: 'UT', text: 'Utah' },
			{ value: 'VT', text: 'Vermont' },
			{ value: 'VA', text: 'Virginia' },
			{ value: 'WA', text: 'Washington' },
			{ value: 'WV', text: 'West Virginia' },
			{ value: 'WI', text: 'Wisconsin' },
			{ value: 'WY', text: 'Wyoming' }
		]
	}
	getAllCountries() {
		return [
			{ value: 'AF', text: 'Afghanistan' },
			{ value: 'AX', text: 'Aland Islands' },
			{ value: 'AL', text: 'Albania' },
			{ value: 'DZ', text: 'Algeria' },
			{ value: 'AS', text: 'American Samoa' },
			{ value: 'AD', text: 'Andorra' },
			{ value: 'AO', text: 'Angola' },
			{ value: 'AI', text: 'Anguilla' },
			{ value: 'AQ', text: 'Antarctica' },
			{ value: 'AG', text: 'Antigua and Barbuda' },
			{ value: 'AR', text: 'Argentina' },
			{ value: 'AM', text: 'Armenia' },
			{ value: 'AW', text: 'Aruba' },
			{ value: 'AU', text: 'Australia' },
			{ value: 'AT', text: 'Austria' },
			{ value: 'AZ', text: 'Azerbaijan' },
			{ value: 'BS', text: 'Bahamas' },
			{ value: 'BH', text: 'Bahrain' },
			{ value: 'BD', text: 'Bangladesh' },
			{ value: 'BB', text: 'Barbados' },
			{ value: 'BY', text: 'Belarus' },
			{ value: 'BE', text: 'Belgium' },
			{ value: 'BZ', text: 'Belize' },
			{ value: 'BJ', text: 'Benin' },
			{ value: 'BM', text: 'Bermuda' },
			{ value: 'BT', text: 'Bhutan' },
			{ value: 'BO', text: 'Bolivia, Plurinational State of' },
			{ value: 'BQ', text: 'Bonaire, Sint Eustatius and Saba' },
			{ value: 'BA', text: 'Bosnia and Herzegovina' },
			{ value: 'BW', text: 'Botswana' },
			{ value: 'BV', text: 'Bouvet Island' },
			{ value: 'BR', text: 'Brazil' },
			{ value: 'IO', text: 'British Indian Ocean Territory' },
			{ value: 'BN', text: 'Brunei Darussalam' },
			{ value: 'BG', text: 'Bulgaria' },
			{ value: 'BF', text: 'Burkina Faso' },
			{ value: 'BI', text: 'Burundi' },
			{ value: 'KH', text: 'Cambodia' },
			{ value: 'CM', text: 'Cameroon' },
			{ value: 'CA', text: 'Canada' },
			{ value: 'CV', text: 'Cape Verde' },
			{ value: 'KY', text: 'Cayman Islands' },
			{ value: 'CF', text: 'Central African Republic' },
			{ value: 'TD', text: 'Chad' },
			{ value: 'CL', text: 'Chile' },
			{ value: 'CN', text: 'China' },
			{ value: 'CX', text: 'Christmas Island' },
			{ value: 'CC', text: 'Cocos (Keeling) Islands' },
			{ value: 'CO', text: 'Colombia' },
			{ value: 'KM', text: 'Comoros' },
			{ value: 'CG', text: 'Congo' },
			{ value: 'CD', text: 'Congo, the Democratic Republic of the' },
			{ value: 'CK', text: 'Cook Islands' },
			{ value: 'CR', text: 'Costa Rica' },
			{ value: 'CI', text: "Cote d'Ivoire" },
			{ value: 'HR', text: 'Croatia' },
			{ value: 'CU', text: 'Cuba' },
			{ value: 'CW', text: 'Curacao' },
			{ value: 'CY', text: 'Cyprus' },
			{ value: 'CZ', text: 'Czech Republic' },
			{ value: 'DK', text: 'Denmark' },
			{ value: 'DJ', text: 'Djibouti' },
			{ value: 'DM', text: 'Dominica' },
			{ value: 'DO', text: 'Dominican Republic' },
			{ value: 'EC', text: 'Ecuador' },
			{ value: 'EG', text: 'Egypt' },
			{ value: 'SV', text: 'El Salvador' },
			{ value: 'GQ', text: 'Equatorial Guinea' },
			{ value: 'ER', text: 'Eritrea' },
			{ value: 'EE', text: 'Estonia' },
			{ value: 'ET', text: 'Ethiopia' },
			{ value: 'FK', text: 'Falkland Islands (Malvinas)' },
			{ value: 'FO', text: 'Faroe Islands' },
			{ value: 'FJ', text: 'Fiji' },
			{ value: 'FI', text: 'Finland' },
			{ value: 'FR', text: 'France' },
			{ value: 'GF', text: 'French Guiana' },
			{ value: 'PF', text: 'French Polynesia' },
			{ value: 'TF', text: 'French Southern Territories' },
			{ value: 'GA', text: 'Gabon' },
			{ value: 'GM', text: 'Gambia' },
			{ value: 'GE', text: 'Georgia' },
			{ value: 'DE', text: 'Germany' },
			{ value: 'GH', text: 'Ghana' },
			{ value: 'GI', text: 'Gibraltar' },
			{ value: 'GR', text: 'Greece' },
			{ value: 'GL', text: 'Greenland' },
			{ value: 'GD', text: 'Grenada' },
			{ value: 'GP', text: 'Guadeloupe' },
			{ value: 'GU', text: 'Guam' },
			{ value: 'GT', text: 'Guatemala' },
			{ value: 'GG', text: 'Guernsey' },
			{ value: 'GN', text: 'Guinea' },
			{ value: 'GW', text: 'Guinea-Bissau' },
			{ value: 'GY', text: 'Guyana' },
			{ value: 'HT', text: 'Haiti' },
			{ value: 'HM', text: 'Heard Island and McDonald Islands' },
			{ value: 'VA', text: 'Holy See (Vatican City State)' },
			{ value: 'HN', text: 'Honduras' },
			{ value: 'HK', text: 'Hong Kong' },
			{ value: 'HU', text: 'Hungary' },
			{ value: 'IS', text: 'Iceland' },
			{ value: 'IN', text: 'India' },
			{ value: 'ID', text: 'Indonesia' },
			{ value: 'IR', text: 'Iran, Islamic Republic of' },
			{ value: 'IQ', text: 'Iraq' },
			{ value: 'IE', text: 'Ireland' },
			{ value: 'IM', text: 'Isle of Man' },
			{ value: 'IL', text: 'Israel' },
			{ value: 'IT', text: 'Italy' },
			{ value: 'JM', text: 'Jamaica' },
			{ value: 'JP', text: 'Japan' },
			{ value: 'JE', text: 'Jersey' },
			{ value: 'JO', text: 'Jordan' },
			{ value: 'KZ', text: 'Kazakhstan' },
			{ value: 'KE', text: 'Kenya' },
			{ value: 'KI', text: 'Kiribati' },
			{ value: 'KP', text: "Korea, Democratic People's Republic of" },
			{ value: 'KR', text: 'Korea, Republic of' },
			{ value: 'KW', text: 'Kuwait' },
			{ value: 'KG', text: 'Kyrgyzstan' },
			{ value: 'LA', text: "Lao People's Democratic Republic" },
			{ value: 'LV', text: 'Latvia' },
			{ value: 'LB', text: 'Lebanon' },
			{ value: 'LS', text: 'Lesotho' },
			{ value: 'LR', text: 'Liberia' },
			{ value: 'LY', text: 'Libya' },
			{ value: 'LI', text: 'Liechtenstein' },
			{ value: 'LT', text: 'Lithuania' },
			{ value: 'LU', text: 'Luxembourg' },
			{ value: 'MO', text: 'Macao' },
			{ value: 'MK', text: 'Macedonia, the former Yugoslav Republic of' },
			{ value: 'MG', text: 'Madagascar' },
			{ value: 'MW', text: 'Malawi' },
			{ value: 'MY', text: 'Malaysia' },
			{ value: 'MV', text: 'Maldives' },
			{ value: 'ML', text: 'Mali' },
			{ value: 'MT', text: 'Malta' },
			{ value: 'MH', text: 'Marshall Islands' },
			{ value: 'MQ', text: 'Martinique' },
			{ value: 'MR', text: 'Mauritania' },
			{ value: 'MU', text: 'Mauritius' },
			{ value: 'YT', text: 'Mayotte' },
			{ value: 'MX', text: 'Mexico' },
			{ value: 'FM', text: 'Micronesia, Federated States of' },
			{ value: 'MD', text: 'Moldova, Republic of' },
			{ value: 'MC', text: 'Monaco' },
			{ value: 'MN', text: 'Mongolia' },
			{ value: 'ME', text: 'Montenegro' },
			{ value: 'MS', text: 'Montserrat' },
			{ value: 'MA', text: 'Morocco' },
			{ value: 'MZ', text: 'Mozambique' },
			{ value: 'MM', text: 'Myanmar' },
			{ value: 'NA', text: 'Namibia' },
			{ value: 'NR', text: 'Nauru' },
			{ value: 'NP', text: 'Nepal' },
			{ value: 'NL', text: 'Netherlands' },
			{ value: 'NC', text: 'New Caledonia' },
			{ value: 'NZ', text: 'New Zealand' },
			{ value: 'NI', text: 'Nicaragua' },
			{ value: 'NE', text: 'Niger' },
			{ value: 'NG', text: 'Nigeria' },
			{ value: 'NU', text: 'Niue' },
			{ value: 'NF', text: 'Norfolk Island' },
			{ value: 'MP', text: 'Northern Mariana Islands' },
			{ value: 'NO', text: 'Norway' },
			{ value: 'OM', text: 'Oman' },
			{ value: 'PK', text: 'Pakistan' },
			{ value: 'PW', text: 'Palau' },
			{ value: 'PS', text: 'Palestinian Territory, Occupied' },
			{ value: 'PA', text: 'Panama' },
			{ value: 'PG', text: 'Papua New Guinea' },
			{ value: 'PY', text: 'Paraguay' },
			{ value: 'PE', text: 'Peru' },
			{ value: 'PH', text: 'Philippines' },
			{ value: 'PN', text: 'Pitcairn' },
			{ value: 'PL', text: 'Poland' },
			{ value: 'PT', text: 'Portugal' },
			{ value: 'PR', text: 'Puerto Rico' },
			{ value: 'QA', text: 'Qatar' },
			{ value: 'RE', text: 'Reunion' },
			{ value: 'RO', text: 'Romania' },
			{ value: 'RU', text: 'Russian Federation' },
			{ value: 'RW', text: 'Rwanda' },
			{ value: 'BL', text: 'Saint Barthélemy' },
			{ value: 'SH', text: 'Saint Helena, Ascension and Tristan da Cunha' },
			{ value: 'KN', text: 'Saint Kitts and Nevis' },
			{ value: 'LC', text: 'Saint Lucia' },
			{ value: 'MF', text: 'Saint Martin (French part)' },
			{ value: 'PM', text: 'Saint Pierre and Miquelon' },
			{ value: 'VC', text: 'Saint Vincent and the Grenadines' },
			{ value: 'WS', text: 'Samoa' },
			{ value: 'SM', text: 'San Marino' },
			{ value: 'ST', text: 'Sao Tome and Principe' },
			{ value: 'SA', text: 'Saudi Arabia' },
			{ value: 'SN', text: 'Senegal' },
			{ value: 'RS', text: 'Serbia' },
			{ value: 'SC', text: 'Seychelles' },
			{ value: 'SL', text: 'Sierra Leone' },
			{ value: 'SG', text: 'Singapore' },
			{ value: 'SX', text: 'Sint Maarten (Dutch part)' },
			{ value: 'SK', text: 'Slovakia' },
			{ value: 'SI', text: 'Slovenia' },
			{ value: 'SB', text: 'Solomon Islands' },
			{ value: 'SO', text: 'Somalia' },
			{ value: 'ZA', text: 'South Africa' },
			{ value: 'GS', text: 'South Georgia and the South Sandwich Islands' },
			{ value: 'SS', text: 'South Sudan' },
			{ value: 'ES', text: 'Spain' },
			{ value: 'LK', text: 'Sri Lanka' },
			{ value: 'SD', text: 'Sudan' },
			{ value: 'SR', text: 'Suriname' },
			{ value: 'SJ', text: 'Svalbard and Jan Mayen' },
			{ value: 'SZ', text: 'Swaziland' },
			{ value: 'SE', text: 'Sweden' },
			{ value: 'CH', text: 'Switzerland' },
			{ value: 'SY', text: 'Syrian Arab Republic' },
			{ value: 'TW', text: 'Taiwan, Province of China' },
			{ value: 'TJ', text: 'Tajikistan' },
			{ value: 'TZ', text: 'Tanzania, United Republic of' },
			{ value: 'TH', text: 'Thailand' },
			{ value: 'TL', text: 'Timor-Leste' },
			{ value: 'TG', text: 'Togo' },
			{ value: 'TK', text: 'Tokelau' },
			{ value: 'TO', text: 'Tonga' },
			{ value: 'TT', text: 'Trinidad and Tobago' },
			{ value: 'TN', text: 'Tunisia' },
			{ value: 'TR', text: 'Turkey' },
			{ value: 'TM', text: 'Turkmenistan' },
			{ value: 'TC', text: 'Turks and Caicos Islands' },
			{ value: 'TV', text: 'Tuvalu' },
			{ value: 'UG', text: 'Uganda' },
			{ value: 'UA', text: 'Ukraine' },
			{ value: 'AE', text: 'United Arab Emirates' },
			{ value: 'GB', text: 'United Kingdom' },
			{ value: 'US', text: 'United States' },
			{ value: 'UM', text: 'United States Minor Outlying Islands' },
			{ value: 'UY', text: 'Uruguay' },
			{ value: 'UZ', text: 'Uzbekistan' },
			{ value: 'VU', text: 'Vanuatu' },
			{ value: 'VE', text: 'Venezuela, Bolivarian Republic of' },
			{ value: 'VN', text: 'Viet Nam' },
			{ value: 'VG', text: 'Virgin Islands, British' },
			{ value: 'VI', text: 'Virgin Islands, U.S.' },
			{ value: 'WF', text: 'Wallis and Futuna' },
			{ value: 'EH', text: 'Western Sahara' },
			{ value: 'YE', text: 'Yemen' },
			{ value: 'ZM', text: 'Zambia' },
			{ value: 'ZW', text: 'Zimbabwe' }
		]
	}
	getStates(selectedCountry){
		if (selectedCountry === '') return []
		const contries = allCountries;
		const stateFilter = contries.filter((country) => country[1] === selectedCountry)[0];
		const options = stateFilter ? stateFilter[2] : [];
		const dropDownOptions = options.map((e) => {
			return { value: e[1], text: e[0] };
		});
		return dropDownOptions
	}

	getMccOptionName(id) {
		let name = ''
		let mccOptions = this.getMccOptions()
		mccOptions.forEach(function (item) {
			if (item.value === id) {
				name = item.name
			}
		})
		return name
	}

	getTimeZone(version, timeZoneParams) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let timeZone = timeZoneParams !== null && timeZoneParams !== undefined ? timeZoneParams : encryptStorage && encryptStorage.getItem('pUser') ? encryptStorage.getItem('pUser').timeZone : 0

		switch (parseInt(timeZone)) {
			case 0:
				return version === 'v1' ? 'UTC' : 'UTC-00:00'
			case -4:
				return version === 'v1' ? 'AST' : 'Atlantic Time UTC-04:00'
			case -5:
				return version === 'v1' ? 'EST' : 'Eastern Time UTC−05:00'
			case -6:
				return version === 'v1' ? 'CST' : 'Central Time UTC−06:00'
			case -7:
				return version === 'v1' ? 'MST' : 'Mountain Time UTC−07:00'
			case -8:
				return version === 'v1' ? 'PST' : 'Pacific Time UTC−08:00'
			case -9:
				return version === 'v1' ? 'AKST' : 'Alaskan Time UTC−09:00'
			case -10:
				return version === 'v1' ? 'HAST' : 'Hawaii-Aleutian Time UTC−10:00'
			default:
				return version === 'v1' ? 'UTC' : 'UTC-00:00'
		}
	}

	getIconTransactionResponse(response) {
		switch (response?.toString().toLowerCase()) {
			case '1':
				return (
					<HiCheckCircle
						type="solid"
						className="green-text icon-side-text"
					/>
				)
			default:
				return (
					<HiXCircle
						type="solid"
						className="red-text icon-side-text"
					/>
				)
		}
	}

	isDrag() {
		if (this.coordClickDown !== null && this.coordClickUp !== null && this.coordClickDown.clientX === this.coordClickUp.clientX && this.coordClickDown.clientY === this.coordClickUp.clientY) {
			return false
		}
		return true
	}

	getMccOptions() {
		return [
			{
				value: '',
				name: 'Select Industry (Merchant Category Code)'
			},
			{
				value: '0742',
				edited_description: 'Veterinary Services',
				combined_description: 'Veterinary Services',
				usda_description: 'Veterinary Services',
				name: '0742 - Veterinary Services',
				irs_reportable: 'Yes',
				id: 0
			},
			{
				value: '0763',
				edited_description: 'Agricultural Co-operatives',
				combined_description: 'Agricultural Co-operatives',
				usda_description: 'Agricultural Co-operatives',
				name: '0763 - Agricultural Cooperative',
				irs_reportable: 'Yes',
				id: 1
			},
			{
				value: '0780',
				edited_description: 'Horticultural Services, Landscaping Services',
				combined_description: 'Horticultural Services, Landscaping Services',
				usda_description: 'Horticultural Services',
				name: '0780 - Landscaping Services',
				irs_reportable: 'Yes',
				id: 2
			},
			{
				value: '1520',
				edited_description: 'General Contractors-Residential and Commercial',
				combined_description: 'General Contractors-Residential and Commercial',
				usda_description: 'General Contractors-Residential and Commercial',
				name: '1520 - General Contractors',
				irs_reportable: 'Yes',
				id: 3
			},
			{
				value: '1711',
				edited_description: 'Air Conditioning Contractors & Sales and Installation, Heating Contractors & Sales, Service, Installation',
				combined_description: 'Air Conditioning Contractors & Sales and Installation, Heating Contractors & Sales, Service, Installation',
				usda_description: 'Air Conditioning Contractors & Sales and Installation',
				name: '1711 - Heating, Plumbing, A/C',
				irs_reportable: 'Yes',
				id: 4
			},
			{
				value: '1731',
				edited_description: 'Electrical Contractors',
				combined_description: 'Electrical Contractors',
				usda_description: 'Electrical Contractors',
				name: '1731 - Electrical Contractors',
				irs_reportable: 'Yes',
				id: 5
			},
			{
				value: '1740',
				edited_description: 'Insulation & Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework and Masonry Contractors, Tile Settings Contractors',
				combined_description: 'Insulation & Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework and Masonry Contractors, Tile Settings Contractors',
				usda_description: 'Insulation & Contractors',
				name: '1740 - Masonry, Stonework, and Plaster',
				irs_reportable: 'Yes',
				id: 6
			},
			{
				value: '1750',
				edited_description: 'Carpentry Contractors',
				combined_description: 'Carpentry Contractors',
				usda_description: 'Carpentry Contractors',
				name: '1750 - Carpentry Contractors',
				irs_reportable: 'Yes',
				id: 7
			},
			{
				value: '1761',
				edited_description: 'Roofing & Contractors, Sheet Metal Work & Contractors, Siding & Contractors',
				combined_description: 'Roofing & Contractors, Sheet Metal Work & Contractors, Siding & Contractors',
				usda_description: 'Roofing - Contractors',
				name: '1761 - Roofing/Siding, Sheet Metal',
				irs_reportable: 'Yes',
				id: 8
			},
			{
				value: '1771',
				edited_description: 'Contractors & Concrete Work',
				combined_description: 'Contractors & Concrete Work',
				usda_description: 'Contractors & Concrete Work',
				name: '1771 - Concrete Work Contractors',
				irs_reportable: 'Yes',
				id: 9
			},
			{
				value: '1799',
				edited_description: 'Contractors & Special Trade, Not Elsewhere Classified',
				combined_description: 'Contractors & Special Trade, Not Elsewhere Classified',
				usda_description: 'Contractors & Special Trade, Not Elsewhere Classified',
				name: '1799 - Special Trade Contractors',
				irs_reportable: 'Yes',
				id: 10
			},
			{
				value: '2741',
				edited_description: 'Miscellaneous Publishing and Printing',
				combined_description: 'Miscellaneous Publishing and Printing',
				usda_description: 'Miscellaneous Publishing and Printing',
				name: '2741 - Miscellaneous Publishing and Printing',
				irs_reportable: 'Yes',
				id: 11
			},
			{
				value: '2791',
				edited_description: 'Typesetting, Plate Making, & Related Services',
				combined_description: 'Typesetting, Plate Making, & Related Services',
				usda_description: 'Typesetting, Plate Making, & Related Services',
				name: '2791 - Typesetting, Plate Making, and Related Services',
				irs_reportable: 'Yes',
				id: 12
			},
			{
				value: '2842',
				edited_description: 'Specialty Cleaning, Polishing, and Sanitation Preparations',
				combined_description: 'Specialty Cleaning, Polishing, and Sanitation Preparations',
				usda_description: 'Specialty Cleaning, Polishing, and Sanitation Preparations',
				name: '2842 - Specialty Cleaning',
				irs_reportable: 'Yes',
				id: 13
			},
			{
				value: '3003',
				edited_description: 'Airlines',
				combined_description: 'Airlines',
				usda_description: 'Airlines',
				name: '3003 - Airlines',
				irs_reportable: 'Yes',
				id: 17
			},
			{
				value: '4011',
				edited_description: 'Railroads',
				combined_description: 'Railroads',
				usda_description: 'Railroads',
				name: '4011 - Railroads',
				irs_reportable: 'No1.6041-3(c)',
				id: 697
			},
			{
				value: '4111',
				edited_description: 'Local/Suburban Commuter Passenger Transportation & Railroads, Feries, Local Water Transportation.',
				combined_description: 'Local/Suburban Commuter Passenger Transportation & Railroads, Feries, Local Water Transportation.',
				usda_description: 'Local/Suburban Commuter Passenger Transportation & Railroads, Feries, Local Water Transportation.',
				name: '4111 - Commuter Transport, Ferries',
				irs_reportable: 'Yes',
				id: 698
			},
			{
				value: '4112',
				edited_description: 'Passenger Railways',
				combined_description: 'Passenger Railways',
				usda_description: 'Passenger Railways',
				name: '4112 - Passenger Railways',
				irs_reportable: 'Yes',
				id: 699
			},
			{
				value: '4119',
				edited_description: 'Ambulance Services',
				combined_description: 'Ambulance Services',
				usda_description: 'Ambulance Services',
				name: '4119 - Ambulance Services',
				irs_reportable: 'Yes',
				id: 700
			},
			{
				value: '4121',
				edited_description: 'Taxicabs and Limousines',
				combined_description: 'Taxicabs and Limousines',
				usda_description: 'Taxicabs and Limousines',
				name: '4121 - Taxicabs/Limousines',
				irs_reportable: 'Yes',
				id: 701
			},
			{
				value: '4131',
				edited_description: 'Bus Lines, Including Charters, Tour Buses',
				combined_description: 'Bus Lines, Including Charters, Tour Buses',
				usda_description: 'Bus Lines, Including Charters, Tour Buses',
				name: '4131 - Bus Lines',
				irs_reportable: 'Yes',
				id: 702
			},
			{
				value: '4214',
				edited_description: 'Motor Freight Carriers, Moving and Storage Companies, Trucking & Local/Long Distance, Delivery Services & Local',
				combined_description: 'Motor Freight Carriers, Moving and Storage Companies, Trucking & Local/Long Distance, Delivery Services & Local',
				usda_description: 'Motor Freight Carriers',
				name: '4214 - Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services ',
				irs_reportable: 'No1.6041-3(c)',
				id: 703
			},
			{
				value: '4215',
				edited_description: 'Courier Services & Air or Ground, Freight forwarders',
				combined_description: 'Courier Services & Air or Ground, Freight forwarders',
				usda_description: 'Courier Services & Air or Ground',
				name: '4215 - Courier Services ',
				irs_reportable: 'Yes',
				id: 704
			},
			{
				value: '4225',
				edited_description: 'Public warehousing, Storage',
				combined_description: 'Public warehousing, Storage',
				usda_description: 'Warehousing, Public',
				name: '4225 - Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage ',
				irs_reportable: 'No1.6041-3(c)',
				id: 705
			},
			{
				value: '4411',
				edited_description: 'Cruise and Steamship Lines',
				combined_description: 'Cruise and Steamship Lines',
				usda_description: 'Cruise Lines',
				name: '4411 - Cruise Lines',
				irs_reportable: 'Yes',
				id: 706
			},
			{
				value: '4457',
				edited_description: 'Boat Rentals and Leases',
				combined_description: 'Boat Rentals and Leases',
				usda_description: 'Boat Rentals and Leases',
				name: '4457 - Boat Rentals and Leases',
				irs_reportable: 'Yes',
				id: 707
			},
			{
				value: '4468',
				edited_description: 'Marinas, Marine Service, and Supplies',
				combined_description: 'Marinas, Marine Service, and Supplies',
				usda_description: 'Marinas, Marine Service, and Supplies',
				name: '4468 - Marinas, Service and Supplies',
				irs_reportable: 'Yes',
				id: 708
			},
			{
				value: '4511',
				edited_description: 'Airlines, Air Carriers ( not listed elsewhere)',
				combined_description: 'Airlines, Air Carriers ( not listed elsewhere)',
				usda_description: 'Airlines, Air Carriers ( not listed elsewhere)',
				name: '4511 - Airlines, Air Carriers',
				irs_reportable: 'Yes',
				id: 709
			},
			{
				value: '4582',
				edited_description: 'Airports, Airport Terminals, Flying Fields',
				combined_description: 'Airports, Airport Terminals, Flying Fields',
				usda_description: 'Airports, Airport Terminals',
				name: '4582 - Airports, Flying Fields',
				irs_reportable: 'Yes',
				id: 710
			},
			{
				value: '4722',
				edited_description: 'Travel Agencies and Tour Operations',
				combined_description: 'Travel Agencies and Tour Operations',
				usda_description: 'Travel Agencies and Tour Operations',
				name: '4722 - Travel Agencies, Tour Operators',
				irs_reportable: 'Yes',
				id: 711
			},
			{
				value: '4784',
				edited_description: 'Toll and Bridge Fees',
				combined_description: 'Toll and Bridge Fees',
				usda_description: 'Toll and Bridge Fees',
				name: '4784 - Tolls/Bridge Fees',
				irs_reportable: 'No1.6041-3(c)',
				id: 713
			},
			{
				value: '4789',
				edited_description: 'Transportation Services, Not elsewhere classified)',
				combined_description: 'Transportation Services, Not elsewhere classified)',
				usda_description: 'Transportation Services, Not elsewhere classified)',
				name: '4789 - Transportation Services (Not Elsewhere Classified)',
				irs_reportable: 'Yes',
				id: 714
			},
			{
				value: '4812',
				edited_description: 'Telecommunications Equipment including telephone sales',
				combined_description: 'Telecommunications Equipment including telephone sales',
				usda_description: 'Telecommunications Equipment including telephone sales',
				name: '4812 - Telecommunication Equipment and Telephone Sales',
				irs_reportable: 'No1.6041-3(c)',
				id: 715
			},
			{
				value: '4814',
				edited_description: 'Fax services, Telecommunication Services',
				combined_description: 'Fax services, Telecommunication Services',
				usda_description: 'Fax services',
				name: '4814 - Telecommunication Services',
				irs_reportable: 'No1.6041-3(c)',
				id: 716
			},
			{
				value: '4816',
				edited_description: 'Computer Network Services',
				combined_description: 'Computer Network Services',
				usda_description: 'Computer Network Services',
				name: '4816 - Computer Network Services',
				irs_reportable: 'No1.6041-3(c)',
				id: 718
			},
			{
				value: '4821',
				edited_description: 'Telegraph services',
				combined_description: 'Telegraph services',
				usda_description: 'Telegraph services',
				name: '4821 - Telegraph Services',
				irs_reportable: 'No1.6041-3(c)',
				id: 719
			},
			{
				value: '4829',
				edited_description: 'Money Orders & Wire Transfer',
				combined_description: 'Money Orders & Wire Transfer',
				usda_description: 'Money Orders & Wire Transfer',
				name: '4829 - Wires, Money Orders',
				irs_reportable: 'No1.6041-3(c)',
				id: 720
			},
			{
				value: '4899',
				edited_description: 'Cable and other pay television (previously Cable Services)',
				combined_description: 'Cable and other pay television (previously Cable Services)',
				usda_description: 'Cable and other pay television (previously Cable Services)',
				name: '4899 - Cable, Satellite, and Other Pay Television and Radio',
				irs_reportable: 'No1.6041-3(c)',
				id: 721
			},
			{
				value: '4900',
				edited_description: 'Electric, Gas, Sanitary and Water Utilities',
				combined_description: 'Electric, Gas, Sanitary and Water Utilities',
				usda_description: 'Electric, Gas, Sanitary and Water Utilities',
				name: '4900 - Utilities ',
				irs_reportable: 'No1.6041-3(c)',
				id: 722
			},
			{
				value: '5013',
				edited_description: 'Motor vehicle supplies and new parts',
				combined_description: 'Motor vehicle supplies and new parts',
				usda_description: 'Motor vehicle supplies and new parts',
				name: '5013 - Motor Vehicle Supplies and New Parts',
				irs_reportable: 'No1.6041-3(c)',
				id: 723
			},
			{
				value: '5021',
				edited_description: 'Office and Commercial Furniture',
				combined_description: 'Office and Commercial Furniture',
				usda_description: 'Office and Commercial Furniture',
				name: '5021 - Office and Commercial Furniture',
				irs_reportable: 'No1.6041-3(c)',
				id: 724
			},
			{
				value: '5039',
				edited_description: 'Construction Materials, Not Elsewhere Classified',
				combined_description: 'Construction Materials, Not Elsewhere Classified',
				usda_description: 'Construction Materials, Not Elsewhere Classified',
				name: '5039 - Construction Materials (Not Elsewhere Classified)',
				irs_reportable: 'No1.6041-3(c)',
				id: 725
			},
			{
				value: '5044',
				edited_description: 'Office, Photographic, Photocopy, and Microfilm Equipment',
				combined_description: 'Office, Photographic, Photocopy, and Microfilm Equipment',
				usda_description: 'Office, Photographic, Photocopy, and Microfilm Equipment',
				name: '5044 - Photographic, Photocopy, Microfilm Equipment, and Supplies',
				irs_reportable: 'No1.6041-3(c)',
				id: 726
			},
			{
				value: '5045',
				edited_description: 'Computers, Computer Peripheral Equipment, Software',
				combined_description: 'Computers, Computer Peripheral Equipment, Software',
				usda_description: 'Computers, Computer Peripheral Equipment, Software',
				name: '5045 - Computers, Peripherals, and Software',
				irs_reportable: 'No1.6041-3(c)',
				id: 727
			},
			{
				value: '5046',
				edited_description: 'Commercial Equipment, Not Elsewhere Classified',
				combined_description: 'Commercial Equipment, Not Elsewhere Classified',
				usda_description: 'Commercial Equipment, Not Elsewhere Classified',
				name: '5046 - Commercial Equipment (Not Elsewhere Classified)',
				irs_reportable: 'No1.6041-3(c)',
				id: 728
			},
			{
				value: '5047',
				edited_description: 'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
				combined_description: 'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
				usda_description: 'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
				name: '5047 - Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies',
				irs_reportable: 'No1.6041-3(c)',
				id: 729
			},
			{
				value: '5051',
				edited_description: 'Metal Service Centers and Offices',
				combined_description: 'Metal Service Centers and Offices',
				usda_description: 'Metal Service Centers and Offices',
				name: '5051 - Metal Service Centers',
				irs_reportable: 'No1.6041-3(c)',
				id: 730
			},
			{
				value: '5065',
				edited_description: 'Electrical Parts and Equipment',
				combined_description: 'Electrical Parts and Equipment',
				usda_description: 'Electrical Parts and Equipment',
				name: '5065 - Electrical Parts and Equipment',
				irs_reportable: 'No1.6041-3(c)',
				id: 731
			},
			{
				value: '5072',
				edited_description: 'Hardware Equipment and Supplies',
				combined_description: 'Hardware Equipment and Supplies',
				usda_description: 'Hardware Equipment and Supplies',
				name: '5072 - Hardware, Equipment, and Supplies',
				irs_reportable: 'No1.6041-3(c)',
				id: 732
			},
			{
				value: '5074',
				edited_description: 'Plumbing and Heating Equipment and Supplies',
				combined_description: 'Plumbing and Heating Equipment and Supplies',
				usda_description: 'Plumbing and Heating Equipment and Supplies',
				name: '5074 - Plumbing, Heating Equipment, and Supplies',
				irs_reportable: 'No1.6041-3(c)',
				id: 733
			},
			{
				value: '5085',
				edited_description: 'Industrial Supplies, Not Elsewhere Classified',
				combined_description: 'Industrial Supplies, Not Elsewhere Classified',
				usda_description: 'Industrial Supplies, Not Elsewhere Classified',
				name: '5085 - Industrial Supplies (Not Elsewhere Classified)',
				irs_reportable: 'No1.6041-3(c)',
				id: 734
			},
			{
				value: '5094',
				edited_description: 'Precious Stones and Metals, Watches and Jewelry',
				combined_description: 'Precious Stones and Metals, Watches and Jewelry',
				usda_description: 'Precious Stones and Metals, Watches and Jewelry',
				name: '5094 - Precious Stones and Metals, Watches and Jewelry',
				irs_reportable: 'No1.6041-3(c)',
				id: 735
			},
			{
				value: '5099',
				edited_description: 'Durable Goods, Not Elsewhere Classified',
				combined_description: 'Durable Goods, Not Elsewhere Classified',
				usda_description: 'Durable Goods, Not Elsewhere Classified',
				name: '5099 - Durable Goods (Not Elsewhere Classified)',
				irs_reportable: 'No1.6041-3(c)',
				id: 736
			},
			{
				value: '5111',
				edited_description: 'Stationery, Office Supplies, Printing, and Writing Paper',
				combined_description: 'Stationery, Office Supplies, Printing, and Writing Paper',
				usda_description: 'Stationery, Office Supplies, Printing, and Writing Paper',
				name: '5111 - Stationary, Office Supplies, Printing and Writing Paper',
				irs_reportable: 'No1.6041-3(c)',
				id: 737
			},
			{
				value: '5122',
				edited_description: 'Drugs, Drug Proprietors, and Druggist&s Sundries',
				combined_description: 'Drugs, Drug Proprietors, and Druggist&s Sundries',
				usda_description: 'Drugs, Drug Proprietors, and Druggist&s Sundries',
				name: '5122 - Drugs, Drug Proprietaries, and Druggist Sundries',
				irs_reportable: 'No1.6041-3(c)',
				id: 738
			},
			{
				value: '5131',
				edited_description: 'Piece Goods, Notions, and Other Dry Goods',
				combined_description: 'Piece Goods, Notions, and Other Dry Goods',
				usda_description: 'Piece Goods, Notions, and Other Dry Goods',
				name: '5131 - Piece Goods, Notions, and Other Dry Goods',
				irs_reportable: 'No1.6041-3(c)',
				id: 739
			},
			{
				value: '5137',
				edited_description: "Men's Women's and Children&s Uniforms and Commercial Clothing",
				combined_description: "Men's Women's and Children&s Uniforms and Commercial Clothing",
				usda_description: "Men's Women's and Children&s Uniforms and Commercial Clothing",
				name: '5137 - Uniforms, Commercial Clothing',
				irs_reportable: 'No1.6041-3(c)',
				id: 740
			},
			{
				value: '5139',
				edited_description: 'Commercial Footwear',
				combined_description: 'Commercial Footwear',
				usda_description: 'Commercial Footwear',
				name: '5139 - Commercial Footwear',
				irs_reportable: 'No1.6041-3(c)',
				id: 741
			},
			{
				value: '5169',
				edited_description: 'Chemicals and Allied Products, Not Elsewhere Classified',
				combined_description: 'Chemicals and Allied Products, Not Elsewhere Classified',
				usda_description: 'Chemicals and Allied Products, Not Elsewhere Classified',
				name: '5169 - Chemicals and Allied Products (Not Elsewhere Classified)',
				irs_reportable: 'No1.6041-3(c)',
				id: 742
			},
			{
				value: '5172',
				edited_description: 'Petroleum and Petroleum Products',
				combined_description: 'Petroleum and Petroleum Products',
				usda_description: 'Petroleum and Petroleum Products',
				name: '5172 - Petroleum and Petroleum Products',
				irs_reportable: 'No1.6041-3(c)',
				id: 743
			},
			{
				value: '5192',
				edited_description: 'Books, Periodicals, and Newspapers',
				combined_description: 'Books, Periodicals, and Newspapers',
				usda_description: 'Books, Periodicals, and Newspapers',
				name: '5192 - Books, Periodicals, and Newspapers',
				irs_reportable: 'No1.6041-3(c)',
				id: 744
			},
			{
				value: '5193',
				edited_description: 'Florists& Supplies, Nursery Stock and Flowers',
				combined_description: 'Florists& Supplies, Nursery Stock and Flowers',
				usda_description: 'Florists& Supplies, Nursery Stock and Flowers',
				name: '5193 - Florists Supplies, Nursery Stock, and Flowers',
				irs_reportable: 'No1.6041-3(c)',
				id: 745
			},
			{
				value: '5198',
				edited_description: 'Paints, Varnishes, and Supplies',
				combined_description: 'Paints, Varnishes, and Supplies',
				usda_description: 'Paints, Varnishes, and Supplies',
				name: '5198 - Paints, Varnishes, and Supplies',
				irs_reportable: 'No1.6041-3(c)',
				id: 746
			},
			{
				value: '5199',
				edited_description: 'Non-durable Goods, Not Elsewhere Classified',
				combined_description: 'Non-durable Goods, Not Elsewhere Classified',
				usda_description: 'Non-durable Goods, Not Elsewhere Classified',
				name: '5199 - Nondurable Goods (Not Elsewhere Classified)',
				irs_reportable: 'No1.6041-3(c)',
				id: 747
			},
			{
				value: '5200',
				edited_description: 'Home Supply Warehouse Stores',
				combined_description: 'Home Supply Warehouse Stores',
				usda_description: 'Home Supply Warehouse Stores',
				name: '5200 - Home Supply Warehouse Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 748
			},
			{
				value: '5211',
				edited_description: 'Lumber and Building Materials Stores',
				combined_description: 'Lumber and Building Materials Stores',
				usda_description: 'Lumber and Building Materials Stores',
				name: '5211 - Lumber, Building Materials Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 749
			},
			{
				value: '5231',
				edited_description: 'Glass, Paint, and Wallpaper Stores',
				combined_description: 'Glass, Paint, and Wallpaper Stores',
				usda_description: 'Wallpaper Stores',
				name: '5231 - Glass, Paint, and Wallpaper Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 750
			},
			{
				value: '5251',
				edited_description: 'Hardware Stores',
				combined_description: 'Hardware Stores',
				usda_description: 'Hardware Stores',
				name: '5251 - Hardware Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 751
			},
			{
				value: '5261',
				edited_description: 'Nurseries & Lawn and Garden Supply Store',
				combined_description: 'Nurseries & Lawn and Garden Supply Store',
				usda_description: 'Nurseries & Lawn and Garden Supply Store',
				name: '5261 - Nurseries, Lawn and Garden Supply Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 752
			},
			{
				value: '5271',
				edited_description: 'Mobile Home Dealers',
				combined_description: 'Mobile Home Dealers',
				usda_description: 'Mobile Home Dealers',
				name: '5271 - Mobile Home Dealers',
				irs_reportable: 'No1.6041-3(c)',
				id: 753
			},
			{
				value: '5300',
				edited_description: 'Wholesale Clubs',
				combined_description: 'Wholesale Clubs',
				usda_description: 'Wholesale Clubs',
				name: '5300 - Wholesale Clubs',
				irs_reportable: 'No1.6041-3(c)',
				id: 754
			},
			{
				value: '5309',
				edited_description: 'Duty Free Store',
				combined_description: 'Duty Free Store',
				usda_description: 'Duty Free Store',
				name: '5309 - Duty Free Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 755
			},
			{
				value: '5310',
				edited_description: 'Discount Stores',
				combined_description: 'Discount Stores',
				usda_description: 'Discount Stores',
				name: '5310 - Discount Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 756
			},
			{
				value: '5311',
				edited_description: 'Department Stores',
				combined_description: 'Department Stores',
				usda_description: 'Department Stores',
				name: '5311 - Department Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 757
			},
			{
				value: '5331',
				edited_description: 'Variety Stores',
				combined_description: 'Variety Stores',
				usda_description: 'Variety Stores',
				name: '5331 - Variety Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 758
			},
			{
				value: '5399',
				edited_description: 'Misc. General Merchandise',
				combined_description: 'Misc. General Merchandise',
				usda_description: 'Misc. General Merchandise',
				name: '5399 - Miscellaneous General Merchandise',
				irs_reportable: 'No1.6041-3(c)',
				id: 759
			},
			{
				value: '5411',
				edited_description: 'Grocery Stores, Supermarkets',
				combined_description: 'Grocery Stores, Supermarkets',
				usda_description: 'Grocery Stores',
				name: '5411 - Grocery Stores, Supermarkets',
				irs_reportable: 'No1.6041-3(c)',
				id: 760
			},
			{
				value: '5422',
				edited_description: 'Meat Provisioners & Freezer and Locker',
				combined_description: 'Meat Provisioners & Freezer and Locker',
				usda_description: 'Meat Provisioners & Freezer and Locker',
				name: '5422 - Freezer and Locker Meat Provisioners',
				irs_reportable: 'No1.6041-3(c)',
				id: 761
			},
			{
				value: '5441',
				edited_description: 'Candy, Nut, and Confectionery Stores',
				combined_description: 'Candy, Nut, and Confectionery Stores',
				usda_description: 'Candy Stores',
				name: '5441 - Candy, Nut, and Confectionery Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 762
			},
			{
				value: '5451',
				edited_description: 'Dairy Products Stores',
				combined_description: 'Dairy Products Stores',
				usda_description: 'Dairy Products Stores',
				name: '5451 - Dairy Products Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 763
			},
			{
				value: '5462',
				edited_description: 'Bakeries',
				combined_description: 'Bakeries',
				usda_description: 'Bakeries',
				name: '5462 - Bakeries',
				irs_reportable: 'No1.6041-3(c)',
				id: 764
			},
			{
				value: '5499',
				edited_description: 'Misc. Food Stores & Convenience Stores and Specialty Markets',
				combined_description: 'Misc. Food Stores & Convenience Stores and Specialty Markets',
				usda_description: 'Misc. Food Stores & Convenience Stores and Specialty Markets',
				name: '5499 - Miscellaneous Food Stores - Convenience Stores and Specialty Markets',
				irs_reportable: 'No1.6041-3(c)',
				id: 765
			},
			{
				value: '5511',
				edited_description: 'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
				combined_description: 'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
				usda_description: 'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
				name: '5511 - Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing ',
				irs_reportable: 'No1.6041-3(c)',
				id: 766
			},
			{
				value: '5521',
				edited_description: 'Automobile and Truck Dealers (Used Only)',
				combined_description: 'Automobile and Truck Dealers (Used Only)',
				usda_description: 'Automobile and Truck Dealers (Used Only)',
				name: '5521 - Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing ',
				irs_reportable: 'No1.6041-3(c)',
				id: 767
			},
			{
				value: '5531',
				edited_description: 'Automobile Supply Stores',
				combined_description: 'Automobile Supply Stores',
				usda_description: 'Automobile Supply Stores',
				name: '5531 - Auto and Home Supply Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 768
			},
			{
				value: '5532',
				edited_description: 'Automotive Tire Stores',
				combined_description: 'Automotive Tire Stores',
				usda_description: 'Automotive Tire Stores',
				name: '5532 - Automotive Tire Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 769
			},
			{
				value: '5533',
				edited_description: 'Automotive Parts, Accessories Stores',
				combined_description: 'Automotive Parts, Accessories Stores',
				usda_description: 'Automotive Parts, Accessories Stores',
				name: '5533 - Automotive Parts and Accessories Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 770
			},
			{
				value: '5541',
				edited_description: 'Service Stations ( with or without ancillary services)',
				combined_description: 'Service Stations ( with or without ancillary services)',
				usda_description: 'Service Stations ( with or without ancillary services)',
				name: '5541 - Service Stations ',
				irs_reportable: 'No1.6041-3(c)',
				id: 771
			},
			{
				value: '5542',
				edited_description: 'Automated Fuel Dispensers',
				combined_description: 'Automated Fuel Dispensers',
				usda_description: 'Automated Fuel Dispensers',
				name: '5542 - Automated Fuel Dispensers',
				irs_reportable: 'No1.6041-3(c)',
				id: 772
			},
			{
				value: '5551',
				edited_description: 'Boat Dealers',
				combined_description: 'Boat Dealers',
				usda_description: 'Boat Dealers',
				name: '5551 - Boat Dealers',
				irs_reportable: 'No1.6041-3(c)',
				id: 773
			},
			{
				value: '5561',
				edited_description: 'Recreational and Utility Trailers, Camp Dealers',
				combined_description: 'Recreational and Utility Trailers, Camp Dealers',
				usda_description: 'Recreational and Utility Trailers, Camp Dealers',
				name: '5561 - Motorcycle Shops, Dealers',
				irs_reportable: 'No1.6041-3(c)',
				id: 774
			},
			{
				value: '5571',
				edited_description: 'Motorcycle Dealers',
				combined_description: 'Motorcycle Dealers',
				usda_description: 'Motorcycle Dealers',
				name: '5571 - Motorcycle Shops and Dealers',
				irs_reportable: 'No1.6041-3(c)',
				id: 775
			},
			{
				value: '5592',
				edited_description: 'Motor Home Dealers',
				combined_description: 'Motor Home Dealers',
				usda_description: 'Motor Home Dealers',
				name: '5592 - Motor Homes Dealers',
				irs_reportable: 'No1.6041-3(c)',
				id: 776
			},
			{
				value: '5598',
				edited_description: 'Snowmobile Dealers',
				combined_description: 'Snowmobile Dealers',
				usda_description: 'Snowmobile Dealers',
				name: '5598 - Snowmobile Dealers',
				irs_reportable: 'No1.6041-3(c)',
				id: 777
			},
			{
				value: '5599',
				edited_description: 'Miscellaneous Auto Dealers ',
				combined_description: 'Miscellaneous Auto Dealers ',
				usda_description: 'Miscellaneous Auto Dealers',
				name: '5599 - Miscellaneous Auto Dealers ',
				irs_reportable: 'No1.6041-3(c)',
				id: 778
			},
			{
				value: '5611',
				edited_description: "Men's and Boy&s Clothing and Accessories Stores",
				combined_description: "Men's and Boy&s Clothing and Accessories Stores",
				usda_description: "Men's and Boy&s Clothing and Accessories Stores",
				name: "5611 - Men's and Boy&s Clothing and Accessories Stores",
				irs_reportable: 'No1.6041-3(c)',
				id: 779
			},
			{
				value: '5621',
				edited_description: "Women's Ready-to-Wear Stores",
				combined_description: "Women's Ready-to-Wear Stores",
				usda_description: "Women's Ready-to-Wear Stores",
				name: "5621 - Women's Ready-To-Wear Stores",
				irs_reportable: 'No1.6041-3(c)',
				id: 780
			},
			{
				value: '5631',
				edited_description: "Women's Accessory and Specialty Shops",
				combined_description: "Women's Accessory and Specialty Shops",
				usda_description: "Women's Accessory and Specialty Shops",
				name: "5631 - Women's Accessory and Specialty Shops",
				irs_reportable: 'No1.6041-3(c)',
				id: 781
			},
			{
				value: '5641',
				edited_description: 'Children&s and Infant&s Wear Stores',
				combined_description: 'Children&s and Infant&s Wear Stores',
				usda_description: 'Children&s and Infant&s Wear Stores',
				name: '5641 - Children&s and Infant&s Wear Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 782
			},
			{
				value: '5651',
				edited_description: 'Family Clothing Stores',
				combined_description: 'Family Clothing Stores',
				usda_description: 'Family Clothing Stores',
				name: '5651 - Family Clothing Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 783
			},
			{
				value: '5655',
				edited_description: 'Sports Apparel, Riding Apparel Stores',
				combined_description: 'Sports Apparel, Riding Apparel Stores',
				usda_description: 'Sports Apparel, Riding Apparel Stores',
				name: '5655 - Sports and Riding Apparel Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 784
			},
			{
				value: '5661',
				edited_description: 'Shoe Stores',
				combined_description: 'Shoe Stores',
				usda_description: 'Shoe Stores',
				name: '5661 - Shoe Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 785
			},
			{
				value: '5681',
				edited_description: 'Furriers and Fur Shops',
				combined_description: 'Furriers and Fur Shops',
				usda_description: 'Furriers and Fur Shops',
				name: '5681 - Furriers and Fur Shops',
				irs_reportable: 'No1.6041-3(c)',
				id: 786
			},
			{
				value: '5691',
				edited_description: "Men's and Women's Clothing Stores",
				combined_description: "Men's and Women's Clothing Stores",
				usda_description: "Men's and Women's Clothing Stores",
				name: "5691 - Men's, Women's Clothing Stores",
				irs_reportable: 'No1.6041-3(c)',
				id: 787
			},
			{
				value: '5697',
				edited_description: 'Tailors, Seamstress, Mending, and Alterations',
				combined_description: 'Tailors, Seamstress, Mending, and Alterations',
				usda_description: 'Tailors, Seamstress, Mending, and Alterations',
				name: '5697 - Tailors, Alterations',
				irs_reportable: 'Yes',
				id: 788
			},
			{
				value: '5698',
				edited_description: 'Wig and Toupee Stores',
				combined_description: 'Wig and Toupee Stores',
				usda_description: 'Wig and Toupee Stores',
				name: '5698 - Wig and Toupee Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 789
			},
			{
				value: '5699',
				edited_description: 'Miscellaneous Apparel and Accessory Shops',
				combined_description: 'Miscellaneous Apparel and Accessory Shops',
				usda_description: 'Miscellaneous Apparel and Accessory Shops',
				name: '5699 - Miscellaneous Apparel and Accessory Shops',
				irs_reportable: 'No1.6041-3(c)',
				id: 790
			},
			{
				value: '5712',
				edited_description: 'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
				combined_description: 'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
				usda_description: 'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
				name: '5712 - Furniture, Home Furnishings, and Equipment Stores, Except Appliances',
				irs_reportable: 'No1.6041-3(c)',
				id: 791
			},
			{
				value: '5713',
				edited_description: 'Floor Covering Stores',
				combined_description: 'Floor Covering Stores',
				usda_description: 'Floor Covering Stores',
				name: '5713 - Floor Covering Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 792
			},
			{
				value: '5714',
				edited_description: 'Drapery, Window Covering and Upholstery Stores',
				combined_description: 'Drapery, Window Covering and Upholstery Stores',
				usda_description: 'Drapery, Window Covering and Upholstery Stores',
				name: '5714 - Drapery, Window Covering, and Upholstery Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 793
			},
			{
				value: '5718',
				edited_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
				combined_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
				usda_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
				name: '5718 - Fireplace, Fireplace Screens, and Accessories Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 794
			},
			{
				value: '5719',
				edited_description: 'Miscellaneous Home Furnishing Specialty Stores',
				combined_description: 'Miscellaneous Home Furnishing Specialty Stores',
				usda_description: 'Miscellaneous Home Furnishing Specialty Stores',
				name: '5719 - Miscellaneous Home Furnishing Specialty Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 795
			},
			{
				value: '5722',
				edited_description: 'Household Appliance Stores',
				combined_description: 'Household Appliance Stores',
				usda_description: 'Household Appliance Stores',
				name: '5722 - Household Appliance Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 796
			},
			{
				value: '5732',
				edited_description: 'Electronic Sales',
				combined_description: 'Electronic Sales',
				usda_description: 'Electronic Sales',
				name: '5732 - Electronics Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 797
			},
			{
				value: '5733',
				edited_description: 'Music Stores, Musical Instruments, Piano Sheet Music',
				combined_description: 'Music Stores, Musical Instruments, Piano Sheet Music',
				usda_description: 'Music Stores, Musical Instruments, Piano Sheet Music',
				name: '5733 - Music Stores-Musical Instruments, Pianos, and Sheet Music',
				irs_reportable: 'No1.6041-3(c)',
				id: 798
			},
			{
				value: '5734',
				edited_description: 'Computer Software Stores',
				combined_description: 'Computer Software Stores',
				usda_description: 'Computer Software Stores',
				name: '5734 - Computer Software Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 799
			},
			{
				value: '5735',
				edited_description: 'Record Shops',
				combined_description: 'Record Shops',
				usda_description: 'Record Shops',
				name: '5735 - Record Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 800
			},
			{
				value: '5811',
				edited_description: 'Caterers',
				combined_description: 'Caterers',
				usda_description: 'Caterers',
				name: '5811 - Caterers',
				irs_reportable: 'Yes',
				id: 801
			},
			{
				value: '5812',
				edited_description: 'Eating places and Restaurants',
				combined_description: 'Eating places and Restaurants',
				usda_description: 'Eating places and Restaurants',
				name: '5812 - Eating Places, Restaurants',
				irs_reportable: 'No1.6041-3(c)',
				id: 802
			},
			{
				value: '5813',
				edited_description: 'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
				combined_description: 'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
				usda_description: 'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
				name: '5813 - Drinking Places',
				irs_reportable: 'No1.6041-3(c)',
				id: 803
			},
			{
				value: '5814',
				edited_description: 'Fast Food Restaurants',
				combined_description: 'Fast Food Restaurants',
				usda_description: 'Fast Food Restaurants',
				name: '5814 - Fast Food Restaurants',
				irs_reportable: 'No1.6041-3(c)',
				id: 804
			},
			{
				value: '5815',
				edited_description: 'Digital Goods: Media, Books, Movies, Music',
				combined_description: 'Digital Goods: Media, Books, Movies, Music',
				usda_description: 'Digital Goods: Media, Books, Movies, Music',
				name: '5815 - ',
				irs_reportable: '',
				id: 805
			},
			{
				value: '5816',
				edited_description: 'Digital Goods: Games',
				combined_description: 'Digital Goods: Games',
				usda_description: 'Digital Goods: Games',
				name: '5816 - ',
				irs_reportable: '',
				id: 806
			},
			{
				value: '5817',
				edited_description: 'Digital Goods: Applications (Excludes Games)',
				combined_description: 'Digital Goods: Applications (Excludes Games)',
				usda_description: 'Digital Goods: Applications (Excludes Games)',
				name: '5817 - ',
				irs_reportable: '',
				id: 807
			},
			{
				value: '5818',
				edited_description: 'Digital Goods: Large Digital Goods Merchant',
				combined_description: 'Digital Goods: Large Digital Goods Merchant',
				usda_description: 'Digital Goods: Large Digital Goods Merchant',
				name: '5818 - ',
				irs_reportable: '',
				id: 808
			},
			{
				value: '5832',
				edited_description: 'Antique Shops & Sales, Repairs, and Restoration Services',
				combined_description: 'Antique Shops & Sales, Repairs, and Restoration Services',
				usda_description: 'Antique Shops & Sales, Repairs, and Restoration Services',
				name: '5832 - ',
				irs_reportable: '',
				id: 809
			},
			{
				value: '5912',
				edited_description: 'Drug Stores and Pharmacies',
				combined_description: 'Drug Stores and Pharmacies',
				usda_description: 'Drug Stores and Pharmacies',
				name: '5912 - Drug Stores and Pharmacies',
				irs_reportable: 'No1.6041-3(c)',
				id: 810
			},
			{
				value: '5921',
				edited_description: 'Package Stores & Beer, Wine, and Liquor',
				combined_description: 'Package Stores & Beer, Wine, and Liquor',
				usda_description: 'Package Stores & Beer, Wine, and Liquor',
				name: '5921 - Package Stores-Beer, Wine, and Liquor',
				irs_reportable: 'No1.6041-3(c)',
				id: 811
			},
			{
				value: '5931',
				edited_description: 'Used Merchandise and Secondhand Stores',
				combined_description: 'Used Merchandise and Secondhand Stores',
				usda_description: 'Used Merchandise and Secondhand Stores',
				name: '5931 - Used Merchandise and Secondhand Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 812
			},
			{
				value: '5932',
				edited_description: 'Antique Shops',
				combined_description: 'Antique Shops',
				usda_description: 'Antique Shops',
				name: '5932 - Antique Shops',
				irs_reportable: 'No1.6041-3(c)',
				id: 813
			},
			{
				value: '5933',
				edited_description: 'Pawn Shops and Salvage Yards',
				combined_description: 'Pawn Shops and Salvage Yards',
				usda_description: 'Pawn Shops and Salvage Yards',
				name: '5933 - Pawn Shops',
				irs_reportable: 'No1.6041-3(c)',
				id: 814
			},
			{
				value: '5935',
				edited_description: 'Wrecking and Salvage Yards',
				combined_description: 'Wrecking and Salvage Yards',
				usda_description: 'Wrecking and Salvage Yards',
				name: '5935 - Wrecking and Salvage Yards',
				irs_reportable: 'Yes',
				id: 815
			},
			{
				value: '5937',
				edited_description: 'Antique Reproductions',
				combined_description: 'Antique Reproductions',
				usda_description: 'Antique Reproductions',
				name: '5937 - Antique Reproductions',
				irs_reportable: 'No1.6041-3(c)',
				id: 816
			},
			{
				value: '5940',
				edited_description: 'Bicycle Shops & Sales and Service',
				combined_description: 'Bicycle Shops & Sales and Service',
				usda_description: 'Bicycle Shops & Sales and Service',
				name: '5940 - Bicycle Shops',
				irs_reportable: 'No1.6041-3(c)',
				id: 817
			},
			{
				value: '5941',
				edited_description: 'Sporting Goods Stores',
				combined_description: 'Sporting Goods Stores',
				usda_description: 'Sporting Goods Stores',
				name: '5941 - Sporting Goods Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 818
			},
			{
				value: '5942',
				edited_description: 'Book Stores',
				combined_description: 'Book Stores',
				usda_description: 'Book Stores',
				name: '5942 - Book Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 819
			},
			{
				value: '5943',
				edited_description: 'Stationery Stores, Office and School Supply Stores',
				combined_description: 'Stationery Stores, Office and School Supply Stores',
				usda_description: 'Stationery Stores, Office and School Supply Stores',
				name: '5943 - Stationery Stores, Office, and School Supply Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 820
			},
			{
				value: '5944',
				edited_description: 'Watch, Clock, Jewelry, and Silverware Stores',
				combined_description: 'Watch, Clock, Jewelry, and Silverware Stores',
				usda_description: 'Watch, Clock, Jewelry, and Silverware Stores',
				name: '5944 - Jewelry Stores, Watches, Clocks, and Silverware Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 821
			},
			{
				value: '5945',
				edited_description: 'Hobby, Toy, and Game Shops',
				combined_description: 'Hobby, Toy, and Game Shops',
				usda_description: 'Hobby, Toy, and Game Shops',
				name: '5945 - Hobby, Toy, and Game Shops',
				irs_reportable: 'No1.6041-3(c)',
				id: 822
			},
			{
				value: '5946',
				edited_description: 'Camera and Photographic Supply Stores',
				combined_description: 'Camera and Photographic Supply Stores',
				usda_description: 'Camera and Photographic Supply Stores',
				name: '5946 - Camera and Photographic Supply Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 823
			},
			{
				value: '5947',
				edited_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
				combined_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
				usda_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
				name: '5947 - Gift, Card, Novelty, and Souvenir Shops',
				irs_reportable: 'No1.6041-3(c)',
				id: 824
			},
			{
				value: '5948',
				edited_description: 'Leather Goods Stores',
				combined_description: 'Leather Goods Stores',
				usda_description: 'Leather Goods Stores',
				name: '5948 - Luggage and Leather Goods Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 825
			},
			{
				value: '5949',
				edited_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
				combined_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
				usda_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
				name: '5949 - Sewing, Needlework, Fabric, and Piece Goods Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 826
			},
			{
				value: '5950',
				edited_description: 'Glassware/Crystal Stores',
				combined_description: 'Glassware/Crystal Stores',
				usda_description: 'Glassware/Crystal Stores',
				name: '5950 - Glassware, Crystal Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 827
			},
			{
				value: '5960',
				edited_description: 'Direct Marketing- Insurance Service',
				combined_description: 'Direct Marketing- Insurance Service',
				usda_description: 'Direct Marketing- Insurance Service',
				name: '5960 - Direct Marketing - Insurance Services',
				irs_reportable: 'Yes',
				id: 828
			},
			{
				value: '5962',
				edited_description: 'Direct Marketing & Travel Related Arrangements Services',
				combined_description: 'Direct Marketing & Travel Related Arrangements Services',
				usda_description: 'Direct Marketing & Travel Related Arrangements Services',
				name: '5962 - Direct Marketing - Travel',
				irs_reportable: 'Yes',
				id: 830
			},
			{
				value: '5963',
				edited_description: 'Door-to-Door Sales',
				combined_description: 'Door-to-Door Sales',
				usda_description: 'Door-to-Door Sales',
				name: '5963 - Door-To-Door Sales',
				irs_reportable: 'No1.6041-3(c)',
				id: 831
			},
			{
				value: '5964',
				edited_description: 'Direct Marketing & Catalog Merchant',
				combined_description: 'Direct Marketing & Catalog Merchant',
				usda_description: 'Direct Marketing & Catalog Merchant',
				name: '5964 - Direct Marketing - Catalog Merchant',
				irs_reportable: 'No1.6041-3(c)',
				id: 832
			},
			{
				value: '5965',
				edited_description: 'Direct Marketing & Catalog and Catalog and Retail Merchant',
				combined_description: 'Direct Marketing & Catalog and Catalog and Retail Merchant',
				usda_description: 'Direct Marketing & Catalog and Catalog and Retail Merchant',
				name: '5965 - Direct Marketing - Combination Catalog and Retail Merchant',
				irs_reportable: 'No1.6041-3(c)',
				id: 833
			},
			{
				value: '5966',
				edited_description: 'Direct Marketing- Outbound Telemarketing Merchant',
				combined_description: 'Direct Marketing- Outbound Telemarketing Merchant',
				usda_description: 'Direct Marketing- Outbound Telemarketing Merchant',
				name: '5966 - Direct Marketing - Outbound Tele',
				irs_reportable: 'No1.6041-3(c)',
				id: 834
			},
			{
				value: '5967',
				edited_description: 'Direct Marketing & Inbound Teleservices Merchant',
				combined_description: 'Direct Marketing & Inbound Teleservices Merchant',
				usda_description: 'Direct Marketing & Inbound Teleservices Merchant',
				name: '5967 - Direct Marketing - Inbound Tele',
				irs_reportable: 'No1.6041-3(c)',
				id: 835
			},
			{
				value: '5968',
				edited_description: 'Direct Marketing & Continuity/Subscription Merchant',
				combined_description: 'Direct Marketing & Continuity/Subscription Merchant',
				usda_description: 'Direct Marketing & Continuity/Subscription Merchant',
				name: '5968 - Direct Marketing - Subscription',
				irs_reportable: 'No1.6041-3(c)',
				id: 836
			},
			{
				value: '5969',
				edited_description: 'Direct Marketing & Not Elsewhere Classified',
				combined_description: 'Direct Marketing & Not Elsewhere Classified',
				usda_description: 'Direct Marketing & Not Elsewhere Classified',
				name: '5969 - Direct Marketing - Other ',
				irs_reportable: 'No1.6041-3(c)',
				id: 837
			},
			{
				value: '5970',
				edited_description: 'Artist&s Supply and Craft Shops',
				combined_description: 'Artist&s Supply and Craft Shops',
				usda_description: 'Artist&s Supply and Craft Shops',
				name: '5970 - Artist&s Supply and Craft Shops',
				irs_reportable: 'No1.6041-3(c)',
				id: 838
			},
			{
				value: '5971',
				edited_description: 'Art Dealers and Galleries',
				combined_description: 'Art Dealers and Galleries',
				usda_description: 'Art Dealers and Galleries',
				name: '5971 - Art Dealers and Galleries',
				irs_reportable: 'No1.6041-3(c)',
				id: 839
			},
			{
				value: '5972',
				edited_description: 'Stamp and Coin Stores & Philatelic and Numismatic Supplies',
				combined_description: 'Stamp and Coin Stores & Philatelic and Numismatic Supplies',
				usda_description: 'Stamp and Coin Stores & Philatelic and Numismatic Supplies',
				name: '5972 - Stamp and Coin Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 840
			},
			{
				value: '5973',
				edited_description: 'Religious Goods Stores',
				combined_description: 'Religious Goods Stores',
				usda_description: 'Religious Goods Stores',
				name: '5973 - Religious Goods Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 841
			},
			{
				value: '5975',
				edited_description: 'Hearing Aids & Sales, Service, and Supply Stores',
				combined_description: 'Hearing Aids & Sales, Service, and Supply Stores',
				usda_description: 'Hearing Aids & Sales, Service, and Supply Stores',
				name: '5975 - Hearing Aids Sales and Supplies',
				irs_reportable: 'No1.6041-3(c)',
				id: 842
			},
			{
				value: '5976',
				edited_description: 'Orthopedic Goods Prosthetic Devices',
				combined_description: 'Orthopedic Goods Prosthetic Devices',
				usda_description: 'Orthopedic Goods Prosthetic Devices',
				name: '5976 - Orthopedic Goods - Prosthetic Devices',
				irs_reportable: 'No1.6041-3(c)',
				id: 843
			},
			{
				value: '5977',
				edited_description: 'Cosmetic Stores',
				combined_description: 'Cosmetic Stores',
				usda_description: 'Cosmetic Stores',
				name: '5977 - Cosmetic Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 844
			},
			{
				value: '5978',
				edited_description: 'Typewriter Stores & Sales, Rental, Service',
				combined_description: 'Typewriter Stores & Sales, Rental, Service',
				usda_description: 'Typewriter Stores & Sales, Rental, Service',
				name: '5978 - Typewriter Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 845
			},
			{
				value: '5983',
				edited_description: 'Fuel & Fuel Oil, Wood, Coal, Liquefied Petroleum',
				combined_description: 'Fuel & Fuel Oil, Wood, Coal, Liquefied Petroleum',
				usda_description: 'Fuel & Fuel Oil, Wood, Coal, Liquefied Petroleum',
				name: '5983 - Fuel Dealers (Non Automotive)',
				irs_reportable: 'No1.6041-3(c)',
				id: 846
			},
			{
				value: '5992',
				edited_description: 'Florists',
				combined_description: 'Florists',
				usda_description: 'Florists',
				name: '5992 - Florists',
				irs_reportable: 'No1.6041-3(c)',
				id: 847
			},
			{
				value: '5993',
				edited_description: 'Cigar Stores and Stands',
				combined_description: 'Cigar Stores and Stands',
				usda_description: 'Cigar Stores and Stands',
				name: '5993 - Cigar Stores and Stands',
				irs_reportable: 'No1.6041-3(c)',
				id: 848
			},
			{
				value: '5994',
				edited_description: 'News Dealers and Newsstands',
				combined_description: 'News Dealers and Newsstands',
				usda_description: 'News Dealers and Newsstands',
				name: '5994 - News Dealers and Newsstands',
				irs_reportable: 'No1.6041-3(c)',
				id: 849
			},
			{
				value: '5995',
				edited_description: 'Pet Shops, Pet Foods, and Supplies Stores',
				combined_description: 'Pet Shops, Pet Foods, and Supplies Stores',
				usda_description: 'Pet Shops, Pet Foods, and Supplies Stores',
				name: '5995 - Pet Shops, Pet Food, and Supplies',
				irs_reportable: 'No1.6041-3(c)',
				id: 850
			},
			{
				value: '5996',
				edited_description: 'Swimming Pools & Sales, Service, and Supplies',
				combined_description: 'Swimming Pools & Sales, Service, and Supplies',
				usda_description: 'Swimming Pools & Sales, Service, and Supplies',
				name: '5996 - Swimming Pools Sales',
				irs_reportable: 'No1.6041-3(c)',
				id: 851
			},
			{
				value: '5997',
				edited_description: 'Electric Razor Stores & Sales and Service',
				combined_description: 'Electric Razor Stores & Sales and Service',
				usda_description: 'Electric Razor Stores & Sales and Service',
				name: '5997 - Electric Razor Stores',
				irs_reportable: 'No1.6041-3(c)',
				id: 852
			},
			{
				value: '5998',
				edited_description: 'Tent and Awning Shops',
				combined_description: 'Tent and Awning Shops',
				usda_description: 'Tent and Awning Shops',
				name: '5998 - Tent and Awning Shops',
				irs_reportable: 'No1.6041-3(c)',
				id: 853
			},
			{
				value: '5999',
				edited_description: 'Miscellaneous and Specialty Retail Stores',
				combined_description: 'Miscellaneous and Specialty Retail Stores',
				usda_description: 'Miscellaneous and Specialty Retail Stores',
				name: '5999 - Miscellaneous Specialty Retail',
				irs_reportable: 'No1.6041-3(c)',
				id: 854
			},
			{
				value: '6010',
				edited_description: 'Financial Institutions & Manual Cash Disbursements',
				combined_description: 'Financial Institutions & Manual Cash Disbursements',
				usda_description: 'Financial Institutions & Manual Cash Disbursements',
				name: '6010 - Manual Cash Disburse',
				irs_reportable: 'No1.6041-3(c)',
				id: 855
			},
			{
				value: '6011',
				edited_description: 'Financial Institutions & Manual Cash Disbursements',
				combined_description: 'Financial Institutions & Manual Cash Disbursements',
				usda_description: 'Financial Institutions & Manual Cash Disbursements',
				name: '6011 - Automated Cash Disburse',
				irs_reportable: 'No1.6041-3(c)',
				id: 856
			},
			{
				value: '6012',
				edited_description: 'Financial Institutions & Merchandise and Services',
				combined_description: 'Financial Institutions & Merchandise and Services',
				usda_description: 'Financial Institutions & Merchandise and Services',
				name: '6012 - Financial Institutions',
				irs_reportable: 'Yes',
				id: 857
			},
			{
				value: '6051',
				edited_description: 'Non-Financial Institutions & Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques',
				combined_description: 'Non-Financial Institutions & Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques',
				usda_description: 'Non-Financial Institutions & Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques',
				name: '6051 - Non-FI, Money Orders',
				irs_reportable: 'No1.6041-3(c)',
				id: 858
			},
			{
				value: '6211',
				edited_description: 'Security Brokers/Dealers',
				combined_description: 'Security Brokers/Dealers',
				usda_description: 'Security Brokers/Dealers',
				name: '6211 - Security Brokers/Dealers',
				irs_reportable: 'Yes',
				id: 859
			},
			{
				value: '6300',
				edited_description: 'Insurance Sales, Underwriting, and Premiums',
				combined_description: 'Insurance Sales, Underwriting, and Premiums',
				usda_description: 'Insurance Sales, Underwriting, and Premiums',
				name: '6300 - Insurance Underwriting, Premiums',
				irs_reportable: 'No1.6041-3(c)',
				id: 860
			},
			{
				value: '6399',
				edited_description: 'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
				combined_description: 'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
				usda_description: 'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
				name: '6399 - Insurance - Default',
				irs_reportable: 'No1.6041-3(c)',
				id: 862
			},
			{
				value: '6513',
				edited_description: 'Real Estate Agents and Managers - Rentals',
				combined_description: 'Real Estate Agents and Managers - Rentals',
				usda_description: 'Real Estate Agents and Managers - Rentals',
				name: '6513 - Real Estate Agents and Managers - Rentals',
				irs_reportable: 'Yes',
				id: 863
			},
			{
				value: '7011',
				edited_description: 'Lodging & Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)',
				combined_description: 'Lodging & Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)',
				usda_description: 'Lodging & Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)',
				name: '7011 - Hotels, Motels, and Resorts',
				irs_reportable: 'Yes',
				id: 864
			},
			{
				value: '7012',
				edited_description: 'Timeshares',
				combined_description: 'Timeshares',
				usda_description: 'Timeshares',
				name: '7012 - Timeshares',
				irs_reportable: 'Yes',
				id: 865
			},
			{
				value: '7032',
				edited_description: 'Sporting and Recreational Camps',
				combined_description: 'Sporting and Recreational Camps',
				usda_description: 'Sporting and Recreational Camps',
				name: '7032 - Sporting/Recreation Camps',
				irs_reportable: 'Yes',
				id: 866
			},
			{
				value: '7033',
				edited_description: 'Trailer Parks and Camp Grounds',
				combined_description: 'Trailer Parks and Camp Grounds',
				usda_description: 'Trailer Parks and Camp Grounds',
				name: '7033 - Trailer Parks, Campgrounds',
				irs_reportable: 'Yes',
				id: 867
			},
			{
				value: '7210',
				edited_description: 'Laundry, Cleaning, and Garment Services',
				combined_description: 'Laundry, Cleaning, and Garment Services',
				usda_description: 'Laundry, Cleaning, and Garment Services',
				name: '7210 - Laundry, Cleaning Services',
				irs_reportable: 'Yes',
				id: 868
			},
			{
				value: '7211',
				edited_description: 'Laundry & Family and Commercial',
				combined_description: 'Laundry & Family and Commercial',
				usda_description: 'Laundry & Family and Commercial',
				name: '7211 - Laundries ',
				irs_reportable: 'Yes',
				id: 869
			},
			{
				value: '7216',
				edited_description: 'Dry Cleaners',
				combined_description: 'Dry Cleaners',
				usda_description: 'Dry Cleaners',
				name: '7216 - Dry Cleaners',
				irs_reportable: 'Yes',
				id: 870
			},
			{
				value: '7217',
				edited_description: 'Carpet and Upholstery Cleaning',
				combined_description: 'Carpet and Upholstery Cleaning',
				usda_description: 'Carpet and Upholstery Cleaning',
				name: '7217 - Carpet/Upholstery Cleaning',
				irs_reportable: 'Yes',
				id: 871
			},
			{
				value: '7221',
				edited_description: 'Photographic Studios',
				combined_description: 'Photographic Studios',
				usda_description: 'Photographic Studios',
				name: '7221 - Photographic Studios',
				irs_reportable: 'Yes',
				id: 872
			},
			{
				value: '7230',
				edited_description: 'Barber and Beauty Shops',
				combined_description: 'Barber and Beauty Shops',
				usda_description: 'Barber and Beauty Shops',
				name: '7230 - Barber and Beauty Shops',
				irs_reportable: 'Yes',
				id: 873
			},
			{
				value: '7251',
				edited_description: 'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
				combined_description: 'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
				usda_description: 'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
				name: '7251 - Shoe Repair/Hat Cleaning',
				irs_reportable: 'Yes',
				id: 874
			},
			{
				value: '7261',
				edited_description: 'Funeral Service and Crematories',
				combined_description: 'Funeral Service and Crematories',
				usda_description: 'Funeral Service and Crematories',
				name: '7261 - Funeral Services, Crematories',
				irs_reportable: 'Yes',
				id: 875
			},
			{
				value: '7273',
				edited_description: 'Dating and Escort Services',
				combined_description: 'Dating and Escort Services',
				usda_description: 'Dating and Escort Services',
				name: '7273 - Dating/Escort Services',
				irs_reportable: 'Yes',
				id: 876
			},
			{
				value: '7276',
				edited_description: 'Tax Preparation Service',
				combined_description: 'Tax Preparation Service',
				usda_description: 'Tax Preparation Service',
				name: '7276 - Tax Preparation Services',
				irs_reportable: 'Yes',
				id: 877
			},
			{
				value: '7277',
				edited_description: 'Counseling Service & Debt, Marriage, Personal',
				combined_description: 'Counseling Service & Debt, Marriage, Personal',
				usda_description: 'Counseling Service & Debt, Marriage, Personal',
				name: '7277 - Counseling Services',
				irs_reportable: 'Yes',
				id: 878
			},
			{
				value: '7278',
				edited_description: 'Buying/Shopping Services, Clubs',
				combined_description: 'Buying/Shopping Services, Clubs',
				usda_description: 'Buying/Shopping Services, Clubs',
				name: '7278 - Buying/Shopping Services',
				irs_reportable: 'Yes',
				id: 879
			},
			{
				value: '7296',
				edited_description: 'Clothing Rental & Costumes, Formal Wear, Uniforms',
				combined_description: 'Clothing Rental & Costumes, Formal Wear, Uniforms',
				usda_description: 'Clothing Rental & Costumes, Formal Wear, Uniforms',
				name: '7296 - Clothing Rental ',
				irs_reportable: 'Yes',
				id: 880
			},
			{
				value: '7297',
				edited_description: 'Massage Parlors',
				combined_description: 'Massage Parlors',
				usda_description: 'Massage Parlors',
				name: '7297 - Massage Parlors',
				irs_reportable: 'Yes',
				id: 881
			},
			{
				value: '7298',
				edited_description: 'Health and Beauty Shops',
				combined_description: 'Health and Beauty Shops',
				usda_description: 'Health and Beauty Shops',
				name: '7298 - Health and Beauty Spas',
				irs_reportable: 'Yes',
				id: 882
			},
			{
				value: '7299',
				edited_description: 'Miscellaneous Personal Services ( not elsewhere classifies)',
				combined_description: 'Miscellaneous Personal Services ( not elsewhere classifies)',
				usda_description: 'Miscellaneous Personal Services ( not elsewhere classifies)',
				name: '7299 - Miscellaneous General Services',
				irs_reportable: 'Yes',
				id: 883
			},
			{
				value: '7311',
				edited_description: 'Advertising Services',
				combined_description: 'Advertising Services',
				usda_description: 'Advertising Services',
				name: '7311 - Advertising Services',
				irs_reportable: 'Yes',
				id: 884
			},
			{
				value: '7321',
				edited_description: 'Consumer Credit Reporting Agencies',
				combined_description: 'Consumer Credit Reporting Agencies',
				usda_description: 'Consumer Credit Reporting Agencies',
				name: '7321 - Credit Reporting Agencies',
				irs_reportable: 'Yes',
				id: 885
			},
			{
				value: '7332',
				edited_description: 'Blueprinting and Photocopying Services',
				combined_description: 'Blueprinting and Photocopying Services',
				usda_description: 'Blueprinting and Photocopying Services',
				name: '7332 - ',
				irs_reportable: '',
				id: 886
			},
			{
				value: '7333',
				edited_description: 'Commercial Photography, Art and Graphics',
				combined_description: 'Commercial Photography, Art and Graphics',
				usda_description: 'Commercial Photography, Art and Graphics',
				name: '7333 - Commercial Photography, Art and Graphics',
				irs_reportable: 'Yes',
				id: 887
			},
			{
				value: '7338',
				edited_description: 'Quick Copy, Reproduction and Blueprinting Services',
				combined_description: 'Quick Copy, Reproduction and Blueprinting Services',
				usda_description: 'Quick Copy, Reproduction and Blueprinting Services',
				name: '7338 - Quick Copy, Repro, and Blueprint',
				irs_reportable: 'Yes',
				id: 888
			},
			{
				value: '7339',
				edited_description: 'Stenographic and Secretarial Support Services',
				combined_description: 'Stenographic and Secretarial Support Services',
				usda_description: 'Stenographic and Secretarial Support Services',
				name: '7339 - Secretarial Support Services',
				irs_reportable: 'Yes',
				id: 889
			},
			{
				value: '7342',
				edited_description: 'Exterminating and Disinfecting Services',
				combined_description: 'Exterminating and Disinfecting Services',
				usda_description: 'Exterminating and Disinfecting Services',
				name: '7342 - Exterminating Services',
				irs_reportable: 'Yes',
				id: 890
			},
			{
				value: '7349',
				edited_description: 'Cleaning and Maintenance, Janitorial Services',
				combined_description: 'Cleaning and Maintenance, Janitorial Services',
				usda_description: 'Cleaning and Maintenance, Janitorial Services',
				name: '7349 - Cleaning and Maintenance',
				irs_reportable: 'Yes',
				id: 891
			},
			{
				value: '7361',
				edited_description: 'Employment Agencies, Temporary Help Services',
				combined_description: 'Employment Agencies, Temporary Help Services',
				usda_description: 'Employment Agencies, Temporary Help Services',
				name: '7361 - Employment/Temp Agencies',
				irs_reportable: 'Yes',
				id: 892
			},
			{
				value: '7372',
				edited_description: 'Computer Programming, Integrated Systems Design and Data Processing Services',
				combined_description: 'Computer Programming, Integrated Systems Design and Data Processing Services',
				usda_description: 'Computer Programming, Integrated Systems Design and Data Processing Services',
				name: '7372 - Computer Programming',
				irs_reportable: 'Yes',
				id: 893
			},
			{
				value: '7375',
				edited_description: 'Information Retrieval Services',
				combined_description: 'Information Retrieval Services',
				usda_description: 'Information Retrieval Services',
				name: '7375 - Information Retrieval Services',
				irs_reportable: 'Yes',
				id: 894
			},
			{
				value: '7379',
				edited_description: 'Computer Maintenance and Repair Services, Not Elsewhere Classified',
				combined_description: 'Computer Maintenance and Repair Services, Not Elsewhere Classified',
				usda_description: 'Computer Maintenance and Repair Services, Not Elsewhere Classified',
				name: '7379 - Computer Repair',
				irs_reportable: 'Yes',
				id: 895
			},
			{
				value: '7392',
				edited_description: 'Management, Consulting, and Public Relations Services',
				combined_description: 'Management, Consulting, and Public Relations Services',
				usda_description: 'Management, Consulting, and Public Relations Services',
				name: '7392 - Consulting, Public Relations',
				irs_reportable: 'Yes',
				id: 896
			},
			{
				value: '7393',
				edited_description: 'Protective and Security Services & Including Armored Carsand Guard Dogs',
				combined_description: 'Protective and Security Services & Including Armored Carsand Guard Dogs',
				usda_description: 'Protective and Security Services & Including Armored Carsand Guard Dogs',
				name: '7393 - Detective Agencies',
				irs_reportable: 'Yes',
				id: 897
			},
			{
				value: '7394',
				edited_description: 'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
				combined_description: 'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
				usda_description: 'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
				name: '7394 - Equipment Rental ',
				irs_reportable: 'Yes',
				id: 898
			},
			{
				value: '7395',
				edited_description: 'Photofinishing Laboratories, Photo Developing',
				combined_description: 'Photofinishing Laboratories, Photo Developing',
				usda_description: 'Photofinishing Laboratories, Photo Developing',
				name: '7395 - Photo Developing',
				irs_reportable: 'Yes',
				id: 899
			},
			{
				value: '7399',
				edited_description: 'Business Services, Not Elsewhere Classified',
				combined_description: 'Business Services, Not Elsewhere Classified',
				usda_description: 'Business Services, Not Elsewhere Classified',
				name: '7399 - Miscellaneous Business Services ',
				irs_reportable: 'Yes',
				id: 900
			},
			{
				value: '7511',
				edited_description: 'Truck Stop',
				combined_description: 'Truck Stop',
				usda_description: 'Truck Stop',
				name: '7511 - Truck Stop',
				irs_reportable: 'Yes',
				id: 901
			},
			{
				value: '7512',
				edited_description: 'Car Rental Companies ( Not Listed Below)',
				combined_description: 'Car Rental Companies ( Not Listed Below)',
				usda_description: 'Car Rental Companies ( Not Listed Below)',
				name: '7512 - Car Rental Agencies',
				irs_reportable: 'Yes',
				id: 902
			},
			{
				value: '7513',
				edited_description: 'Truck and Utility Trailer Rentals',
				combined_description: 'Truck and Utility Trailer Rentals',
				usda_description: 'Truck and Utility Trailer Rentals',
				name: '7513 - Truck/Utility Trailer Rentals',
				irs_reportable: 'Yes',
				id: 903
			},
			{
				value: '7519',
				edited_description: 'Motor Home and Recreational Vehicle Rentals',
				combined_description: 'Motor Home and Recreational Vehicle Rentals',
				usda_description: 'Motor Home and Recreational Vehicle Rentals',
				name: '7519 - Recreational Vehicle Rentals',
				irs_reportable: 'Yes',
				id: 904
			},
			{
				value: '7523',
				edited_description: 'Automobile Parking Lots and Garages',
				combined_description: 'Automobile Parking Lots and Garages',
				usda_description: 'Automobile Parking Lots and Garages',
				name: '7523 - Parking Lots, Garages',
				irs_reportable: 'Yes',
				id: 905
			},
			{
				value: '7531',
				edited_description: 'Automotive Body Repair Shops',
				combined_description: 'Automotive Body Repair Shops',
				usda_description: 'Automotive Body Repair Shops',
				name: '7531 - Auto Body Repair Shops',
				irs_reportable: 'Yes',
				id: 906
			},
			{
				value: '7534',
				edited_description: 'Tire Re-treading and Repair Shops',
				combined_description: 'Tire Re-treading and Repair Shops',
				usda_description: 'Tire Re-treading and Repair Shops',
				name: '7534 - Tire Retreading and Repair',
				irs_reportable: 'Yes',
				id: 907
			},
			{
				value: '7535',
				edited_description: 'Paint Shops & Automotive',
				combined_description: 'Paint Shops & Automotive',
				usda_description: 'Paint Shops & Automotive',
				name: '7535 - Auto Paint Shops',
				irs_reportable: 'Yes',
				id: 908
			},
			{
				value: '7538',
				edited_description: 'Automotive Service Shops',
				combined_description: 'Automotive Service Shops',
				usda_description: 'Automotive Service Shops',
				name: '7538 - Auto Service Shops',
				irs_reportable: 'Yes',
				id: 909
			},
			{
				value: '7542',
				edited_description: 'Car Washes',
				combined_description: 'Car Washes',
				usda_description: 'Car Washes',
				name: '7542 - Car Washes',
				irs_reportable: 'Yes',
				id: 910
			},
			{
				value: '7549',
				edited_description: 'Towing Services',
				combined_description: 'Towing Services',
				usda_description: 'Towing Services',
				name: '7549 - Towing Services',
				irs_reportable: 'Yes',
				id: 911
			},
			{
				value: '7622',
				edited_description: 'Radio Repair Shops',
				combined_description: 'Radio Repair Shops',
				usda_description: 'Radio Repair Shops',
				name: '7622 - Electronics Repair Shops',
				irs_reportable: 'Yes',
				id: 912
			},
			{
				value: '7623',
				edited_description: 'Air Conditioning and Refrigeration Repair Shops',
				combined_description: 'Air Conditioning and Refrigeration Repair Shops',
				usda_description: 'Air Conditioning and Refrigeration Repair Shops',
				name: '7623 - A/C, Refrigeration Repair',
				irs_reportable: 'Yes',
				id: 913
			},
			{
				value: '7629',
				edited_description: 'Electrical And Small Appliance Repair Shops',
				combined_description: 'Electrical And Small Appliance Repair Shops',
				usda_description: 'Electrical And Small Appliance Repair Shops',
				name: '7629 - Small Appliance Repair',
				irs_reportable: 'Yes',
				id: 914
			},
			{
				value: '7631',
				edited_description: 'Watch, Clock, and Jewelry Repair',
				combined_description: 'Watch, Clock, and Jewelry Repair',
				usda_description: 'Watch, Clock, and Jewelry Repair',
				name: '7631 - Watch/Jewelry Repair',
				irs_reportable: 'Yes',
				id: 915
			},
			{
				value: '7641',
				edited_description: 'Furniture, Furniture Repair, and Furniture Refinishing',
				combined_description: 'Furniture, Furniture Repair, and Furniture Refinishing',
				usda_description: 'Furniture, Furniture Repair, and Furniture Refinishing',
				name: '7641 - Furniture Repair, Refinishing',
				irs_reportable: 'Yes',
				id: 916
			},
			{
				value: '7692',
				edited_description: 'Welding Repair',
				combined_description: 'Welding Repair',
				usda_description: 'Welding Repair',
				name: '7692 - Welding Repair',
				irs_reportable: 'Yes',
				id: 917
			},
			{
				value: '7699',
				edited_description: 'Repair Shops and Related Services &Miscellaneous',
				combined_description: 'Repair Shops and Related Services &Miscellaneous',
				usda_description: 'Repair Shops and Related Services &Miscellaneous',
				name: '7699 - Miscellaneous Repair Shops',
				irs_reportable: 'Yes',
				id: 918
			},
			{
				value: '7829',
				edited_description: 'Motion Pictures and Video Tape Production and Distribution',
				combined_description: 'Motion Pictures and Video Tape Production and Distribution',
				usda_description: 'Motion Pictures and Video Tape Production and Distribution',
				name: '7829 - Picture/Video Production',
				irs_reportable: 'Yes',
				id: 922
			},
			{
				value: '7832',
				edited_description: 'Motion Picture Theaters',
				combined_description: 'Motion Picture Theaters',
				usda_description: 'Motion Picture Theaters',
				name: '7832 - Motion Picture Theaters',
				irs_reportable: 'Yes',
				id: 923
			},
			{
				value: '7841',
				edited_description: 'Video Tape Rental Stores',
				combined_description: 'Video Tape Rental Stores',
				usda_description: 'Video Tape Rental Stores',
				name: '7841 - Video Tape Rental Stores',
				irs_reportable: 'Yes',
				id: 924
			},
			{
				value: '7911',
				edited_description: 'Dance Halls, Studios and Schools',
				combined_description: 'Dance Halls, Studios and Schools',
				usda_description: 'Dance Halls, Studios and Schools',
				name: '7911 - Dance Hall, Studios, Schools',
				irs_reportable: 'Yes',
				id: 925
			},
			{
				value: '7922',
				edited_description: 'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
				combined_description: 'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
				usda_description: 'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
				name: '7922 - Theatrical Ticket Agencies',
				irs_reportable: 'Yes',
				id: 926
			},
			{
				value: '7929',
				edited_description: 'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
				combined_description: 'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
				usda_description: 'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
				name: '7929 - Bands, Orchestras',
				irs_reportable: 'Yes',
				id: 927
			},
			{
				value: '7932',
				edited_description: 'Billiard and Pool Establishments',
				combined_description: 'Billiard and Pool Establishments',
				usda_description: 'Billiard and Pool Establishments',
				name: '7932 - Billiard/Pool Establishments',
				irs_reportable: 'Yes',
				id: 928
			},
			{
				value: '7933',
				edited_description: 'Bowling Alleys',
				combined_description: 'Bowling Alleys',
				usda_description: 'Bowling Alleys',
				name: '7933 - Bowling Alleys',
				irs_reportable: 'Yes',
				id: 929
			},
			{
				value: '7941',
				edited_description: 'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
				combined_description: 'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
				usda_description: 'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
				name: '7941 - Sports Clubs/Fields',
				irs_reportable: 'Yes',
				id: 930
			},
			{
				value: '7991',
				edited_description: 'Tourist Attractions and Exhibits',
				combined_description: 'Tourist Attractions and Exhibits',
				usda_description: 'Tourist Attractions and Exhibits',
				name: '7991 - Tourist Attractions and Exhibits',
				irs_reportable: 'Yes',
				id: 931
			},
			{
				value: '7992',
				edited_description: 'Golf Courses & Public',
				combined_description: 'Golf Courses & Public',
				usda_description: 'Golf Courses & Public',
				name: '7992 - Golf Courses - Public',
				irs_reportable: 'Yes',
				id: 932
			},
			{
				value: '7993',
				edited_description: 'Video Amusement Game Supplies',
				combined_description: 'Video Amusement Game Supplies',
				usda_description: 'Video Amusement Game Supplies',
				name: '7993 - Video Amusement Game Supplies',
				irs_reportable: 'No1.6041-3(c)',
				id: 933
			},
			{
				value: '7994',
				edited_description: 'Video Game Arcades/Establishments',
				combined_description: 'Video Game Arcades/Establishments',
				usda_description: 'Video Game Arcades/Establishments',
				name: '7994 - Video Game Arcades',
				irs_reportable: 'Yes',
				id: 934
			},
			{
				value: '7995',
				edited_description: 'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
				combined_description: 'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
				usda_description: 'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
				name: '7995 - Betting/Casino Gambling',
				irs_reportable: 'Yes',
				id: 935
			},
			{
				value: '7996',
				edited_description: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
				combined_description: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
				usda_description: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
				name: '7996 - Amusement Parks/Carnivals',
				irs_reportable: 'Yes',
				id: 936
			},
			{
				value: '7997',
				edited_description: 'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
				combined_description: 'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
				usda_description: 'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
				name: '7997 - Country Clubs',
				irs_reportable: 'Yes',
				id: 937
			},
			{
				value: '7998',
				edited_description: 'Aquariums, Sea-aquariums, Dolphinariums',
				combined_description: 'Aquariums, Sea-aquariums, Dolphinariums',
				usda_description: 'Aquariums, Sea-aquariums, Dolphinariums',
				name: '7998 - Aquariums',
				irs_reportable: 'Yes',
				id: 938
			},
			{
				value: '7999',
				edited_description: 'Recreation Services (Not Elsewhere Classified)',
				combined_description: 'Recreation Services (Not Elsewhere Classified)',
				usda_description: 'Recreation Services (Not Elsewhere Classified)',
				name: '7999 - Miscellaneous Recreation Services',
				irs_reportable: 'Yes',
				id: 939
			},
			{
				value: '8011',
				edited_description: 'Doctors and Physicians (Not Elsewhere Classified)',
				combined_description: 'Doctors and Physicians (Not Elsewhere Classified)',
				usda_description: 'Doctors and Physicians (Not Elsewhere Classified)',
				name: '8011 - Doctors',
				irs_reportable: 'Yes',
				id: 940
			},
			{
				value: '8021',
				edited_description: 'Dentists and Orthodontists',
				combined_description: 'Dentists and Orthodontists',
				usda_description: 'Dentists and Orthodontists',
				name: '8021 - Dentists, Orthodontists',
				irs_reportable: 'Yes',
				id: 941
			},
			{
				value: '8031',
				edited_description: 'Osteopaths',
				combined_description: 'Osteopaths',
				usda_description: 'Osteopaths',
				name: '8031 - Osteopaths',
				irs_reportable: 'Yes',
				id: 942
			},
			{
				value: '8041',
				edited_description: 'Chiropractors',
				combined_description: 'Chiropractors',
				usda_description: 'Chiropractors',
				name: '8041 - Chiropractors',
				irs_reportable: 'Yes',
				id: 943
			},
			{
				value: '8042',
				edited_description: 'Optometrists and Ophthalmologists',
				combined_description: 'Optometrists and Ophthalmologists',
				usda_description: 'Optometrists and Ophthalmologists',
				name: '8042 - Optometrists, Ophthalmologist',
				irs_reportable: 'Yes',
				id: 944
			},
			{
				value: '8043',
				edited_description: 'Opticians, Opticians Goods and Eyeglasses',
				combined_description: 'Opticians, Opticians Goods and Eyeglasses',
				usda_description: 'Opticians, Opticians Goods and Eyeglasses',
				name: '8043 - Opticians, Eyeglasses',
				irs_reportable: 'Yes',
				id: 945
			},
			{
				value: '8044',
				edited_description: 'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
				combined_description: 'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
				usda_description: 'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
				name: '8044 - ',
				irs_reportable: '',
				id: 946
			},
			{
				value: '8049',
				edited_description: 'Podiatrists and Chiropodists',
				combined_description: 'Podiatrists and Chiropodists',
				usda_description: 'Podiatrists and Chiropodists',
				name: '8049 - Chiropodists, Podiatrists',
				irs_reportable: 'Yes',
				id: 947
			},
			{
				value: '8050',
				edited_description: 'Nursing and Personal Care Facilities',
				combined_description: 'Nursing and Personal Care Facilities',
				usda_description: 'Nursing and Personal Care Facilities',
				name: '8050 - Nursing/Personal Care ',
				irs_reportable: 'Yes',
				id: 948
			},
			{
				value: '8062',
				edited_description: 'Hospitals',
				combined_description: 'Hospitals',
				usda_description: 'Hospitals',
				name: '8062 - Hospitals',
				irs_reportable: 'Yes',
				id: 949
			},
			{
				value: '8071',
				edited_description: 'Medical and Dental Laboratories',
				combined_description: 'Medical and Dental Laboratories',
				usda_description: 'Medical and Dental Laboratories',
				name: '8071 - Medical and Dental Labs',
				irs_reportable: 'Yes',
				id: 950
			},
			{
				value: '8099',
				edited_description: 'Medical Services and Health Practitioners (Not Elsewhere Classified)',
				combined_description: 'Medical Services and Health Practitioners (Not Elsewhere Classified)',
				usda_description: 'Medical Services and Health Practitioners (Not Elsewhere Classified)',
				name: '8099 - Medical Services ',
				irs_reportable: 'Yes',
				id: 951
			},
			{
				value: '8111',
				edited_description: 'Legal Services and Attorneys',
				combined_description: 'Legal Services and Attorneys',
				usda_description: 'Legal Services and Attorneys',
				name: '8111 - Legal Services, Attorneys',
				irs_reportable: 'Yes',
				id: 952
			},
			{
				value: '8211',
				edited_description: 'Elementary and Secondary Schools',
				combined_description: 'Elementary and Secondary Schools',
				usda_description: 'Elementary and Secondary Schools',
				name: '8211 - Elementary, Secondary Schools',
				irs_reportable: 'No1.6041-3(p)(2)',
				id: 953
			},
			{
				value: '8220',
				edited_description: 'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
				combined_description: 'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
				usda_description: 'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
				name: '8220 - Colleges, Universities',
				irs_reportable: 'No1.6041-3(p)(2)',
				id: 954
			},
			{
				value: '8241',
				edited_description: 'Correspondence Schools',
				combined_description: 'Correspondence Schools',
				usda_description: 'Correspondence Schools',
				name: '8241 - Correspondence Schools',
				irs_reportable: 'No1.6041-3(p)(2)',
				id: 955
			},
			{
				value: '8244',
				edited_description: 'Business and Secretarial Schools',
				combined_description: 'Business and Secretarial Schools',
				usda_description: 'Business and Secretarial Schools',
				name: '8244 - Business/Secretarial Schools',
				irs_reportable: 'No1.6041-3(p)(2)',
				id: 956
			},
			{
				value: '8249',
				edited_description: 'Vocational Schools and Trade Schools',
				combined_description: 'Vocational Schools and Trade Schools',
				usda_description: 'Vocational Schools and Trade Schools',
				name: '8249 - Vocational/Trade Schools',
				irs_reportable: 'No1.6041-3(p)(2)',
				id: 957
			},
			{
				value: '8299',
				edited_description: 'Schools and Educational Services ( Not Elsewhere Classified)',
				combined_description: 'Schools and Educational Services ( Not Elsewhere Classified)',
				usda_description: 'Schools and Educational Services ( Not Elsewhere Classified)',
				name: '8299 - Educational Services ',
				irs_reportable: 'Yes',
				id: 958
			},
			{
				value: '8351',
				edited_description: 'Child Care Services',
				combined_description: 'Child Care Services',
				usda_description: 'Child Care Services',
				name: '8351 - Child Care Services',
				irs_reportable: 'Yes',
				id: 959
			},
			{
				value: '8398',
				edited_description: 'Charitable and Social Service Organizations',
				combined_description: 'Charitable and Social Service Organizations',
				usda_description: 'Charitable and Social Service Organizations',
				name: '8398 - Charitable and Social Service Organizations - Fundraising',
				irs_reportable: 'No1.6041-3(p)(2)',
				id: 960
			},
			{
				value: '8641',
				edited_description: 'Civic, Fraternal, and Social Associations',
				combined_description: 'Civic, Fraternal, and Social Associations',
				usda_description: 'Civic, Fraternal, and Social Associations',
				name: '8641 - Civic, Social, Fraternal Associations',
				irs_reportable: 'No1.6041-3(p)(2)',
				id: 961
			},
			{
				value: '8651',
				edited_description: 'Political Organizations',
				combined_description: 'Political Organizations',
				usda_description: 'Political Organizations',
				name: '8651 - Political Organizations',
				irs_reportable: 'Yes',
				id: 962
			},
			{
				value: '8661',
				edited_description: 'Religious Organizations',
				combined_description: 'Religious Organizations',
				usda_description: 'Religious Organizations',
				name: '8661 - Religious Organizations',
				irs_reportable: 'No1.6041-3(p)(2)',
				id: 963
			},
			{
				value: '8675',
				edited_description: 'Automobile Associations',
				combined_description: 'Automobile Associations',
				usda_description: 'Automobile Associations',
				name: '8675 - Automobile Associations',
				irs_reportable: 'Yes',
				id: 964
			},
			{
				value: '8699',
				edited_description: 'Membership Organizations ( Not Elsewhere Classified)',
				combined_description: 'Membership Organizations ( Not Elsewhere Classified)',
				usda_description: 'Membership Organizations ( Not Elsewhere Classified)',
				name: '8699 - Membership Organizations',
				irs_reportable: 'Yes',
				id: 965
			},
			{
				value: '8734',
				edited_description: 'Testing Laboratories ( non-medical)',
				combined_description: 'Testing Laboratories ( non-medical)',
				usda_description: 'Testing Laboratories ( non-medical)',
				name: '8734 - Testing Laboratories',
				irs_reportable: 'Yes',
				id: 966
			},
			{
				value: '8911',
				edited_description: 'Architectural & Engineering and Surveying Services',
				combined_description: 'Architectural & Engineering and Surveying Services',
				usda_description: 'Architectural & Engineering and Surveying Services',
				name: '8911 - Architectural/Surveying Services',
				irs_reportable: 'Yes',
				id: 967
			},
			{
				value: '8931',
				edited_description: 'Accounting, Auditing, and Bookkeeping Services',
				combined_description: 'Accounting, Auditing, and Bookkeeping Services',
				usda_description: 'Accounting, Auditing, and Bookkeeping Services',
				name: '8931 - Accounting/Bookkeeping Services',
				irs_reportable: 'Yes',
				id: 968
			},
			{
				value: '8999',
				edited_description: 'Professional Services ( Not Elsewhere Defined)',
				combined_description: 'Professional Services ( Not Elsewhere Defined)',
				usda_description: 'Professional Services ( Not Elsewhere Defined)',
				name: '8999 - Professional Services',
				irs_reportable: 'Yes',
				id: 969
			},
			{
				value: '9211',
				edited_description: 'Court Costs, including Alimony and Child Support',
				combined_description: 'Court Costs, including Alimony and Child Support',
				usda_description: 'Court Costs, including Alimony and Child Support',
				name: '9211 - Court Costs, Including Alimony and Child Support - Courts of Law',
				irs_reportable: 'No1.6041-3(p)(4)',
				id: 970
			},
			{
				value: '9222',
				edited_description: 'Fines',
				combined_description: 'Fines',
				usda_description: 'Fines',
				name: '9222 - Fines - Government Administrative Entities',
				irs_reportable: 'No1.6041-3(p)(4)',
				id: 971
			},
			{
				value: '9223',
				edited_description: 'Bail and Bond Payments',
				combined_description: 'Bail and Bond Payments',
				usda_description: 'Bail and Bond Payments',
				name: '9223 - Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency) ',
				irs_reportable: 'Yes',
				id: 972
			},
			{
				value: '9311',
				edited_description: 'Tax Payments',
				combined_description: 'Tax Payments',
				usda_description: 'Tax Payments',
				name: '9311 - Tax Payments - Government Agencies',
				irs_reportable: 'No1.6041-3(p)(4)',
				id: 973
			},
			{
				value: '9399',
				edited_description: 'Government Services ( Not Elsewhere Classified)',
				combined_description: 'Government Services ( Not Elsewhere Classified)',
				usda_description: 'Government Services ( Not Elsewhere Classified)',
				name: '9399 - Government Services (Not Elsewhere Classified)',
				irs_reportable: 'No1.6041-3(p)(4)',
				id: 974
			},
			{
				value: '9402',
				edited_description: 'Postal Services & Government Only',
				combined_description: 'Postal Services & Government Only',
				usda_description: 'Postal Services & Government Only',
				name: '9402 - Postal Services - Government Only',
				irs_reportable: 'No1.6041-3(p)(3)',
				id: 975
			},
			{
				value: '9405',
				edited_description: 'Intra & Government Transactions',
				combined_description: 'Intra & Government Transactions',
				usda_description: 'Intra & Government Transactions',
				name: '9405 - U.S. Federal Government Agencies or Departments',
				irs_reportable: 'No1.6041-3(p)(3)',
				id: 976
			},
			{
				value: '9700',
				edited_description: 'Automated Referral Service ( For Visa Only)',
				combined_description: 'Automated Referral Service ( For Visa Only)',
				usda_description: 'Automated Referral Service ( For Visa Only)',
				name: '9700 - Automated Referral Service ( For Visa Only)',
				irs_reportable: '',
				id: 977
			},
			{
				value: '9701',
				edited_description: 'Visa Credential Service ( For Visa Only)',
				combined_description: 'Visa Credential Service ( For Visa Only)',
				usda_description: 'Visa Credential Service ( For Visa Only)',
				name: '9701 - Visa Credential Service ( For Visa Only)',
				irs_reportable: '',
				id: 978
			},
			{
				value: '9702',
				edited_description: 'GCAS Emergency Services ( For Visa Only)',
				combined_description: 'GCAS Emergency Services ( For Visa Only)',
				usda_description: 'GCAS Emergency Services ( For Visa Only)',
				name: '9702 - GCAS Emergency Services ( For Visa Only)',
				irs_reportable: '',
				id: 979
			},
			{
				value: '9950',
				edited_description: 'Intra & Company Purchases ( For Visa Only)',
				combined_description: 'Intra & Company Purchases ( For Visa Only)',
				usda_description: 'Intra & Company Purchases ( For Visa Only)',
				name: '9950 - Intra-Company Purchases',
				irs_reportable: 'No1.6041-3(c)',
				id: 980
			}
		]
	}

	AppStatusTooltip(body, status) {
		return(<OverlayTrigger
        	placement="top"
            overlay={<Tooltip>{this.getAppStatusDescription(status)}</Tooltip>}>
				{body}
        </OverlayTrigger>)
	}

	getAppStatusTextV1(status) {
        switch (status) {
            case -99:
                return <span className="badge bg-light"><BiCabinet /> Not Submitted</span>;
            case 1:
                return <span className="badge bg-info"><MdOutlineRateReview /> Manual Review</span>;
            case 2:
                return <span className="badge bg-warning"><BiPen /> Pending Signature</span>;
            case 3:
                return <span className="badge bg-primary"><BiShieldQuarter /> Underwriting</span>;
			case 4:
				return <span className="badge bg-primary"><BiSend /> Submitted</span>;
			case 6:
				return <span className="badge bg-info"><MdOutlineRateReview /> Manual Review</span>;
			case 7:
				return <span className="badge bg-secondary"><MdApproval /> Approved</span>;
			case 8:
				return <span className="badge bg-warning"><BiMoneyWithdraw /> Withdrawn</span>;
			case 9:
				return <span className="badge bg-info"><MdOutlineCancel /> Deactivated</span>;
			case 5:
                return <span className="badge bg-info"><MdOutlineRateReview /> Manual Review</span>;
            case 10:
                return <span className="badge bg-secondary">{this.getGlobalImg('intransitsecondarydark', '12px')} Boarding</span>;
            case 99:
                return <span className="badge bg-primary"><BiCheckCircle /> Activated</span>;
            case 100:
                return <span className="badge bg-success"><BiDollarCircle /> Live</span>;
            case 0:
                return <span className="badge bg-danger"><BiXCircle /> Declined</span>;
			case -1:
				return <span className="badge bg-danger"><AiOutlineException /> Exception Error</span>;
            default:
				return <span className="badge bg-light"><CgUnavailable /> N/A</span>;
        }
    }

	getAppStatusText(status, string=false) {
        switch (status) {
            case 0:
                return !string ? this.AppStatusTooltip(<span className="badge bg-light"><BiCabinet /> Not Submitted</span>,status) : 'Not Submitted';
			case 1:
				return !string ? this.AppStatusTooltip(<span className="badge bg-info"><BiPaperPlane /> Submitted</span>,status) : 'Submitted';
			case 2:
				return !string ? this.AppStatusTooltip(<span className="badge bg-warning"><BiPen /> Pending Signature</span>,status) : 'Pending Signature';
            case 3:
                return !string ? this.AppStatusTooltip(<span className="badge bg-primary"><BiShieldQuarter /> Underwriting</span>,status) : 'Underwriting';
			case 4:
                return !string ? this.AppStatusTooltip(<span className="badge bg-primary"><BiShieldQuarter /> Pended</span>,status) : 'Pended';
            case 5:
                return !string ? this.AppStatusTooltip(<span className="badge bg-warning"><BiLoader /> Held</span>,status) : 'Held';
			case 6:
				return !string ? this.AppStatusTooltip(<span className="badge bg-info"><BiLogInCircle /> Bank Review</span>,status) : 'Bank Review';
			case 7:
				return !string ? this.AppStatusTooltip(<span className="badge bg-info"><BiLogInCircle /> Bank Pending</span>,status) : 'Bank Pending';
			case 8:
				return !string ? this.AppStatusTooltip(<span className="badge bg-info"><BiLogInCircle /> Awaiting External</span>,status) : 'Awaiting External';
			case 9:
				return !string ? this.AppStatusTooltip(<span className="badge bg-info"><BiLogInCircle /> Approved</span>,status) : 'Approved';
            case 10:
                return !string ? this.AppStatusTooltip(<span className="badge bg-secondary">{this.getGlobalImg('intransitsecondarydark', '12px')} Boarding</span>,status) : 'Boarding';
			case 11:
				return !string ? this.AppStatusTooltip(<span className="badge bg-danger"><BiXCircle /> Declined</span>,status) : 'Declined';
			case 12:
				return !string ? this.AppStatusTooltip(<span className="badge bg-danger"><BiXCircle /> withdrawn</span>,status) : 'withdrawn';
            case 99:
                return !string ? this.AppStatusTooltip(<span className="badge bg-primary"><BiCheckCircle /> Activated</span>,status) : 'Activated';
            case 100:
                return !string ? this.AppStatusTooltip(<span className="badge bg-success"><BiDollarCircle /> Live</span>,status) : 'Live';
            default:
							return <span className="badge bg-light"><CgUnavailable /> N/A</span>;
        }
    }

	getAppStatusDescription(status) {
        switch (status) {
            case 0:
                return 'Application is incomplete ';
			case 1:
				return 'Application is submitted, but not yet in underwriting';
			case 2:
				return 'Application is pending signature';
            case 3:
                return 'Application is in underwriting, and not pended';
			case 4:
                return 'In underwriting, requires further diligence';
            case 5:
                return 'When an application is temporarily put on hold by processor, partner or merchant';
			case 6:
				return 'Application submitted to processing bank for review and final approval';
			case 7:
				return 'Processing bank is requiring additional information.';
			case 8:
				return 'Application is going through external validation';
			case 9:
				return 'Application has been approved and is in queue for boarding';
            case 10:
                return 'Awaiting Activation - Paypoint has been created and is awaiting configuration and activation';
			case 11:
				return 'An application is fully declined.';
			case 12:
				return 'This is when a merchant withdraws their application voluntarily.';
            case 99:
                return 'This is when a Paypoint is activated but no transactions have been run yet.';
            case 100:
                return 'First transaction was run successfully';
            default:
                return '';
        }
    }


	normalizeFileUrl(file){
		let logoUrl;
		try {
			logoUrl = new URL(file)
		} catch (e) {
			logoUrl = new URL(file, process.env.REACT_APP_URL_IMAGES)
		}
		return logoUrl;
	}

	kFormatter(num) {
		if(!isNaN(num)){
			const formatter = Intl.NumberFormat("en", { notation: "compact" });
  			return formatter.format(num);
		}
		else{
			return 0;
		}
		
	}

	getBankAccountFunction(value) {
        const functionMap = {
            0: 'Deposit',
            1: 'Withdrawal',
            2: 'Deposit, Withdrawal'
        };
    
        return functionMap[value] || '-';
    }
	  
}

const globalStore = new GlobalStore()
export default globalStore
