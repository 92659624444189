import React, { useContext } from 'react'
import styles from '../../assets/css/styleCommandCenter.module.css'
import { BiChevronLeft, BiMessageSquareX, BiMessageSquareCheck, BiMenu, BiLinkExternal } from 'react-icons/bi'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import commandContext from '../../views/commandCenter/context/commandCenter_context'
import { toast } from 'react-toastify'
import { PayabliCookieManager, PayabliStorageManager } from '../../api/localStorageManager'
import md5 from 'crypto-js/md5'
import { SessionManager } from '../../api/sessionManager'
import globalStore from '../../store/GlobalStore'
import entryStore from '../../store/EntryStore'
import * as Sentry from "@sentry/react";

export default function StatusBar({ backBtnTitle, hrefBtn, SubTitleStatus, LabelStatus, showPopUpMessage }) {
	const { Business, updateStatus, PayPoint, setLoading, ParamsURL } = useContext(commandContext)

	const redirectSso = (pEntry) => {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let user = encryptStorage && encryptStorage.getItem('pUser') ? encryptStorage.getItem('pUser') : null
		let entryLogo = null;

		if (user && user.id && pEntry.entryName) {
			setLoading(true)

			entryStore.getEntryFromApi(pEntry.entryName).then(res => {
                entryLogo = res?.responseData?.EntryLogo;
				if(entryLogo === null){
                    entryLogo = PayabliCookieManager.readCookie(`${PayabliStorageManager.getEntryName()}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`) || "payabli.png";

                }
				setTimeout(function () {
					SessionManager.getNewToken()
						.then((res) => {
							if (res) {
								PayabliCookieManager.createCookie(pEntry.entryName + '_pToken', res, 1)
								PayabliCookieManager.createCookie(`${pEntry.entryName}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`, globalStore.normalizeFileUrl(entryLogo), 1);
								window.open(`${process.env.REACT_APP_URL_PAYPOINT}${pEntry.entryName}/sso`, '_blank')
							} else {
								let errorMessage = "Something is Wrong. Can't open a PayPoint."
								toast.error(errorMessage, {
									position: toast.POSITION.BOTTOM_RIGHT,
									className: 'toast-error-container'
								})
							}
							setLoading(false)
						})
						.catch((error) => {
							setLoading(false)
							toast.error('Something is Wrong. ' + error, {
								position: toast.POSITION.BOTTOM_RIGHT,
								className: 'toast-error-container'
							})
						})
				}, 500)
            })
            .catch(error => {
				Sentry.captureException(error);
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
                return;
            });

			
		}
	}

	return (
		<div
			className={`d-flex flex-row align-items-center justify-content-between w-100 ${styles['show-go-paypoint']}`}
			style={{ padding: '8px 20px 0 40px' }}>
			{backBtnTitle && (
				<div className={styles['container-btn']}>
					<a
						href={hrefBtn}
						className={styles['btn'] + ' ' + styles['btn-grey'] + ' ' + styles['ftn-weight-700'] + ' ' + styles['ft-s-12'] + ' ' + styles['btn-hover-blue'] + ' ' + styles['btn-small']}>
						<BiChevronLeft className={styles['icon20']} />
						<label
							className={styles['d-none-750']}
							style={{ cursor: 'pointer' }}>
							{backBtnTitle}
						</label>
					</a>
				</div>
			)}

			<div className="d-flex flex-column align-items-center p-0 m-auto">
				<div className="d-flex justify-content-center align-items-center">
					<div className="d-flex flex-column text-center">
						<label
							data-qaid="paypointCommandCenterPage"
							className={styles['title-status-bar']}>
							{Business ? Business.dbaName : ''}{' '}
						</label>
						<label data-qaid="newPaypointCommandCenterPage" className={styles['sub-title-status-bar']}>{SubTitleStatus}</label>
					</div>
					{ParamsURL.id > 0 && (
						<div className={`px-4 pb-1 ${styles['hide-go-paypoint']}`}>
							<OverlayTrigger
								key={'right'}
								placement={'right'}
								overlay={<Tooltip>{'Go to PayPoint'}</Tooltip>}>
								<button
									onClick={() => redirectSso(PayPoint.entryPoints[0])}
									className={styles['btn'] + ' ' + styles['btn-grey'] + ' p-0 ' + styles['btn-hover-blue']}>
									<BiLinkExternal className={styles['icon20']} />
								</button>
							</OverlayTrigger>
						</div>
					)}
				</div>
			</div>
			{LabelStatus && (
				<>
					<div className={styles['container-status']}>
						<label
							className={styles['label-form'] + ' ' + styles['ft-s-12']}
							style={{ fontWeight: '600', letterSpacing: '-0.32px' }}>
							{LabelStatus}
						</label>
						<div className={styles['status-btn-group']}>
							<button
								onClick={() => !PayPoint.paypointStatus && showPopUpMessage({ show: true, messageTitle: 'Notice!', message: 'This action will activate the Paypoint.', message2: 'Do you want to continue?', btnCaption: 'Activate Paypoint', functionOk: () => updateStatus(true) })}
								className={PayPoint.paypointStatus ? styles['btn-status'] + ' ' + styles['active'] : styles['btn-status'] + ' ' + styles['border-light-gray']}
								style={{ borderRadius: '4px 0px 0px 4px' }}>
								<BiMessageSquareCheck /> Active
							</button>
							<button
								onClick={() => PayPoint.paypointStatus && showPopUpMessage({ show: true, messageTitle: 'Notice!', message: 'This action will inactivate the Paypoint.', message2: 'Do you want to continue?', btnCaption: 'Inactivate Paypoint', functionOk: () => updateStatus(false) })}
								className={PayPoint.paypointStatus ? styles['btn-status'] + ' ' + styles['border-light-gray'] : styles['btn-status'] + ' ' + styles['active']}
								style={{ borderRadius: '0px 4px 4px 0px' }}>
								<BiMessageSquareX /> Inactive
							</button>
						</div>
					</div>

					<DropdownButton
						menuAlign="right"
						title={
							<>
								<BiMenu
									className={styles['icon20'] + ' ' + styles['icon-status']}
									style={{ marginRight: '10px' }}
								/>
								<label className={styles['d-none-750']}>{LabelStatus}</label>
							</>
						}
						size="sm"
						variant="default"
						className={styles['btn-drop-down-status']}>
						<Dropdown.Item>
							<div className={styles['status-btn-group']}>
								<button
									onClick={() => !PayPoint.paypointStatus && showPopUpMessage({ show: true, messageTitle: 'Notice!', message: 'This action will activate the Paypoint', message2: 'Do you want to continue?', btnCaption: 'Activate Paypoint', functionOk: () => updateStatus(true) })}
									className={styles['btn-status'] + ' ' + styles['active']}>
									<BiMessageSquareCheck /> Active
								</button>
								<button
									onClick={() => PayPoint.paypointStatus && showPopUpMessage({ show: true, messageTitle: 'Notice!', message: 'This action will inactivate the Paypoint', message2: 'Do you want to continue?', btnCaption: 'Inactivate Paypoint', functionOk: () => updateStatus(false) })}
									className={styles['btn-status']}>
									<BiMessageSquareX /> Inactive
								</button>
							</div>
						</Dropdown.Item>
					</DropdownButton>
				</>
			)}
		</div>
	)
}
